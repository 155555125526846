import React, { useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Swiper as SwiperType } from "swiper";
import styled from "styled-components";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import { Pagination, Navigation, HashNavigation } from "swiper/modules";
import LeftIcon from "./icons/LeftIcon";
import RightIcon from "./icons/RightIcon";
import { breakPoints } from "../../../constant/breakpoints";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useTheme from "../../../hooks/use-theme";
import YoutubeVideo, {
  IYoutubeVideo,
} from "../../../components/YoutubeVideo/YoutubeVideo";
import { ImagesUrl } from "../../../constant";

// const VIDEO_URLS = [
//   "https://www.youtube.com/embed/hAA45ymKPJU?si=YTLb6XN6wzrG69d_",
//   "https://www.youtube.com/embed/ZeQm7SNKtXE?si=w0JN45Na89YlG1qi",
//   "https://www.youtube.com/embed/IH991wtTFz0?si=geUKZUYJKgAMFqdB",
//   "https://www.youtube.com/embed/PQq7b7uXVzw?si=moygCRTgujbO_2a0",
// ];
const VIDEOS: IYoutubeVideo[] = [
  {
    videoId: "hAA45ymKPJU",
    thumbnail: ImagesUrl.Youtube_Thumbnail_3,
  },
  {
    videoId: "ZeQm7SNKtXE",
    thumbnail: ImagesUrl.Youtube_Thumbnail_1,
  },
  {
    videoId: "IH991wtTFz0",
    thumbnail: ImagesUrl.Youtube_Thumbnail_4,
  },
  {
    videoId: "PQq7b7uXVzw",
    thumbnail: ImagesUrl.Youtube_Thumbnail_2,
  },
];

const NavigateButtonLeft = styled.div`
  right: 100%;
  padding: 1.4rem 2rem 1.4rem 1.6rem;
`;

const NavigateButtonRight = styled.div`
  left: 100%;
  padding: 1.4rem 1.6rem 1.4rem 2rem;
`;

const NavigateMobileContainer = styled.div`
  position: absolute;
  bottom: -4.5%;
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 7rem;
  @media (min-width: ${breakPoints.laptop}) {
    display: none;
  }
`;

const NavigateButtonMobile = styled.div`
  width: 3rem;
  height: 3rem;
  background-color: #fff;
  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  > svg {
    max-width: 25%;
  }
  @media (min-width: ${breakPoints.ipad}) {
    width: 4rem;
    height: 4rem;
    bottom: -0.9%;
  }
`;

const NavigateButtonLeftMobile = styled(NavigateButtonMobile)``;

const NavigateButtonRightMobile = styled(NavigateButtonMobile)``;

const Container = styled.div`
  margin-top: 3rem;
  width: 100%;
  position: relative;
  z-index: 0;

  ${NavigateButtonLeft}, ${NavigateButtonRight} {
    aspect-ratio: 1/1;
    border-radius: 99999rem;
    position: absolute;
    display: none;
    align-items: center;
    justify-content: center;
    top: 50%;
    transform: translateY(-50%);
    opacity: 0.8;
    background: #fff;
    box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.1);
    cursor: pointer;
  }

  .swiper-pagination {
    position: static;
    margin-top: 1.6rem;
  }

  @media (min-width: ${breakPoints.laptop}) {
    /* margin-top: 10rem; */
    padding: 0 4.2rem;
    ${NavigateButtonLeft}, ${NavigateButtonRight} {
      display: flex;
    }
  }
`;

const Wrapper = styled.div`
  padding: 0 3.2rem;
  @media (max-width: ${breakPoints.mobile}) {
    padding: 0;
  }
`;

const Header = styled.div<{ fontFamily?: string }>`
  display: flex;
  flex-direction: column;
  row-gap: 1.7rem;
  margin-top: 10rem;
  > div {
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 0.6rem;
    > span {
      width: 1.2rem;
      height: 1.2rem;

      border-radius: 0.4rem;
      background: linear-gradient(
        237deg,
        #1268e9 -15.33%,
        #b43bf0 45.3%,
        #f64f59 105.7%
      );
    }
    > p {
      color: #2d2d2d;
      font-family: ${({ fontFamily }) => fontFamily};
      font-size: 1.4rem;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      text-transform: uppercase;
    }
  }
  > h2 {
    color: #2d2d2d;
    text-align: center;
    text-transform: uppercase;
    /* H3 */
    font-family: ${({ fontFamily }) => fontFamily};
    font-size: 3.2rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.128rem;
  }
  @media (min-width: ${breakPoints.laptop}) {
    row-gap: 2.4rem;
    > div {
      column-gap: 1.2rem;
      > span {
        width: 1.6rem;
        height: 1.6rem;
      }
      > p {
        font-size: 2.2rem;
      }
    }
    > h2 {
      font-size: 6.4rem;
    }
  }
`;
function VideoSlider() {
  const { pathname } = useLocation();
  const swiperRef = useRef<SwiperType>();
  const [swiper, setSwiper] = useState<number>(0);
  const { fontFamily } = useTheme();
  const { t } = useTranslation();

  return (
    <Wrapper>
      <Header fontFamily={fontFamily}>
        <div>
          <span></span>
          <p>{t("Video Discovery")}</p>
        </div>
        <h2>{t("Redefining your tech journey")}</h2>
      </Header>
      <Container>
        <Swiper
          loop
          onSlideChange={(e) => {
            setSwiper(e.activeIndex);
          }}
          spaceBetween={30}
          hashNavigation={{
            watchState: true,
          }}
          pagination={{
            clickable: true,
          }}
          modules={[Pagination, Navigation, HashNavigation]}
          onBeforeInit={(swiper) => {
            swiperRef.current = swiper;
          }}
          style={{
            //@ts-ignore
            "--swiper-pagination-color": "#000",
          }}
        >
          {pathname !== "/purchase"
            ? VIDEOS.slice(0, 3).map((item, index) => (
                <SwiperSlide key={index}>
                  <YoutubeVideo
                    thumbnail={item.thumbnail}
                    videoId={item.videoId}
                  />
                </SwiperSlide>
              ))
            : VIDEOS.map((item, index) => (
                <SwiperSlide key={index}>
                  <YoutubeVideo
                    thumbnail={item.thumbnail}
                    videoId={item.videoId}
                  />
                </SwiperSlide>
              ))}
        </Swiper>
        <NavigateButtonLeft
          onClick={() => {
            swiperRef.current?.slidePrev();
          }}
        >
          <LeftIcon />
        </NavigateButtonLeft>
        <NavigateButtonRight
          onClick={() => {
            swiperRef.current?.slideNext();
          }}
        >
          <RightIcon />
        </NavigateButtonRight>
        {/* mobile */}
        <NavigateMobileContainer>
          <NavigateButtonLeftMobile
            onClick={() => {
              swiperRef.current?.slidePrev();
            }}
          >
            <LeftIcon />
          </NavigateButtonLeftMobile>
          <NavigateButtonRightMobile
            onClick={() => {
              swiperRef.current?.slideNext();
            }}
          >
            <RightIcon />
          </NavigateButtonRightMobile>
        </NavigateMobileContainer>
      </Container>
    </Wrapper>
  );
}

export default VideoSlider;
