import { useState } from "react";
import styled from "styled-components";
import { breakPoints } from "../../../constant/breakpoints";
import MemorySelector from "../../Purchase/components/MemorySelector";
import ColorSelector from "../../Purchase/components/ColorSelector";
import { ButtonGradient } from "../../../components/ButtonGradient";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useTheme from "../../../hooks/use-theme";
import { ImagesUrl } from "../../../constant";

const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  gap: 2.4rem;
  padding: 0 0 8rem 0;
  @media (min-width: ${breakPoints.laptop}) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 6rem;
  }
  @media (min-width: ${breakPoints.desktop}) {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
`;

const ContentLeft = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  > img {
    width: 30rem;
    max-width: 30rem;
    max-height: 100%;
    /* max-height: 64.3rem; */
    aspect-ratio: 810/1758;
  }
`;

const ContentRight = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 2.4rem;
  h4 {
    color: #121212;
    font-size: 4.8rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
  }
  @media (min-width: ${breakPoints.laptop}) {
    row-gap: 3.8rem;
  }
  > button {
    width: fit-content;
  }
`;

const TextDescription = styled.p`
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 400;
  line-height: 170%;
  letter-spacing: 0.016rem;
  color: #8f8f8f;
  @media (min-width: ${breakPoints.laptop}) {
    font-size: 2.4rem;
    line-height: 180%;
  }
`;

const PowerList = styled.div`
  display: flex;
  align-items: center;
  gap: 1.6rem;
  flex-wrap: wrap;
`;

const ColorList = styled.div`
  display: flex;
  align-items: center;
  column-gap: 1.6rem;
  p {
    color: var(--gray-scale-black-80, #2d2d2d);
    font-family: Archivo;
    font-size: 2rem;
    font-style: normal;
    font-weight: 500;
    line-height: 130%;
    display: none;
  }
  > div {
    display: flex;
    align-items: center;
    column-gap: 1.6rem;
  }
  @media (min-width: ${breakPoints.laptop}) {
    flex-direction: column;
    align-items: start;
    row-gap: 1.6rem;
    > p {
      display: block;
      font-size: 3.6rem;
    }
  }
`;

const SubmitButton = styled.div`
  flex: 1;
  a {
    text-decoration: none !important;
  }
  button {
    display: flex;
    padding: 8px;
    align-items: center;
    justify-content: center;
    gap: 8px;
    align-self: stretch;
    font-size: 1.4rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    width: 100%;
  }
  @media (min-width: ${breakPoints.mobile}) {
    width: 37.6rem;
    button {
      padding: 16px;
      font-size: 18px;
    }
  }
`;

const Color = styled.div<{ $isBottom?: boolean }>`
  /* position: ${(props) => (props.$isBottom ? "relative" : "fixed")}; */
  position: relative;
  bottom: 0;
  left: 0;
  right: 0;
  min-height: 8rem;
  background-color: white;
  padding: ${(props) => (!props.$isBottom ? "1.2rem 2.4rem" : "0")};
  z-index: 0;
  display: flex;
  align-items: center;
  column-gap: 2.4rem;
  @media (min-width: ${breakPoints.ipad}) {
    position: relative;
    flex-direction: column;
    align-items: start;
    row-gap: 2.4rem;
  }
`;

function PreOrder() {
  const { t } = useTranslation();
  const { fontFamily } = useTheme();
  const [color, setColor] = useState<string>("Black");
  // const colorRef = useRef<HTMLDivElement>(null);
  // const isOnScreen = useOnScreen(colorRef);

  return (
    <Container>
      <ContentLeft>
        <img
          src={
            color === "Black" ? ImagesUrl.Phone_Black : ImagesUrl.Phone_White2
          }
          alt="black"
        />
      </ContentLeft>
      <ContentRight>
        <h2 style={{ fontFamily: fontFamily, fontWeight: "600" }}>
          {color === "Black" ? t("BLACK VERSION") : t("WHITE VERSION")}
        </h2>
        <TextDescription>
          {t(
            "Using the START24 social networking app, you can connect with people from all over the world"
          )}
        </TextDescription>
        <PowerList>
          <MemorySelector isReverse title={t("LCD Display")} value={`6.81"`} />
          <MemorySelector isReverse title={t("GB Storage")} value={`256`} />
          <MemorySelector isReverse title={t("GB RAM")} value={`12`} />
        </PowerList>
        {/* flag */}
        {/* <div ref={colorRef}></div> */}
        <Color>
          <ColorList>
            <p>{t("Pick your color")}</p>
            <div>
              <ColorSelector
                onSelect={() => {
                  setColor("Black");
                }}
                color="#000"
                isActive={color === "Black"}
                hasIcon
              />
              <ColorSelector
                onSelect={() => {
                  setColor("White");
                }}
                color="#D1D1D1"
                isActive={color === "White"}
                hasIcon
              />
            </div>
          </ColorList>
          <SubmitButton>
            <Link
              to={`/purchase/phone/${color.toLowerCase()}?scroll=1`}
              state={{
                color,
              }}
            >
              <ButtonGradient>
                <p>{t("Pre-order Now")}</p>
              </ButtonGradient>
            </Link>
          </SubmitButton>
        </Color>
      </ContentRight>
    </Container>
  );
}

export default PreOrder;
