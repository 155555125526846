import React, { ReactNode } from "react";
import { styled } from "styled-components";
import CloseIcon from "../../icons/Close";
import { breakPoints } from "../../constant/breakpoints";
import ReactDOM from "react-dom";
const Modal = styled.div<{ $isOpen?: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease-in-out;

  ${({ $isOpen }) =>
    $isOpen
      ? `
    opacity: 1;
    visibility: visible;
    pointer-events: all;
    z-index: 9999999;
  `
      : `
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    z-index: -1;
  `}

  > div {
    width: 90vw;
    height: 90vh;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    animation: ${({ $isOpen }) =>
      $isOpen ? "initCustomModalV2 ease 200ms " : "none"};
  }
  @media (max-width: ${breakPoints.mobile}) {
    > div {
      max-width: 95vw;
    }
    > div > svg {
      max-width: 2.5rem;
    }
  }
  @keyframes initCustomModalV2 {
    0% {
      transform: scale(0.5);
    }
    100% {
      transform: scale(1);
    }
  }
`;

interface Props {
  children: ReactNode;
  $isOpen: boolean;
  onClose: () => void;
}

const CustomModalV2 = ({ children, $isOpen, onClose }: Props) => {
  const rootModal = document.getElementById("modal-root");
  if (!rootModal) return null;
  return ReactDOM.createPortal(
    <Modal $isOpen={$isOpen} onClick={onClose}>
      <div onClick={(e) => e.stopPropagation()}>
        {/* <CloseIcon onClick={onClose} /> */}
        {children}
      </div>
    </Modal>,
    rootModal
  );
};

export default CustomModalV2;
