function CheckIcon({
  width,
  height,
  fill,
}: {
  width?: number;
  height?: number;
  fill?: string;
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M7.66721 13.1767L4.96833 10.4779C4.8229 10.3324 4.62566 10.2507 4.41999 10.2507C4.21433 10.2507 4.01709 10.3324 3.87166 10.4779C3.72623 10.6233 3.64453 10.8205 3.64453 11.0262C3.64453 11.128 3.66459 11.2289 3.70356 11.3229C3.74253 11.417 3.79965 11.5025 3.87166 11.5745L7.12277 14.8256C7.4261 15.129 7.9161 15.129 8.21944 14.8256L16.4483 6.59674C16.5938 6.45132 16.6755 6.25407 16.6755 6.04841C16.6755 5.84274 16.5938 5.6455 16.4483 5.50008C16.3029 5.35465 16.1057 5.27295 15.9 5.27295C15.6943 5.27295 15.4971 5.35465 15.3517 5.50008L7.66721 13.1767Z"
        fill={fill}
      />
    </svg>
  );
}

export default CheckIcon;
