import React from "react";
import ComponentInfo from "./home/component/ComponentInfo";
// import ComponentVideo from "./home/component/ComponentVideo";
import IntroducingHome from "../pages/introducingHome";
import FeaturesHome from "../pages/featuresHome";
import Landing from "./Landing";
// import MediaHome from "../pages/mediaHome";
import PreOrder from "./home/component/ComponentPreOrder";
import { useRef, useCallback } from "react";
import VideoSlider from "./home/component/VideoSlider";
import SocialList from "../components/SocialList";
import ComponentUnboxing from "./home/component/ComponentUnboxing";
import ComponentTryOut from "./home/component/ComponentTryOut";
import {
  MainLayoutContainer,
  OutLayoutContainer,
} from "../components/layout/Layout";

const MediaHome = React.lazy(() => import("../pages/mediaHome"));
const ComponentVideo = React.lazy(
  () => import("./home/component/ComponentVideo")
);

function HomePage() {
  const preOrderRef = useRef<any>(null);
  const introduceRef = useRef<HTMLDivElement>(null);

  const handleScrollToView = useCallback(() => {
    preOrderRef.current &&
      preOrderRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
  }, [preOrderRef]);

  const handleScrollToIntroduce = () => {
    introduceRef.current &&
      introduceRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
  };

  return (
    <div>
      <MainLayoutContainer id="landing">
        <Landing
          onClick={() => {
            handleScrollToView();
          }}
          onScrollToIntroduce={() => {
            handleScrollToIntroduce();
          }}
        />
        <div ref={introduceRef}>
          <IntroducingHome />
        </div>
      </MainLayoutContainer>
      {/* <OutLayoutContainer>
        <ComponentTryOut />
      </OutLayoutContainer> */}
      <MainLayoutContainer>
        <VideoSlider />
        <FeaturesHome
          onClick={() => {
            handleScrollToView();
          }}
        />

        <ComponentUnboxing />
        <ComponentVideo />
      </MainLayoutContainer>
      <OutLayoutContainer>
        <ComponentInfo />
      </OutLayoutContainer>
      <MainLayoutContainer>
        <MediaHome />
        <SocialList />
        <div ref={preOrderRef}>
          <PreOrder />
        </div>
      </MainLayoutContainer>
    </div>
  );
}

export default HomePage;
