import { styled } from "styled-components";
import { useEffect, useRef, useState } from "react";
import useWindowDimensions from "../../../hooks/useWindowDimensions";

const PlaybackVideoLoopMobile =
  "https://daziu008xh8wa.cloudfront.net/assets/hero_part2.mp4";

const PlaybackVideoMobile =
  "https://daziu008xh8wa.cloudfront.net/assets/hero_part1.mp4";

const PlaybackVideo =
  "https://daziu008xh8wa.cloudfront.net/assets/hero_desktop-1.mp4";

const PlaybackVideoLoop =
  "https://daziu008xh8wa.cloudfront.net/assets/hero_desktop_2.mp4";

const Container = styled.video`
  position: relative;
  left: 0;
  right: 0;
  top: 0;
  width: 100%;
  min-height: 30rem;
  aspect-ratio: 4/3;
  object-fit: cover;
  object-position: center;
  z-index: -1;
  background-color: #000;
`;

function Playback({ skipVideo }: { skipVideo: boolean }) {
  const [vidIndex, setVidIndex] = useState<number>(0);
  const ref = useRef<HTMLVideoElement>(null);
  const { width } = useWindowDimensions();

  useEffect(() => {
    if (vidIndex === 0 && ref.current) {
      ref.current.play();
    }
  }, [ref, vidIndex, skipVideo]);

  return (
    <>
      <Container
        ref={ref}
        src={width > 640 ? PlaybackVideo : PlaybackVideoMobile}
        autoPlay
        muted
        controls={false}
        controlsList="nofullscreen"
        aria-disabled
        playsInline
        onEnded={() => setVidIndex((idx) => idx + 1)}
        style={{ display: vidIndex === 1 || skipVideo ? "none" : "block" }}
      />
      <Container
        style={{ display: vidIndex === 0 && !skipVideo ? "none" : "block" }}
        ref={ref}
        src={width > 640 ? PlaybackVideoLoop : PlaybackVideoLoopMobile}
        loop
        muted
        controls={false}
        controlsList="nofullscreen"
        aria-disabled
        playsInline
      />
    </>
  );
}

export default Playback;
