import styled, { keyframes } from "styled-components";
import { breakPoints } from "../../constant/breakpoints";
import { useEffect, useState, useRef } from "react";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { useTranslation } from "react-i18next";
import ChatBotIcon from "./icons/ChatBotIcon";
import SendIcon from "./icons/SendIcon";
import MinusIcon from "./icons/MinusIcon";
import {
  getLocalItem,
  setLocalItem,
  removeLocalItem,
} from "../../utils/localStorage";
import { uuid } from "../../utils/uuid";
import ChatBotAPI, { params } from "../../service/ChatBotAPI";
import ClearIcon from "./icons/ClearIcon";
import { LANGUAGES_CODE } from "../LanguageSelector";
import useChangeLanguage from "../../hooks/useChangeLanguage";
import { Stream } from "../../service/ChatBotAPI/StreamService";

const Wrapper = styled.div<{ $isHigh?: boolean }>`
  position: fixed;
  right: 2.4rem;
  bottom: 8.5rem;
  align-items: end;
  flex-direction: column;
  row-gap: 1.6rem;
  z-index: 999;
  @media (min-width: ${breakPoints.ipad}) {
    display: flex;
    bottom: 11.5rem;
  }
  @media (min-width: ${breakPoints.laptop}) {
    right: 3.2rem;
    bottom: 11.5rem;
  }
`;

const ChatBotIconWrap = styled.div`
  cursor: pointer;
  display: inline-flex;
  padding: 7px 14px;
  justify-content: center;
  align-items: center;
  gap: 6px;
  border-radius: 100px;
  background: #121212;
  color: #fff;
  text-align: center;
  font-family: Archivo;
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 400;
  line-height: 170%; /* 34px */
  letter-spacing: 0.2px;
  transition: all 0.4s ease-in-out;
  &:hover {
    transform: scale(1.1);
  }
  @media (min-width: ${breakPoints.ipad}) {
    font-size: 1.6rem;
  }
  @media (min-width: ${breakPoints.laptop}) {
    font-size: 2rem;
  }
`;

const Container = styled.div<{ $display: boolean; $darkMode: boolean }>`
  border-radius: 2rem;
  max-width: 40rem;
  width: 40rem;
  /* width: ${(props) => (props.$display ? "26rem" : "0")}; */
  max-height: 52rem;
  height: 52rem;
  /* height: ${(props) => (props.$display ? "40rem" : "0")}; */
  background-color: ${({ $darkMode: darkMode }) =>
    darkMode ? "#fff" : "#fff"};
  box-shadow: 0.4rem 0.4rem 2rem -0.1rem rgba(0, 0, 0, 0.1);
  z-index: 99;
  display: flex;
  flex-direction: column;
  /* transform: ${(props) =>
    props.$display ? "translate(0, 0)" : "translate(100%, 100%)"}; */
  opacity: ${(props) => (props.$display ? 1 : 0)};
  display: ${(props) => (props.$display ? "flex" : "none")};
  transition: all ease 400ms;
  overflow: hidden;
`;

const ContainerMobile = styled.div<{ $display: boolean }>`
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 99;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  transform: ${(props) =>
    props.$display ? "translateY(0)" : "translateY(100%)"};
  opacity: ${(props) => (props.$display ? 1 : 0)};
  transition: all ease 300ms;
  overflow: hidden;
`;

const ChatBotContent = styled.div`
  width: 100%;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  background: #fff;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 1.6rem;
  align-items: center;
  padding: 2rem;
  @media (min-width: ${breakPoints.ipad}) {
    padding: 1.2rem;
  }
`;

const HeaderTitleWrap = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 0.6rem;
  align-items: center;
  padding: 0.2rem;
  > svg {
    max-width: 3.2rem;
    min-width: 3.2rem;
    max-height: 3rem;
    min-height: 3rem;
  }

  @media (min-width: ${breakPoints.ipad}) {
    > svg {
      max-width: 3rem;
      min-width: 3rem;
      max-height: 2.8rem;
      min-height: 2.8rem;
    }
  }
`;

const HeaderTitle = styled.span`
  color: #2d2d2d;
  font-family: Archivo;
  font-size: 1.8rem;
  font-style: normal;
  font-weight: 600;
  line-height: 140%; /* 16.8px */
  letter-spacing: 0.012rem;

  @media (min-width: ${breakPoints.ipad}) {
    font-size: 1.7rem;
  }
`;

const HeaderIconWrap = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 1.6rem;
`;

const IconWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;
  padding: 0.4rem;
  border-radius: 50%;
  background: #f2f2f2;
  > svg {
    max-width: 2.6rem;
    min-width: 2.6rem;
    max-height: 2.6rem;
    min-height: 2.6rem;
  }

  &:hover {
    background: #f2f2f2;
  }
  @media (min-width: ${breakPoints.laptop}) {
    background: initial;
    > svg {
      max-width: 2rem;
      min-width: 2rem;
      max-height: 2rem;
      min-height: 2rem;
    }
  }
`;

const ContentMessage = styled.div`
  border-top: 0.05rem solid #dfdfdf;
  border-bottom: 0.05rem solid #dfdfdf;
  flex-grow: 1;
  padding: 1.2rem;
  overflow: hidden auto;
`;

const MessageLine = styled.div<{ $isBot: boolean }>`
  display: flex;
  gap: 0.6rem;
  align-items: flex-end;
  justify-content: ${({ $isBot: isBot }) =>
    isBot ? "flex-start" : "flex-end"};
  margin-bottom: 1.5rem;

  > svg {
    min-width: 2.8rem;
    max-width: 2.8rem;
    min-height: 2.6rem;
    max-height: 2.6rem;
    @media (min-width: ${breakPoints.ipad}) {
      min-width: 2.6rem;
      max-width: 2.6rem;
      min-height: 2.4rem;
      max-height: 2.4rem;
    }
  }
`;

const Message = styled.div<{ $isBot: boolean; $typing?: boolean }>`
  display: flex;
  gap: 0.2rem;
  padding: 1.6rem;
  max-width: 29rem;
  border-radius: 1.2rem;
  background: ${({ $isBot: isBot }) => (isBot ? "#F2F2F2" : "#2D2D2D")};
  > p {
    color: ${({ $isBot: isBot }) => (isBot ? "#2d2d2d" : "#ffffff")};
    font-family: Archivo;
    font-size: 1.8rem;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 16.8px */
    letter-spacing: 0.012rem;
    white-space: pre-line;

    &::after {
      content: "...";
      display: ${({ $typing: typing }) => (typing ? "inline-block" : "none")};
    }
  }

  @media (min-width: ${breakPoints.ipad}) {
    max-width: 26rem;
    padding: 1.4rem;
    > p {
      font-size: 1.7rem;
    }
  }
`;

const InputWrap = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 1.6rem;
  align-items: center;
  padding: 2.4rem;
  @media (min-width: ${breakPoints.ipad}) {
    padding: 1.2rem;
  }
`;

const InputMessage = styled.input`
  flex-grow: 1;
  border: none;
  outline: none;
  color: #222222;
  /* BODY 3 */
  font-family: Archivo;
  font-size: 2rem;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 21px */
  letter-spacing: 0.014rem;
  &:disabled {
    cursor: not-allowed;
  }

  ::placeholder {
    color: #8f8f8f;
    /* BODY 3 */
    font-family: Archivo;
    font-size: 2rem;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 21px */
    letter-spacing: 0.014rem;
  }

  @media (min-width: ${breakPoints.ipad}) {
    font-size: 1.7rem;
    ::placeholder {
      font-size: 1.7rem;
    }
  }
`;

const SendIconWrap = styled.div`
  cursor: pointer;
  transition: all 0.4s ease-in-out;
  &:hover {
    transform: scale(1.1);
  }
  > svg {
    max-width: 3.2rem;
    min-width: 3.2rem;
    max-height: 3.2rem;
    min-height: 3.2rem;
  }

  @media (min-width: ${breakPoints.ipad}) {
    > svg {
      max-width: 3rem;
      min-width: 3rem;
      max-height: 3rem;
      min-height: 3rem;
    }
  }
`;

const LineMobile = styled.div`
  min-height: 3rem;
  max-height: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f5f5f5;
  > span {
    min-height: 0.5rem;
    max-height: 0.5rem;
    width: 14rem;
    background: #121212;
    border-radius: 10rem;
  }
`;

const LoadingWrap = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 0.4rem;
`;

const lifte = keyframes`
  0% {
    transform: translateY(0);
  }
  20% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0);
  }
`;

const TreeDotLoading = styled.span`
  min-height: 0.4rem;
  max-height: 0.4rem;
  min-width: 0.4rem;
  max-width: 0.4rem;
  background: #121212;
  border-radius: 50%;
  animation: ${lifte} 1.5s linear infinite;

  @media (min-width: ${breakPoints.ipad}) {
    min-height: 0.3rem;
    max-height: 0.3rem;
    min-width: 0.3rem;
    max-width: 0.3rem;
  }

  &:nth-child(2) {
    animation-delay: 0.2s;
  }

  &:nth-child(3) {
    animation-delay: 0.4s;
  }
`;

const ClearIconWrap = styled.div`
  display: flex;
  gap: 0.2rem;
  align-items: center;
  cursor: pointer;

  > svg {
    max-width: 2rem;
    min-width: 2rem;
    max-height: 2rem;
    min-height: 2rem;

    @media (min-width: ${breakPoints.laptop}) {
      max-width: 1.8rem;
      min-width: 1.8rem;
      max-height: 1.8rem;
      min-height: 1.8rem;
    }
  }
`;

const ClearText = styled.div`
  color: #2d2d2d;
  text-align: right;
  font-family: Archivo;
  font-size: 2rem;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 16.8px */
  letter-spacing: 0.012rem;
  text-decoration-line: underline;

  @media (min-width: ${breakPoints.laptop}) {
    font-size: 1.7rem;
  }
`;

interface IMessage {
  code?: string;
  lastBotMessageLoading?: boolean;
  lastBotMessageTyping?: boolean;
  from: string;
  text: string;
}

function ChatBot() {
  const { t } = useTranslation();
  const welcomeMessage: IMessage = {
    code: "welcome",
    from: "computer",
    text: t("Hello, can I help you?"),
  };
  const screen = useWindowDimensions();
  const [chatbot_cookie_id, set_chatbot_cookie_id] = useState<string>("");
  const [isToggleChatBot, setIsToggleChatBot] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");
  const [messagesHistory, setMessagesHistory] = useState<IMessage[]>([
    welcomeMessage,
  ]);
  const [languageCode, setLanguageCode] = useState<string>(
    LANGUAGES_CODE.English
  );
  const isLaptop = screen.width < 1024;
  const chatBoxElementPC = useRef<any>(null);
  const chatBoxElementMobile = useRef<any>(null);

  const handleInitChatBot = () => {
    const chatbotCookieId = getLocalItem("chatbot_cookie_id");
    const chatBotVisible = getLocalItem("chatBotVisible");
    setIsToggleChatBot(chatBotVisible);

    if (!chatbotCookieId) {
      const uniqueId = uuid();
      setLocalItem("chatbot_cookie_id", uniqueId);
      set_chatbot_cookie_id(uniqueId);
    } else {
      set_chatbot_cookie_id(chatbotCookieId);
    }
  };

  const getMessagesHistory = () => {
    const messages = getLocalItem("chat_messages");
    if (Array.isArray(messages) && messages?.length) {
      //setMessagesHistory([welcomeMessage, ...messages]);
      setMessagesHistory(messages);
    }
  };

  const getLanguageCode = () => {
    const languageCode = getLocalItem("language");
    setLanguageCode(
      languageCode === LANGUAGES_CODE.China
        ? "zh"
        : languageCode === LANGUAGES_CODE.Japan
        ? "ja"
        : languageCode
    );
  };

  const handleClearMessage = () => {
    removeLocalItem("chat_messages");
    setMessagesHistory([welcomeMessage]);
  };

  const handleToggle = () => {
    setLocalItem("chatBotVisible", !isToggleChatBot);
    setIsToggleChatBot(!isToggleChatBot);
  };

  const handleSendMessageNewUpdate = async () => {
    if (!message) return;

    console.log("message send");

    setIsLoading(true);

    const newMessage: IMessage = {
      from: "me",
      text: message,
    };

    const newChatBotMessage: IMessage = {
      from: "computer",
      text: "",
      lastBotMessageLoading: true,
      lastBotMessageTyping: false,
    };

    const newMessagesHistory = [
      ...messagesHistory,
      newMessage,
      newChatBotMessage,
    ];

    setMessagesHistory(newMessagesHistory);
    setMessage("");

    try {
      const dataToPost: params = {
        bot_id: 7,
        role: "User",
        content: message,
        language: languageCode,
        cookie_id: chatbot_cookie_id,
      };

      let progressMessage = "";
      await Stream.fetchStreamMessage(
        dataToPost,
        (resMessage: any) => {
          const cloneNewMessagesHistory = [...newMessagesHistory];
          if (resMessage === "[DONE]") {
            return;
          }
          const chatBotMessage =
            cloneNewMessagesHistory.pop() || newChatBotMessage;

          setMessagesHistory([
            ...cloneNewMessagesHistory,
            {
              ...chatBotMessage,
              text: progressMessage + resMessage,
              lastBotMessageLoading: false,
              lastBotMessageTyping: true,
            },
          ]);
          progressMessage += resMessage;
        },
        () => {
          console.log("doneMessage: ", progressMessage, newMessagesHistory);
          const doneMessage = progressMessage;
          const cloneNewMessagesHistory = [...newMessagesHistory];
          cloneNewMessagesHistory.pop();

          setLocalItem("chat_messages", [
            ...cloneNewMessagesHistory,
            {
              ...newChatBotMessage,
              text: doneMessage,
              lastBotMessageLoading: false,
              lastBotMessageTyping: false,
            },
          ]);

          setMessagesHistory([
            ...cloneNewMessagesHistory,
            {
              ...newChatBotMessage,
              text: doneMessage,
              lastBotMessageLoading: false,
              lastBotMessageTyping: false,
            },
          ]);

          handleScrollToView();
        }
      );
    } catch (error) {
      console.log("send message chatbot error: ", error);
      setMessagesHistory(messagesHistory);
    } finally {
      setIsLoading(false);
    }
  };

  const handleScrollToView = () => {
    if (chatBoxElementPC && chatBoxElementPC.current) {
      chatBoxElementPC.current.scrollTop =
        chatBoxElementPC.current.scrollHeight;
      chatBoxElementPC?.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
    if (chatBoxElementMobile && chatBoxElementMobile.current) {
      chatBoxElementMobile.current.scrollTop =
        chatBoxElementMobile.current.scrollHeight;
      chatBoxElementMobile?.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };

  const handleEnter = (e: any) => {
    if (e.key === "Enter" || e.keyCode === 13) {
      // handleSendMessage();
      handleSendMessageNewUpdate();
    }
  };

  useEffect(() => {
    handleInitChatBot();
    getMessagesHistory();
    getLanguageCode();
  }, []);

  useChangeLanguage(() => getLanguageCode());

  useEffect(() => {
    handleScrollToView();
  }, [messagesHistory.length]);

  return (
    <Wrapper
    // $isHigh={location.pathname.includes("/community") && !user ? true : false}
    >
      {!isLaptop ? (
        <Container $display={isToggleChatBot} $darkMode={true}>
          <ChatBotContent onClick={(e) => e.stopPropagation()}>
            {isLaptop && <LineMobile></LineMobile>}
            <Header>
              <HeaderTitleWrap>
                <ChatBotIcon color="#222222" />
                <HeaderTitle>{t("START24")}</HeaderTitle>
              </HeaderTitleWrap>
              <HeaderIconWrap>
                <ClearIconWrap onClick={handleClearMessage}>
                  <ClearIcon />
                  <ClearText>{t("Clear")}</ClearText>
                </ClearIconWrap>
                <IconWrap onClick={handleToggle}>
                  <MinusIcon />
                </IconWrap>
              </HeaderIconWrap>
            </Header>
            <ContentMessage id="chatbox_message_pc" ref={chatBoxElementPC}>
              {messagesHistory?.map((message, index) =>
                message.from === "me" ? (
                  <MessageLine key={index} $isBot={false}>
                    <Message $isBot={false}>
                      <p>{message.text}</p>
                    </Message>
                  </MessageLine>
                ) : (
                  <MessageLine key={index} $isBot={true}>
                    <ChatBotIcon color="#222222" />
                    <Message
                      $isBot={true}
                      $typing={
                        isLoading &&
                        message.lastBotMessageLoading === false &&
                        message.lastBotMessageTyping === true
                          ? true
                          : false
                      }
                    >
                      <p>{message.text}</p>
                      {isLoading && message.lastBotMessageLoading && (
                        <LoadingWrap>
                          <TreeDotLoading />
                          <TreeDotLoading />
                          <TreeDotLoading />
                        </LoadingWrap>
                      )}
                    </Message>
                  </MessageLine>
                )
              )}
            </ContentMessage>
            <InputWrap>
              <InputMessage
                onKeyUp={handleEnter}
                onChange={(e) => setMessage(e.target.value)}
                value={message}
                disabled={isLoading}
                placeholder={t("Message...")}
              />
              <SendIconWrap onClick={() => handleSendMessageNewUpdate()}>
                <SendIcon />
              </SendIconWrap>
            </InputWrap>
            {isLaptop && (
              <LineMobile>
                <span></span>
              </LineMobile>
            )}
          </ChatBotContent>
        </Container>
      ) : (
        <ContainerMobile $display={isToggleChatBot}>
          <ChatBotContent onClick={(e) => e.stopPropagation()}>
            {isLaptop && <LineMobile></LineMobile>}
            <Header>
              <HeaderTitleWrap>
                <ChatBotIcon color="#222222" />
                <HeaderTitle>{t("START24")}</HeaderTitle>
              </HeaderTitleWrap>
              <HeaderIconWrap>
                <ClearIconWrap onClick={handleClearMessage}>
                  <ClearIcon />
                  <ClearText>{t("Clear")}</ClearText>
                </ClearIconWrap>
                <IconWrap onClick={handleToggle}>
                  <MinusIcon />
                </IconWrap>
              </HeaderIconWrap>
            </Header>
            <ContentMessage
              id="chatbox_message_mobile"
              ref={chatBoxElementMobile}
            >
              {messagesHistory?.map((message, index) =>
                message.from === "me" ? (
                  <MessageLine key={index} $isBot={false}>
                    <Message $isBot={false}>
                      <p>{message.text}</p>
                    </Message>
                  </MessageLine>
                ) : (
                  <MessageLine key={index} $isBot={true}>
                    <ChatBotIcon color="#222222" />
                    <Message
                      $isBot={true}
                      $typing={
                        isLoading &&
                        message.lastBotMessageLoading === false &&
                        message.lastBotMessageTyping === true
                          ? true
                          : false
                      }
                    >
                      <p>{message.text}</p>
                      {isLoading && message.lastBotMessageLoading && (
                        <LoadingWrap>
                          <TreeDotLoading />
                          <TreeDotLoading />
                          <TreeDotLoading />
                        </LoadingWrap>
                      )}
                    </Message>
                  </MessageLine>
                )
              )}
            </ContentMessage>
            <InputWrap>
              <InputMessage
                onKeyUp={handleEnter}
                onChange={(e) => setMessage(e.target.value)}
                value={message}
                disabled={isLoading}
                placeholder={t("Message...")}
              />
              <SendIconWrap onClick={() => handleSendMessageNewUpdate()}>
                <SendIcon />
              </SendIconWrap>
            </InputWrap>
            {isLaptop && <LineMobile>{/* <span></span> */}</LineMobile>}
          </ChatBotContent>
        </ContainerMobile>
      )}

      <ChatBotIconWrap onClick={handleToggle}>
        <ChatBotIcon width={33} height={30} />
        {!isLaptop && <span>{t("Chat")}</span>}
      </ChatBotIconWrap>
    </Wrapper>
  );
}

export default ChatBot;
