import request from "../utils/request";

const ProductApi = {
  getProducts: async () => {
    const response: any = await request.get("/product");
    if (response?.statusCode === 200) {
      return response.data;
    } else {
      return null;
    }
  },
  getSalePrice: async () => {
    const response: any = await request.get("/application/sale-price");
    if (response?.statusCode === 200) {
      return response.data;
    } else {
      return null;
    }
  },
  getDiscountPrice: async () => {
    const response: any = await request.get("/application/discount-price");
    if (response?.statusCode === 200) {
      return response.data;
    } else {
      return null;
    }
  },
};

export default ProductApi;
