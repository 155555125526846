import styled from "styled-components";

const Image = styled.div`
  cursor: pointer;
  width: 15vw;
  height: 15vw;
  opacity: 0;
  animation: button-coming 500ms linear 10s forwards;

  > img:first-child {
    display: block;
    width: 15vw;
    height: 15vw;
  }

  > img:nth-child(2),
  img:nth-child(3) {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  > img:nth-child(3) {
    width: 13vw;
    height: 13vw;
    animation: spin 12s linear infinite;
  }
  > img:nth-child(2) {
    width: 11vw;
    height: 11vw;
  }

  @keyframes spin {
    from {
      transform: translate(-50%, -50%) rotate(0deg);
    }
    to {
      transform: translate(-50%, -50%) rotate(360deg);
    }
  }

  @keyframes button-coming {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;

const Container = styled.div`
  position: relative;
  z-index: 99;
  > ${Image} {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9999;
  }

  &::before {
    content: "";
    position: absolute;
    left: 0;
    bottom: 100%;
    width: 10vw;
    aspect-ratio: 1/1;
    border-bottom-left-radius: 50%;
    background: transparent;
    box-shadow: 0 5vw 0 0 white;
  }

  &::after {
    content: "";
    position: absolute;
    right: 0;
    bottom: 100%;
    width: 10vw;
    aspect-ratio: 1/1;
    border-bottom-right-radius: 50%;
    background: transparent;
    box-shadow: 0 5vw 0 0 white;
  }
`;

const Background = styled.svg`
  width: 100%;
  margin-bottom: -0.4rem;
  position: relative;
`;

const RadiusRight = styled.div``;

function PreOrder({ onClick }: { onClick: () => void }) {
  return (
    <Container>
      {/* <Background
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1920 185"
        fill="none"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1768 0H1225C1180.82 0 1146.59 37.4049 1128.33 77.6373C1099.56 141.027 1036.45 185 962 185C887.519 185 823.317 140.985 793.988 77.5469C775.447 37.4427 741.183 0 697 0H152H0V185H962H1920V0H1768Z"
          fill="red"
        />
      </Background> */}
      <Background
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1920 195"
        fill="none"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1768 0H1225C1180.82 0 1146.59 37.4049 1128.33 77.6373C1099.56 141.027 1036.45 185 962 185C887.519 185 823.317 140.985 793.988 77.5469C775.447 37.4427 741.183 0 697 0H152H0V195H1920V0H1768Z"
          fill="white"
        />
      </Background>
      <Image onClick={onClick}>
        <img
          src="https://daziu008xh8wa.cloudfront.net/assets/Background.webp"
          alt="bg"
        />
        <img
          src="https://daziu008xh8wa.cloudfront.net/assets/Light.webp"
          alt="bg"
        />
        <img
          src="https://daziu008xh8wa.cloudfront.net/assets/Text.webp"
          alt="bg"
        />
      </Image>
    </Container>
  );
}

export default PreOrder;
