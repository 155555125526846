import styled from "styled-components";
import { breakPoints } from "../../../constant/breakpoints";
import useTheme from "../../../hooks/use-theme";

interface SelectorProps {
  title: string;
  value: string;
  onSelect?: () => void;
  isActive?: boolean;
  isReverse?: boolean;
}

const Container = styled.div<{ $isActive: boolean; $isReverse?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: ${(props) =>
    props.$isReverse ? "column-reverse" : "column"};
  flex-wrap: wrap;
  gap: 0.8rem;
  padding: 1.2rem 0.8rem;
  border-radius: 1.6rem;
  border: 1px solid #525252;
  @media (min-width: ${breakPoints.ipad}) {
    min-width: 14rem;
  }
  @media (min-width: ${breakPoints.laptop}) {
    padding: 3.2rem 0.8rem;
  }
`;

const Title = styled.p<{ fontFamily?: string }>`
  font-family: ${({ fontFamily }) => fontFamily};
  color: #2d2d2d;
  text-align: center;
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 300;
  line-height: 120%;
  @media (min-width: ${breakPoints.laptop}) {
    font-size: 2.4rem;
  }
`;

const Value = styled.p<{ fontFamily?: string }>`
  font-family: ${({ fontFamily }) => fontFamily};
  text-align: center;
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  @media (min-width: ${breakPoints.laptop}) {
    font-size: 2.4rem;
  }
`;

function MemorySelector({
  title,
  value,
  onSelect,
  isReverse,
  isActive = false,
}: SelectorProps) {
  const { fontFamily } = useTheme();
  return (
    <Container $isReverse={isReverse} $isActive={isActive} onClick={onSelect}>
      <Title fontFamily={fontFamily}>{title}</Title>
      <Value fontFamily={fontFamily}>{value}</Value>
    </Container>
  );
}

export default MemorySelector;
