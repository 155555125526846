import { ICart, IItem } from "../context/cart-context";
import {
  ProductI,
  ShipmentProduct,
} from "../pages/payment/context/payment-context";
import { Request } from "./axiosClient";

export interface LocationI {
  address1: string;
  address2: string;
  address3: string;
  address4: string;
}
export interface IDetail {
  product_code: string;
  quantity: number;
  quantityGift: number;
}
export interface OrderI {
  orders_code?: string;
  // phone_color: string;
  // quantity: number;
  customer_name: string;
  customer_email: string;
  phone_code: string;
  phone_number: string;
  receiver_phone_number: string;
  address?: string;
  company_name?: string;
  apartment?: string;
  zip_code: string;
  country_code?: string;
  state_code?: string;
  city_id?: number | null;
  ref_code?: string;
  shipment_product?: string;
  shipment_product_code?: string;
  details: IDetail[];
  payment_method?: string;
  transaction_id?: string;
  payment_status?: boolean;
  status?: string;
}

export interface ISubscribersData {
  user_email: string;
  type: string;
}
export interface ISubscribers {
  data: ISubscribersData;
}
const Api = {
  Payment: {
    getSuppliers: async function (params: {
      country_code: string;
      state_code: string;
      quantity: number;
      address_line_1: string;
    }) {
      return await Request.post(
        `${process.env.REACT_APP_DOMAIN_API}/order/rating-fee`,
        {
          data: params,
        }
      );
    },
    getDistricts: async function ({
      country_id,
      state_id,
    }: {
      country_id: number;
      state_id: number;
    }) {
      return await Request.get(
        `${process.env.REACT_APP_DOMAIN_API}/city?country_id=${country_id}&state_id=${state_id}`
      );
    },
    getCities: async function ({
      country_id,
    }: {
      country_id: number | string;
    }) {
      return await Request.get(
        `${process.env.REACT_APP_DOMAIN_API}/state?country_id=${country_id}`
      );
    },
    getPhoneCodes: async function () {
      return await Request.get(
        `${process.env.REACT_APP_DOMAIN_API}/countries?pagination%5BpageSize%5D=1000&fields=phonecode`
      );
    },
    getCountries: async function () {
      return await Request.get(`${process.env.REACT_APP_DOMAIN_API}/country`);
    },
    order: async function (params?: OrderI | null) {
      return await Request.post(
        `${process.env.REACT_APP_DOMAIN_API}/order/create-order-paypal`,
        {
          data: params,
        }
      );
    },

    stripeOrder: async function (params?: OrderI | null) {
      return await Request.post(
        `${process.env.REACT_APP_DOMAIN_API}/order/create-order-stripe`,
        {
          data: params,
        }
      );
    },
    draftOrder: async function (params?: OrderI | null) {
      return await Request.post(
        `${process.env.REACT_APP_DOMAIN_API}/order/create-order-draft`,
        {
          data: params,
        }
      );
    },
    checkoutOrderCrypto: async function (params: {
      orders_code: string;
      wallet_address: string;
    }) {
      return await Request.post(
        `${process.env.REACT_APP_DOMAIN_API}/order/checkout-order-pools`,
        {
          data: params,
        }
      );
    },
    orderShipCOD: async function (params?: OrderI | null) {
      return await Request.post(
        `${process.env.REACT_APP_DOMAIN_API}/order/ship-cod`,
        {
          data: params,
        }
      );
    },
    captureOrder: async function (params: { token: string }) {
      return await Request.post(
        `${process.env.REACT_APP_DOMAIN_API}/order/capture-order-paypal?token=${params.token}`
      );
    },

    checkoutStripe: async function (params: { sessionId: string }) {
      return await Request.get(
        `${process.env.REACT_APP_DOMAIN_API}/order/checkout-order-stripe?sessionId=${params.sessionId}`
      );
    },
  },
  Order: {
    getOrders: async function () {
      return await Request.get(
        `${process.env.REACT_APP_DOMAIN_API}/user/histories-orders`
      );
    },
    getOrderDetail: async function ({ orderId }: { orderId: string }) {
      return await Request.get(
        `${process.env.REACT_APP_DOMAIN_API}/user/order-detail?orderId=${orderId}`
      );
    },
    getTrackingOrder: async function (orderId: string) {
      return await Request.get(
        `${process.env.REACT_APP_DOMAIN_API}/order/tracking-status?orderId=${orderId}`
      );
    },
    getTrackingShipment: async function (orderId: string) {
      return await Request.get(
        `${process.env.REACT_APP_DOMAIN_API}/order/tracking?orderId=${orderId}`
      );
    },
    cancelOrder: async function (params?: {
      orderId: string;
      status: string;
      cancel_reason: string;
    }) {
      return await Request.post(
        `${process.env.REACT_APP_DOMAIN_API}/user/cancel-order`,
        {
          data: params,
        }
      );
    },
    cancelOrderManually: async function (params?: {
      full_name?: string;
      email?: string;
      phone_number?: string;
      reason: string;
      payment_service_name?: string;
      address_to_refund?: string;
      orderId: string;
    }) {
      return await Request.post(
        `${process.env.REACT_APP_DOMAIN_API}/order-cancel/manual`,
        {
          data: params,
        }
      );
    },
  },
  User: {
    login: async function (params: { email: string; password: string }) {
      console.log("params:", params);
      return await Request.post(
        `${process.env.REACT_APP_DOMAIN_API}/auth/login-email`,
        { data: params }
      );
    },
    loginGoogle: async (access_token: string) => {
      const response = await Request.get(
        `${process.env.REACT_APP_DOMAIN_API}/auth/google/callback?access_token=${access_token}`
      );
      return response;
    },
    getProfiles: async function () {
      return await Request.get(`${process.env.REACT_APP_DOMAIN_API}/users/me`);
    },
    verifyEmail: async function (params: {
      email: string;
      specificType: string;
    }) {
      return await Request.post(
        `${process.env.REACT_APP_DOMAIN_API}/email-code/verify-email`,
        {
          data: params,
        }
      );
    },
    checkVerifyCode: async function (params: { email: string; code: string }) {
      return await Request.post(
        `${process.env.REACT_APP_DOMAIN_API}/email-code/verify-email/is-valid`,
        {
          data: params,
        }
      );
    },
    checkRefCode: async function (params: { ref_code: string }) {
      return await Request.get(
        `${process.env.REACT_APP_DOMAIN_API}/user-agent/verify-refcode?ref_code=${params.ref_code}`
      );
    },
    updateName: async function (params: { fullname: string }) {
      return await Request.post(
        `${process.env.REACT_APP_DOMAIN_API}/users/update-me`,
        {
          data: params,
        }
      );
    },
    changePassword: async function (params: {
      currentPassword: string;
      password: string;
      passwordConfirmation: string;
    }) {
      return await Request.post(
        `${process.env.REACT_APP_DOMAIN_API}/auth/change-password`,
        {
          data: params,
        }
      );
    },
  },
  Subscriber: {
    subscribers: async function (params: ISubscribersData) {
      return await Request.post(
        `${process.env.REACT_APP_DOMAIN_API}/subscribers`,
        {
          data: params,
        }
      );
    },

    unsubscribe: async function (params: {
      user_email: string;
      unsubscribe_code: string;
    }) {
      return await Request.post(
        `${process.env.REACT_APP_DOMAIN_API}/subscribers/unsubscribe`,
        {
          data: params,
        }
      );
    },
  },
  Product: {
    getPrice: async () => {
      return await Request.get(
        `${process.env.REACT_APP_DOMAIN_API}/phone-prices`
      );
    },
  },
  Cart: {
    updateCart: async function (params: ICart) {
      return await Request.post(
        `${process.env.REACT_APP_DOMAIN_API}/user-cart`,
        {
          data: params,
        }
      );
    },
    updateRefCode: async function (params: {
      refCode: string;
      refCodeExpiredAt: number;
    }) {
      return await Request.post(
        `${process.env.REACT_APP_DOMAIN_API}/user-cart/update-ref-code`,
        {
          data: params,
        }
      );
    },
  },
  Popup: {
    getPopup: async function () {
      return await Request.get(`${process.env.REACT_APP_DOMAIN_API}/popup`);
    },
  },
};

export default Api;
