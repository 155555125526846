function RightIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="24"
      viewBox="0 0 14 24"
      fill="none"
    >
      <path
        d="M1.58984 22.8197L12.4096 11.9999L1.58984 1.18015"
        stroke="#2D2D2D"
        strokeWidth="2.23256"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default RightIcon;
