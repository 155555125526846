import React, { createContext, useEffect, useState } from "react";

import { Outlet } from "react-router-dom";
import ProductApi from "../api/product";

import PhoneMain from "../pages/Purchase/images/phone-main.png";
import PhoneBlack1 from "../pages/Purchase/images/phone-black-1.png";
import PhoneBlack2 from "../pages/Purchase/images/phone-black-2.png";
import PhoneBlack3 from "../pages/Purchase/images/phone-black-3.png";
import PhoneWhite1 from "../pages/Purchase/images/phone-white-1.png";
import PhoneWhite2 from "../pages/Purchase/images/phone-white-2.png";
import PhoneWhite3 from "../pages/Purchase/images/phone-white-3.png";

interface Props {
  children: React.ReactNode;
}

export enum ProductType {
  Phone = "PHONE",
  Sale_Accessories = "SALE_ACCESSORIES",
}
interface IGift {
  _id: string;
  product: {
    _id: string;
    name: string;
    code: string;
    images: string[];
  };
  quantity: number;
  quantitySold: number;
}
interface IMemory {
  name: string;
  value: string;
}
export interface IProduct {
  _id: string;
  name: string;
  code: string;
  color?: string;
  // description: string;
  origin_price: number;
  sale_price: number;
  // memory: IMemory[];
  images: string[];
  type: string;
  isDeleted: boolean;
  gifts: IGift[];
}

const productsData: IProduct[] = [
  {
    _id: "6512353037a56a3dda5c01a4",
    name: "White START24 phone",
    code: "WHITE_START24_PHONE",
    // description: "Using the START24 social networking app, you can connect with people from all over the world",
    origin_price: 390,
    sale_price: 390,
    // memory: [
    //   {
    //     name: "RAM",
    //     value: "12GB",
    //   },
    //   {
    //     name: "Memory",
    //     value: "256GB",
    //   },
    // ],
    images: [PhoneMain, PhoneWhite1, PhoneWhite2, PhoneWhite3],
    type: "PHONE",
    isDeleted: false,
    gifts: [
      {
        _id: "6512353037a56a3dda5c01a5",
        product: {
          _id: "6512353037a56a3dda5c01a5",
          name: "PHONE POUCH",
          code: "BLACK_PHONE_POUCH",
          images: [],
        },
        quantity: 1000,
        quantitySold: 0,
      },
      {
        _id: "651248d7d8cff395ea10f43e",
        product: {
          _id: "651248d7d8cff395ea10f432",
          name: "Phone Case",
          code: "PHONE_CASE",
          images: [],
        },
        quantity: 500,
        quantitySold: 0,
      },
    ],
  },
  {
    _id: "6512353037a56a3dda5c01a7",
    name: "Black START24 phone",
    code: "BLACK_START24_PHONE",
    // description:
    //   "Using the START24 social networking app, you can connect with people from all over the world",
    origin_price: 390,
    sale_price: 390,
    // memory: [
    //   {
    //     name: "RAM",
    //     value: "12GB",
    //   },
    //   {
    //     name: "Memory",
    //     value: "256GB",
    //   },
    // ],
    images: [PhoneMain, PhoneBlack1, PhoneBlack2, PhoneBlack3],
    type: "PHONE",
    isDeleted: false,
    gifts: [
      {
        _id: "6512353037a56a3dda5c01a5",
        product: {
          _id: "6512353037a56a3dda5c01a5",
          name: "PHONE POUCH",
          code: "BLACK_PHONE_POUCH",
          images: [],
        },
        quantity: 1000,
        quantitySold: 0,
      },
      {
        _id: "651248d7d8cff395ea10f43e",
        product: {
          _id: "651248d7d8cff395ea10f432",
          name: "Phone Case",
          code: "PHONE_CASE",
          images: [],
        },
        quantity: 500,
        quantitySold: 0,
      },
    ],
  },
  {
    _id: "651248d7d8cff395ea10f443",
    name: "Fast Charger",
    code: "FAST_CHARGER",
    origin_price: 0,
    sale_price: 0,
    type: "SALE_ACCESSORIES",
    isDeleted: false,
    gifts: [],
    images: [],
  },
];

interface ProductContextType {
  products: IProduct[] | [];
}

// Create the context
const ProductContext = createContext<ProductContextType>({
  products: [],
  /*eslint-disable */
  /*eslint-disable */
});

// Custom Provider component
export const ProductContextProvider = (props: Props) => {
  const [products, setProducts] = useState<IProduct[]>([]);

  const fetchData = async () => {
    const listProduct = await ProductApi.getProducts();
    if (listProduct?.length > 0) {
      setProducts(listProduct);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);
  return (
    <ProductContext.Provider
      value={{
        products,
      }}
    >
      {props.children}
    </ProductContext.Provider>
  );
};

export const ProductOutlet = (props: any) => {
  return (
    <ProductContextProvider>
      {props.children ? props.children : <Outlet />}
    </ProductContextProvider>
  );
};
export default ProductContext;
