import React, { useEffect, useState } from 'react'
import { Country } from '../pages/payment';
import Api from '../service';

const useCountry = () => {
    const [listCountries, setListCountries] = useState<Country[]>([]);
    const getCountriesAndPhoneCode = async () => {
        try {
            const response = await Api.Payment.getCountries();
            if (response?.data) {
                const data = response.data;
                setListCountries(data);
            }
        } catch (error) {
            console.log('error:', error)
        }
    };
    useEffect(() => {
        getCountriesAndPhoneCode();
    }, [])
    return { listCountries }
}

export default useCountry