import styled from "styled-components";

const Icon = styled.svg<{ $isDown?: boolean }>`
  transform: ${(props) => (props.$isDown ? "rotate(90deg)" : "rotate(0deg)")};
  transition: linear 150ms;
`;

function RightIcon({
  isDown,
  $darkMode: darkMode,
}: {
  isDown?: boolean;
  $darkMode?: boolean;
}) {
  return (
    <Icon
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      $isDown={isDown}
    >
      <path
        d="M6 12.5L10 8.5L6 4.5"
        stroke={darkMode ? "white" : "black"}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  );
}

export default RightIcon;
