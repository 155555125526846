import React, { Dispatch, SetStateAction } from "react";
import {
  ConfirmMode,
  DHL,
  ShippingContainer,
  ShippingRow,
} from "./PaymentConfirmation";
import { PaymentI } from "../../context/payment-context";
import DHLIcon from "../../icons/dhl.svg";
import EditIcon from "../../icons/edit.svg";
import { useSearchParams } from "react-router-dom";
import { SHIPMENT_SERVICE_NAME } from "../../../../constant";
import { useTranslation } from "react-i18next";
import ShipmentCheckpoints from "./ShipmentCheckpoints";

interface IProps {
  setStep?: Dispatch<SetStateAction<number>>;
  isOpen: Dispatch<SetStateAction<boolean>>;
  data: PaymentI | undefined;
  mode?: ConfirmMode;
}

const ShippingInfo = ({ setStep, isOpen, data, mode }: IProps) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { t } = useTranslation();
  return (
    <ShippingContainer>
      <ShippingRow>
        <h3>{t("Shipping Information")}</h3>
        {mode === ConfirmMode.Payment && (
          <p
            onClick={() => {
              isOpen(false);
              if (setStep) {
                setStep(2);
              }
              searchParams.set("step", "2");
              setSearchParams(searchParams);
            }}
          >
            <img src={EditIcon} alt="edit" />
          </p>
        )}
      </ShippingRow>
      <ShippingRow>
        <label>{t("Address")}</label>
        <span style={{ whiteSpace: "pre-wrap" }}>
          {data?.shipping?.address},{" "}
          {data?.shipping?.city && `${data?.shipping?.city},`}{" "}
          {data?.shipping?.state}, {data?.shipping?.country}
        </span>
      </ShippingRow>
      <ShippingRow>
        <label>{t("Post/Zip Code")}</label>
        <span>{data?.shipping?.postal_code}</span>
      </ShippingRow>
      <ShippingRow>
        <label>{t("Shipping Service")}</label>
        <DHL>
          {/* <img src={DHLIcon} alt="DLH" /> */}
          <span>
            {data?.shipping?.shipment_product_code
              ? SHIPMENT_SERVICE_NAME[
                  `${data?.shipping?.shipment_product_code}`
                ]
              : data?.shipping?.shipment_product?.productName}
          </span>
        </DHL>
      </ShippingRow>
      {mode === ConfirmMode.Tracking && <ShipmentCheckpoints />}
      <div style={{ marginBottom: "2rem" }}></div>
    </ShippingContainer>
  );
};

export default ShippingInfo;
