import { styled } from "styled-components";
import PreOrder from "./PreOrder";

const Container = styled.div`
  position: relative;
  /* margin-top: -0.4rem; */
  z-index: 0;
  position: absolute;
  bottom: 0rem;
  left: 0;
  right: 0;
`;

function Animation({ onClick }: { onClick: () => void }) {
  return (
    <Container>
      <PreOrder onClick={onClick} />
      {/* <LedPhone /> */}
    </Container>
  );
}

export default Animation;
