import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import styled, { keyframes } from "styled-components";
import { breakPoints } from "../../constant/breakpoints";
import useTheme from "../../hooks/use-theme";
import { ImagesUrl } from "../../constant";
import PreloadImage from "../../components/PreloadImage/PreloadImage";

const Main = styled.div`
  /* margin-top: 10rem; */
  margin-bottom: 10rem;
  width: 100%;
`;

const Title = styled.h1<{ fontFamily?: string }>`
  margin-top: 1.7rem;
  text-align: center;
  color: #2d2d2d;
  font-family: ${({ fontFamily }) => fontFamily || "sans-serif"};
  font-size: 4rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.24rem;
  text-transform: uppercase;
  margin-bottom: 1.6rem;
  @media (min-width: ${breakPoints.ipad}) {
    font-size: 6rem;
  }
  @media (min-width: ${breakPoints.laptop}) {
    font-size: 6.4rem;
  }
`;

const CheckIcon = styled.div`
  width: 1.6rem;
  height: 1.6rem;
  border-radius: 0.4rem;
  background: var(
    --gradient,
    linear-gradient(237deg, #1268e9 0%, #b43bf0 50.09%, #f64f59 100%)
  );
  @media (max-width: ${breakPoints.mobile}) {
  }
`;

const SubTitleWrap = styled.div`
  width: 100%;
  //margin-bottom: 3.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1.2rem;
  @media (max-width: ${breakPoints.mobile}) {
  }
`;

const SubTitle = styled.span<{ fontFamily?: string }>`
  color: #2d2d2d;
  font-family: ${({ fontFamily }) => fontFamily || "sans-serif"};
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: uppercase;
  @media (min-width: ${breakPoints.ipad}) {
    font-size: 2.4rem;
  }
`;

const SubTitle2 = styled.p`
  /* display: block; */
  /* max-width: 90rem; */
  margin: 0 auto;
  color: #8f8f8f;
  text-align: center;
  font-family: "Archivo";
  font-size: 2.4rem;
  font-style: normal;
  font-weight: 400;
  line-height: 160%; /* 38.4px */
  @media (max-width: ${breakPoints.mobile}) {
    font-size: 1.4rem;
  }
`;

const transformLeft2 = keyframes`
  0% {
    transform: translateX(0%);
    opacity: 0.8;
    visibility: hidden;
  }
  70% {
    transform: translateX(-45%);
  }
  71% {
    transform: translateX(-44%);
  }
  77% {
    transform: translateX(-43%);
  }
  83% {
    transform: translateX(-42%);
  }
  89% {
    transform: translateX(-41%);
  }
  95% {
    transform: translateX(-40%);
  }
  100% {
    transform: translateX(-40%);
    opacity: 1;
    visibility: visible;
  }
`;

const transformLeft3 = keyframes`
  0% {
    transform: translateX(0%);
    opacity: 0.8;
    visibility: hidden;
  }
  70% {
    transform: translateX(-135%);
  }
  71% {
    transform: translateX(-134%);
  }
  77% {
    transform: translateX(-133%);
  }
  83% {
    transform: translateX(-132%);
  }
  89% {
    transform: translateX(-131%);
  }
  95% {
    transform: translateX(-130%);
  }
  100% {
    transform: translateX(-130%);
    opacity: 1;
    visibility: visible;
  }
`;

const transformRight2 = keyframes`
  
  0% {
    transform: translateX(0%);
    opacity: 0.8;
    visibility: hidden;
  }
  70% {
    transform: translateX(45%);
  }
  71% {
    transform: translateX(44%);
  }
  77% {
    transform: translateX(43%);
  }
  83% {
    transform: translateX(42%);
  }
  89% {
    transform: translateX(41%);
  }
  95% {
    transform: translateX(40%);
  }
  100% {
    transform: translateX(40%);
    opacity: 1;
    visibility: visible;
  }
`;

const transformRight3 = keyframes`
 
  0% {
    transform: translateX(0%);
    opacity: 0.8;
    visibility: hidden;
  }
  70% {
    transform: translateX(135%);
  }
  71% {
    transform: translateX(134%);
  }
  77% {
    transform: translateX(133%);
  }
  83% {
    transform: translateX(132%);
  }
  89% {
    transform: translateX(131%);
  }
  95% {
    transform: translateX(130%);
  }
  100% {
    transform: translateX(130%);
    opacity: 1;
    visibility: visible;
  }
`;

const PhoneContainer = styled.div`
  position: relative;
  margin-top: 8rem;
  display: flex;
  justify-content: center;
  z-index: 0;
`;

const ImageWrapCenter = styled.div`
  z-index: 999;
  max-width: 30rem;
  width: 30rem;
  max-height: 77rem;
  @media (max-width: ${breakPoints.laptop}) {
    max-width: 20rem;
    width: 20rem;
  }
  @media (max-width: ${breakPoints.ipad}) {
    max-width: 15rem;
    width: 15rem;
  }
  @media (max-width: ${breakPoints.mobile}) {
    max-width: 8.5rem;
    width: 8.5rem;
  }
  @media (max-width: 375px) {
    max-width: 7.2rem;
    width: 7.2rem;
  }
  @media (max-width: 320px) {
    max-width: 6.2rem;
    width: 6.2rem;
  }
`;

const ImageWrap2 = styled.div<{
  left?: boolean;
  right?: boolean;
}>`
  z-index: 99;
  position: absolute;
  max-width: 27rem;
  width: 27rem;
  max-height: 77rem;
  //height: 77rem;
  opacity: 0.01;
  visibility: hidden;
  animation: ${({ left }) => (left ? transformLeft2 : transformRight2)} 0.8s
    linear forwards;
  animation-delay: 0.2s;
  ${({ left }) => left && "right: 50%;"};
  ${({ right }) => right && "left: 50%;"};
  @media (max-width: ${breakPoints.laptop}) {
    max-width: 18rem;
    width: 18rem;
  }
  @media (max-width: ${breakPoints.ipad}) {
    max-width: 13rem;
    width: 13rem;
  }
  @media (max-width: ${breakPoints.mobile}) {
    max-width: 7.4rem;
    width: 7.4rem;
  }
  @media (max-width: 375px) {
    max-width: 6.4rem;
    width: 6.4rem;
  }
  @media (max-width: 320px) {
    max-width: 5.5rem;
    width: 5.5rem;
  }
`;

const ImageWrap3 = styled.div<{
  left?: boolean;
  right?: boolean;
}>`
  z-index: 9;
  position: absolute;
  max-width: 27rem;
  width: 27rem;
  max-height: 76.5rem;
  opacity: 0.01;
  visibility: hidden;
  animation: ${({ left }) => (left ? transformLeft3 : transformRight3)} 0.8s
    linear forwards;
  animation-delay: 1.4s;

  ${({ left }) => left && "right: 50%;"};
  ${({ right }) => right && "left: 50%;"};

  @media (max-width: ${breakPoints.laptop}) {
    max-width: 18rem;
    width: 18rem;
  }
  @media (max-width: ${breakPoints.ipad}) {
    max-width: 13rem;
    width: 13rem;
  }
  @media (max-width: ${breakPoints.mobile}) {
    max-width: 7.4rem;
    width: 7.4rem;
  }
  @media (max-width: 375px) {
    max-width: 6.4rem;
    width: 6.4rem;
  }
  @media (max-width: 320px) {
    max-width: 5.5rem;
    width: 5.5rem;
  }
`;

const IntroducingHome = () => {
  const { t } = useTranslation();
  const [isShow, setIsShow] = useState(false);
  const { fontFamily } = useTheme();

  useEffect(() => {
    const listenScrollChange = () => {
      const element = document.getElementById("introducing-home");

      if (
        element?.getBoundingClientRect().top &&
        element?.getBoundingClientRect().top <= 30
      ) {
        setIsShow(true);
      }
    };
    listenScrollChange();

    window.addEventListener("scroll", listenScrollChange);
    return () => {
      window.removeEventListener("scroll", listenScrollChange);
    };
  }, []);

  return (
    <Main id="introducing-home">
      <SubTitleWrap>
        <CheckIcon />
        <SubTitle fontFamily={fontFamily}>{t("Introducing")}</SubTitle>
      </SubTitleWrap>

      <Title fontFamily={fontFamily}>{t("Start24 Phone")}</Title>
      <SubTitle2>
        {t(
          "Immerse yourself in an engaging world of opportunity with Brilliant Beginnings, Unlimited Rewards."
        )}
      </SubTitle2>
      <PhoneContainer>
        {isShow && (
          <div>
            <ImageWrap3 left>
              <PreloadImage
                src={ImagesUrl.Introducing_Home_L2}
                alt="Phone L2"
              />
            </ImageWrap3>
            <ImageWrap2 left>
              <PreloadImage
                src={ImagesUrl.Introducing_Home_L1}
                alt="Phone L1"
              />
            </ImageWrap2>
            <ImageWrap2 right>
              <PreloadImage
                src={ImagesUrl.Introducing_Home_R1}
                alt="Phone R1"
              />
            </ImageWrap2>
            <ImageWrap3 right>
              <PreloadImage
                src={ImagesUrl.Introducing_Home_R2}
                alt="Phone R2"
              />
            </ImageWrap3>
          </div>
        )}
        <ImageWrapCenter>
          <PreloadImage src={ImagesUrl.Introducing_Home_C} alt="Phone Center" />
        </ImageWrapCenter>
      </PhoneContainer>
    </Main>
  );
};

export default IntroducingHome;
