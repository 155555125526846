export const ImagesUrl = {
    Black_Back_Start24: 'https://daziu008xh8wa.cloudfront.net/assets/Black-back-START24+.webp',
    Phone_Front: 'https://daziu008xh8wa.cloudfront.net/assets/phone_front.webp',
    Phone_White: 'https://daziu008xh8wa.cloudfront.net/assets/1703141941076.webp',
    Phone_White2: 'https://daziu008xh8wa.cloudfront.net/assets/1703738345903.webp',
    Phone_Black: 'https://daziu008xh8wa.cloudfront.net/assets/phone-black.webp',
    Spec_Fifth_First: 'https://daziu008xh8wa.cloudfront.net/assets/1703142128158.webp',
    Spec_Fifth_Second: 'https://daziu008xh8wa.cloudfront.net/assets/1703142184943.webp',
    Spec_First_First: 'https://daziu008xh8wa.cloudfront.net/assets/1703142214696.webp',
    Spec_First_Second: 'https://daziu008xh8wa.cloudfront.net/assets/1703142237593.webp',
    Spec_Fourth_Second: 'https://daziu008xh8wa.cloudfront.net/assets/1703142263725.webp',
    Spec_Second_Second: 'https://daziu008xh8wa.cloudfront.net/assets/1703142289121.webp',
    Spec_Third_First: 'https://daziu008xh8wa.cloudfront.net/assets/1703142315540.webp',
    Spec_Third_Third: 'https://daziu008xh8wa.cloudfront.net/assets/1703142336729.webp',
    Youtube_Thumbnail_1: 'https://daziu008xh8wa.cloudfront.net/assets/1703143388421.webp',
    Youtube_Thumbnail_2: 'https://daziu008xh8wa.cloudfront.net/assets/1703143439022.webp',
    Youtube_Thumbnail_3: 'https://daziu008xh8wa.cloudfront.net/assets/1703143453568.webp',
    Youtube_Thumbnail_4: 'https://daziu008xh8wa.cloudfront.net/assets/1703738285003.webp',

    // Introducing_Home_C: 'https://daziu008xh8wa.cloudfront.net/assets/C.webp',
    Introducing_Home_C: 'https://daziu008xh8wa.cloudfront.net/assets/1703739683092.webp',
    Introducing_Home_R1: 'https://daziu008xh8wa.cloudfront.net/assets/R1.webp',
    Introducing_Home_R2: 'https://daziu008xh8wa.cloudfront.net/assets/R2.webp',
    Introducing_Home_L1: 'https://daziu008xh8wa.cloudfront.net/assets/L1.webp',
    Introducing_Home_L2: 'https://daziu008xh8wa.cloudfront.net/assets/L2.webp',

}

export enum LanguageKeys {
    vi = 'vi',
    en = 'en',
    jp = 'jp',
    es = 'es',
    zh = 'zh',
    ko = 'ko',
    pt = 'pt',
}
type ShipmentServiceName = {
    [key: string]: string;
}
export const SHIPMENT_SERVICE_NAME: ShipmentServiceName = {
    'spaceship-air-standard': 'Spaceship Air Standard',
    "spaceship-air-standard-ioss": "Spaceship Air Standard (IOSS)",
    "spaceship-air-premium": "Spaceship Air Premium",
    "spaceship-air-economy": "Spaceship Air Economy",
    "ups-saver": "UPS Worldwide Express Saver",
    "ups-worldwide-express": "UPS Worldwide Express",
    "ups-worldwide-express-plus": "UPS Worldwide Express Plus",
    "fedex-ip": "FedEx International Priority",
    "fedex-ie": "FedEx International Economy",
    "fedex-ipe": "FedEx International Priority Express",
    "fedex-icp": "FedEx International Connect Plus",
    "aramex-dpx": "Aramex Deferred Parcel Express (DPX)",
    "aramex-ppx": "Aramex Priority Parcel Express (PPX)",
    "dpex-express": "DPEX Express",
    "sf-standard-express": "SF Standard Express (Supported destination: HK to Zone A countries (MY, SG, KR, TH))",
    "sf-speedy-express": "SF Speedy Express (Supported destination: HK to HK, HK to MO)",
    "hkpost-air-parcel": "Hongkong Post - Air Parcel",
    "hkpost-air-registered-mail": "Hongkong Post - Air Registered Letter/Packet",
    "hkpost-e-express": "Hongkong Post - e-Express",
    "hkpost-speedpost": "Hongkong Post - Speedpost",
}