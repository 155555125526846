import React, { useState } from "react";
import styled from "styled-components";
import CopyIcon from "../../icons/CopyIcon";
import { useSearchParams } from "react-router-dom";
import useTrackingOrder from "../../../../hooks/use-tracking-order";
import Spin from "../../../../components/Spin";
import { breakPoints } from "../../../../constant/breakpoints";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { Break, FirstBreak, Row } from "./Tracking";
import useTrackingShipment from "../../../../hooks/use-tracking-shipment";
import { formattedDateTimeBefore } from "../../../../utils/utils";

export interface ITracking {
  time: string;
  status: string;
}
const ShipmentCheckpoints = () => {
  const [searchParams] = useSearchParams();
  const orderId = searchParams.get("orderId");
  const { t } = useTranslation();

  const [showMore, setShowMore] = useState(false);

  const {
    data: trackingShipmentData,
    shipmentCheckpoints,
    loading,
  } = useTrackingShipment({
    orderId: orderId as string,
  });

  if (loading) {
    return (
      <Loading>
        <Spin />
      </Loading>
    );
  }

  if (!orderId || (shipmentCheckpoints && shipmentCheckpoints.length === 0)) {
    return null;
  }

  return (
    <Wrapper>
      <Container>
        <TimeLineContainer>
          {showMore ? (
            shipmentCheckpoints.map((item, index) => (
              <Row key={index} active={index === 0}>
                {index === 0 ? <FirstBreak /> : <Break />}
                <div>{formattedDateTimeBefore(item.time)}</div>
                <div>{item.status}</div>
              </Row>
            ))
          ) : (
            <Row active={true}>
              <FirstBreak />
              <div>{formattedDateTimeBefore(shipmentCheckpoints[0].time)}</div>
              <div style={{ flexGrow: 1 }}>{shipmentCheckpoints[0].status}</div>
            </Row>
          )}
          <ShowMoreBtn>
            <button onClick={() => setShowMore(!showMore)}>
              {!showMore ? t("Show more") : t("Show less")}
            </button>
          </ShowMoreBtn>
        </TimeLineContainer>
        {/*  */}
        <TextInfoContainer>
          {/* warehouse */}
          <InfoContainer>
            <h4>Warehouse</h4>
            <span>ID: {trackingShipmentData?.warehouse_order_code}</span>
            <span>
              {t("Status")}:{" "}
              <span>{trackingShipmentData?.warehouse_status}</span>
            </span>
            <span>
              {t("Error")}: {trackingShipmentData?.warehouse_error}
            </span>
          </InfoContainer>
          {/* spaceship */}
          <InfoContainer>
            <h4>Spaceship</h4>
            <span>ID: {trackingShipmentData?.shipment_order_code}</span>
            <span>
              {t("Status")}:{" "}
              <span>{trackingShipmentData?.shipment_status}</span>{" "}
            </span>
            <span>
              {t("Error")}: {trackingShipmentData?.shipment_error}
            </span>
          </InfoContainer>
        </TextInfoContainer>
      </Container>
    </Wrapper>
  );
};

export default ShipmentCheckpoints;
const Loading = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;
const TimeLineContainer = styled.div`
  width: 100%;
`;
const TextInfoContainer = styled.div`
  margin-top: 3rem;
  width: 100%;
  display: flex;
  justify-content: space-between;
`;
const InfoContainer = styled.div`
  width: 45%;
  font-family: "Archivo";
  font-size: 1.6rem;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.016rem;
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
  > h4 {
    color: var(--gray-scale-black-80, #2d2d2d);
    font-weight: 700;
  }
  > span {
    margin-left: 2rem;
    display: block;
    color: var(--gray-scale-black-80, #525252);
    font-weight: 400;
    &:nth-child(3) > span {
      text-transform: uppercase;
    }
  }
  @media (max-width: ${breakPoints.mobile}) {
    font-size: 1.4rem;
    > h3 {
      font-size: 1.4rem;
    }
  }
`;

const ShowMoreBtn = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 1rem;
  > button {
    border: none;
    background-color: transparent;
    cursor: pointer;

    color: var(--gray-scale-black-40, #8f8f8f);
    text-align: center;
    font-family: "Archivo";
    font-size: 1.4rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-decoration-line: underline;
  }
`;
const Container = styled.div`
  width: 100%;
  height: 100%;
`;
const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
`;
