import styled from "styled-components";
import React, { useContext, useState, useEffect, useRef } from "react";
import LogoDarkIcon from "./icons/LogoDarkIcon";
import LogoLightIcon from "./icons/LogoLightIcon";
import MenuDarkIcon from "./icons/menu_dark.svg";
import MenuLightIcon from "./icons/menu_light.svg";
import UserIcon from "./icons/UserIcon";
import { ButtonGradient } from "../ButtonGradient";

import AuthContext from "../../context/auth-context";
import CloseIcon from "./icons/x.svg";
import { Link, useLocation } from "react-router-dom";
import { breakPoints } from "../../constant/breakpoints";
import UserDropdown from "../UserDropdown";
import { useOnClickOutside } from "../../hooks/useOnClickOutside";
import { useTranslation } from "react-i18next";
import Cart from "./icons/Cart";
import useCart from "../../hooks/use-cart";
import LanguageSelector from "../LanguageSelector";

const Container = styled.div<{ $isAnimation: boolean }>`
  margin: 0 auto;
  width: 100%;
  padding: 0 2rem;
  display: flex;
  align-items: center;
  position: relative;
  z-index: 1;
  max-width: 144rem;
  animation: ${(props) =>
    props.$isAnimation ? "coming 1s linear 9s forwards" : "none"};
  transform: ${(props) => (props.$isAnimation ? "translateY(-100%)" : "none")};
  opacity: ${(props) => (props.$isAnimation ? 0 : 1)};
  @keyframes coming {
    0% {
      opacity: 0.01;
      transform: translateY(-100%);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
  @media (min-width: ${breakPoints.ipad}) {
    padding-top: 2rem;
  }
  @media (min-width: ${breakPoints.laptop}) {
    padding-top: 3rem;
  }
  @media (min-width: ${breakPoints.desktop}) {
    padding-top: 4rem;
  }
`;

const ContentLeft = styled.a`
  flex: 1;
  > svg {
    width: 8.1rem;
    height: auto;
  }
  @media (min-width: ${breakPoints.ipad}) {
    flex: none;
  }
  @media (min-width: ${breakPoints.laptop}) {
    > svg {
      width: 12rem;
    }
  }
`;

const ContentCenter = styled.div`
  flex: 1;
  display: none;
  a {
    color: #525252;
    font-family: "Archivo";
    font-size: 1.6rem;
    font-style: normal;
    font-weight: 400;
    line-height: 180%; /* 43.2px */
  }

  @media (min-width: ${breakPoints.ipad}) {
    display: flex;
    align-items: center;
    column-gap: 2rem;
    justify-content: center;
  }

  @media (min-width: ${breakPoints.laptop}) {
    column-gap: 4rem;
    a {
      font-family: "Archivo";
      font-size: 1.8rem;
      font-style: normal;
      font-weight: 400;
      line-height: 180%;
    }
  }
  @media (min-width: ${breakPoints.desktop}) {
    column-gap: 5rem;
    a {
      font-size: 2.4rem;
    }
  }
`;

const ContentRight = styled.div`
  display: flex;
  align-items: center;
  column-gap: 0.8rem;

  svg {
    cursor: pointer;
    width: 2.4rem;
  }

  @media (min-width: ${breakPoints.laptop}) {
    column-gap: 1.2rem;

    svg {
      width: 3.2rem;
    }
  }
`;

const Button = styled.a`
  &:first-child {
    display: none;
  }
  > button {
    padding: 0.4rem 0.8rem;
    border-radius: 5rem;
    color: #fff;
    font-family: "Archivo";
    font-size: 1.1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    letter-spacing: 0.014rem;
  }

  @media (min-width: ${breakPoints.ipad}) {
    &:first-child {
      display: flex;
    }
    > button {
      display: flex;
      padding: 1rem;
      justify-content: center;
      align-items: center;
      color: #fff;
      font-family: "Archivo";
      font-size: 1.4rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }

  @media (min-width: ${breakPoints.laptop}) {
    > button {
      display: flex;
      padding: 0.8rem 2rem;
      justify-content: center;
      align-items: center;
      color: #fff;
      font-family: "Archivo";
      font-size: 1.6rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }

  @media (min-width: ${breakPoints.desktop}) {
    > button {
      display: flex;
      padding: 1.4rem 2.8rem;
      justify-content: center;
      align-items: center;
      color: #fff;
      font-family: "Archivo";
      font-size: 24px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
`;

const MenuButton = styled.img`
  display: block;
  @media (min-width: ${breakPoints.ipad}) {
    display: none;
  }
`;

const ModalMobileContainer = styled.div<{ $display: boolean }>`
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: auto;
  padding: 0 0.8rem;
  transform: ${(props) =>
    props.$display ? "translateY(0)" : "translateY(-100%)"};
  opacity: ${(props) => (props.$display ? 1 : 0)};
  transition: all ease 300ms;
  overflow-y: hidden;
  /* transform: translateY(-100%); */
  /* animation: ${(props) => (props.$display ? "modal-in" : "modal-out")}
    ease-in-out 300ms; */
  /* animation-fill-mode: forwards; */

  @keyframes modal-in {
    0% {
      display: flex;
      transform: translateY(-100%);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }
  @keyframes modal-out {
    0% {
      transform: translateY(0);
      opacity: 1;
    }
    100% {
      transform: translateY(-100%);
      opacity: 0;
      display: none;
    }
  }
`;

const ModalMobileContent = styled.div`
  max-width: 43rem;
  min-width: 80%;
  max-height: 90%;
  margin: 7rem 2rem;
  border-radius: 0.8rem;
  height: fit-content;
  width: 3rem;
  display: flex;
  padding: 19px 24px 223px 24px;
  flex-direction: column;
  align-items: flex-end;
  gap: 24px;
  background: #fff;
  > div:last-child {
    width: 100%;
  }
  > div > img {
    max-width: 3rem;
    width: 3rem;
    aspect-ratio: 1/1;
  }
`;

const ModalButton = styled.div`
  width: 100%;
  margin-bottom: 4rem;
  > a:first-child {
    width: 100%;
    display: flex;
    padding: 8px 24px;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    border-radius: 500px;
    color: #222;

    /* BODY 3 */
    font-family: "Archivo";
    font-size: 1.4rem;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    letter-spacing: 0.014rem;
    text-decoration: none;
  }

  > a:last-child {
    display: flex;
    padding: 8px 24px;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    border-radius: 50rem;
    background: linear-gradient(
      250deg,
      #1268e9 -6.87%,
      #b43bf0 52.89%,
      #f64f59 112.42%
    );
    color: #fff;

    /* BODY 3 */
    font-family: "Archivo";
    font-size: 1.4rem;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 21px */
    letter-spacing: 0.014rem;
  }
`;

const ModalNavigation = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
  a,
  div {
    width: 100%;
    display: flex;
    padding-right: 0px;
    align-items: center;
    align-self: stretch;
    color: #222;

    /* BODY 2 */
    font-family: "Archivo";
    font-size: 1.6rem;
    font-style: normal;
    font-weight: 400;
    line-height: 170%; /* 27.2px */
    letter-spacing: 0.016rem;
  }
`;

const BreakLine = styled.span`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  border-bottom: 1px solid var(--gray-scale-black-20, #d1d1d1);
`;

const MenuWrap = styled.span`
  position: relative;
  /* &:hover .sub-menu {
    transform: scaleY(1);
  } */
`;

const MenuText = styled(Link)`
  color: #525252;
  font-family: "Archivo";
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 400;
  line-height: 180%; /* 43.2px */
  @media (min-width: ${breakPoints.laptop}) {
    font-family: "Archivo";
    font-size: 1.8rem;
    font-style: normal;
    font-weight: 400;
    line-height: 180%;
  }
  @media (min-width: ${breakPoints.desktop}) {
    font-size: 2.4rem;
  }
`;

const SubMenuWrap = styled.div<{
  $darkMode: boolean;
  $display: boolean;
}>`
  position: absolute;
  left: 0;
  //margin-top: 2rem;
  min-width: 16rem;
  width: fit-content;
  border-radius: 2.4rem;
  border: 1px solid ${(props) => (props.$darkMode ? "transparent" : "#ccc")};
  background-color: ${(props) => (props.$darkMode ? "#2D2D2D" : "#fff")};
  overflow: hidden;
  color: ${(props) => (props.$darkMode ? "#fff" : "#2D2D2D")};
  cursor: default;
  transform-origin: top center;
  user-select: none;
  transform: ${(props) => (props.$display ? "scaleY(1)" : "scaleY(0)")};
  //transform: scaleY(0);
  transition: ease-in-out 150ms;

  @keyframes growDown {
    0% {
      transform: scaleY(0);
    }
    80% {
      transform: scaleY(1.1);
    }
    100% {
      transform: scaleY(1);
    }
  }

  @keyframes growUp {
    0% {
      transform: scaleY(1);
    }
    80% {
      transform: scaleY(1.1);
    }
    100% {
      transform: scaleY(0);
    }
  }

  /* @media (min-width: ${breakPoints.mobile}) {
    width: 33.6rem;
    right: 0;
  } */
`;

const SubMenu = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  padding: 1.6rem 2.4rem;
  white-space: nowrap;
  a {
    font-family: Archivo;
    font-size: 1.6rem;
    font-style: normal;
    font-weight: 400;
    line-height: 170%; /* 27.2px */
    letter-spacing: 0.016rem;
  }
`;

const GroupUserContainer = styled.div<{ $quantity: number }>`
  display: flex;
  gap: 1rem;
  > a {
    position: relative;
    > div {
      position: absolute;
      top: ${({ $quantity: quantity }) =>
        quantity >= 100 ? "-0.8rem" : "-0.2rem"};
      right: ${({ $quantity: quantity }) =>
        quantity >= 100 ? "-1rem" : " -0.5rem"};
      width: ${({ $quantity: quantity }) =>
        quantity >= 100 ? "2.5rem" : "1.6rem"};
      height: ${({ $quantity: quantity }) =>
        quantity >= 100 ? "2.5rem" : "1.6rem"};
      border-radius: 50%;
      background-color: rgba(0, 0, 0, 0.5);
      display: flex;
      justify-content: center;
      align-items: center;
      span {
        color: #fff;
      }
    }
  }
`;

function Header() {
  const { t } = useTranslation();
  const { getNumberOfItems } = useCart();
  const dropdownRef = useRef<HTMLDivElement>(null);
  const { pathname } = useLocation();
  const isDark =
    pathname === "/" || pathname === "/media" || pathname === "/support";
  const { logout, isLogin } = useContext(AuthContext);
  const [displayModal, setDisplayModal] = useState<boolean>(false);
  const [displayDropdown, setDisplayDropdown] = useState<boolean>(false);
  const [displaySubMenu, setDisplaySubMenu] = useState<boolean>(false);
  const [urlActive, setUrlActive] = useState<string>("/");
  const [isFirstTime, setIsFirstTime] = useState<boolean>(true);

  const sitemap = [
    {
      link: "/",
      text: t("Product"),
    },
    {
      link: "/purchase",
      text: t("Shop"),
      subMenu: [
        {
          link: "/purchase/phone/black",
          text: t("Start24 Phone"),
        },
        {
          link: "/purchase/accessory/pouch-white",
          text: t("White Phone Pouch"),
        },
        {
          link: "/purchase/accessory/pouch-black",
          text: t("Black Phone Pouch"),
        },
        {
          link: "/purchase/accessory/adapter",
          text: t("Adapter"),
        },
        {
          link: "/purchase/accessory/cable",
          text: t("Cable"),
        },
        {
          link: "/purchase/accessory/phone-case",
          text: t("Phone Case"),
        },
      ],
    },
    {
      link: "/media",
      text: t("Media"),
    },
    {
      link: "/community",
      text: t("Community"),
    },
    {
      link: "/support",
      text: t("Support"),
    },
  ];

  useEffect(() => {
    setUrlActive(pathname);
    if (isFirstTime) {
      setTimeout(() => {
        localStorage.setItem("isFirst", JSON.stringify(false));
        setIsFirstTime(false);
      }, 10000);
    }
  }, [pathname, isLogin]);

  useEffect(() => {
    setTimeout(() => {
      setIsFirstTime(false);
    }, 10000);
  }, []);

  useOnClickOutside(dropdownRef, () => {
    setDisplayDropdown(false);
  });

  return (
    <>
      <Container
        id="header_menu"
        style={{
          backgroundColor:
            pathname === "/" ? "transparent" : isDark ? "#121212" : "#fff",
        }}
        $isAnimation={isFirstTime && pathname === "/"}
      >
        <ContentLeft href="/" aria-label="Start24 Home page">
          {isDark ? <LogoLightIcon /> : <LogoDarkIcon />}
        </ContentLeft>
        <ContentCenter>
          {sitemap.map((item, index) =>
            item?.subMenu?.length ? (
              <MenuWrap
                onMouseOver={() => setDisplaySubMenu(true)}
                onMouseOut={() => setDisplaySubMenu(false)}
                key={index}
              >
                <MenuText
                  onClick={() => setDisplaySubMenu(false)}
                  to={item.link}
                  style={{
                    color:
                      urlActive.replace("/", "") === "" && item.link === "/"
                        ? isDark
                          ? "#fff"
                          : "#121212"
                        : urlActive.includes(item.link) && item.link !== "/"
                        ? isDark
                          ? "#fff"
                          : "#121212"
                        : "#8F8F8F",
                  }}
                >
                  {t(item.text)}
                </MenuText>
                <SubMenuWrap
                  //className="sub-menu"
                  $darkMode={!isDark}
                  $display={displaySubMenu}
                >
                  <SubMenu>
                    {item?.subMenu?.map((subItem, index) => (
                      <Link
                        onClick={() => setDisplaySubMenu(false)}
                        to={subItem.link}
                        key={index}
                        style={{
                          color:
                            urlActive.includes(subItem.link) &&
                            subItem.link !== "/"
                              ? "#8F8F8F"
                              : isDark
                              ? "#121212"
                              : "#fff",
                        }}
                      >
                        {t(subItem.text)}
                      </Link>
                    ))}
                  </SubMenu>
                </SubMenuWrap>
              </MenuWrap>
            ) : (
              <Link
                to={item.link}
                key={index}
                style={{
                  color:
                    urlActive.replace("/", "") === "" && item.link === "/"
                      ? isDark
                        ? "#fff"
                        : "#121212"
                      : urlActive.includes(item.link) && item.link !== "/"
                      ? isDark
                        ? "#fff"
                        : "#121212"
                      : "#8F8F8F",
                }}
              >
                {t(item.text)}
              </Link>
            )
          )}
          {/* <Link style={{
          color: urlActive === '/' ?
        }} to={"/"}>{t("Product")}</Link>
        <Link to={"/purchase"}>{t("Purchase")}</Link>
        <Link to={"/media"}>{t("Media")}</Link>
        <Link to={"/community"}>{t("Community")}</Link>
        <Link to={"/support"}>{t("Support")}</Link> */}
        </ContentCenter>
        <ContentRight>
          <LanguageSelector />
          {/* coming soon */}
          {isLogin ? (
            <GroupUserContainer
              ref={dropdownRef}
              $quantity={getNumberOfItems()}
            >
              {/* <Link to={"/purchase/payment?firstStep=1"}>
                <div>
                  <span>{getNumberOfItems()}</span>
                </div>
                <Cart isDark={isDark} />
              </Link> */}
              <UserIcon
                isDark={isDark}
                onClick={() => {
                  setDisplayDropdown(!displayDropdown);
                }}
              />
              <UserDropdown
                display={displayDropdown}
                setDisplayDropdown={setDisplayDropdown}
              />
            </GroupUserContainer>
          ) : (
            <>
              <Button href="https://agent.startmobis.com/">
                <ButtonGradient>{t("Be an Agent")}</ButtonGradient>
              </Button>
              <Button href="/sign-in">
                <ButtonGradient>{t("Sign In")}</ButtonGradient>
              </Button>
            </>
          )}
          <MenuButton
            onClick={() => {
              setDisplayModal(true);
            }}
            src={isDark ? MenuLightIcon : MenuDarkIcon}
            alt="menu-icon"
          />
        </ContentRight>
      </Container>
      {/* menu mobile */}
      <ModalMobileContainer
        onClick={() => {
          setDisplayModal(false);
        }}
        $display={displayModal}
      >
        <ModalMobileContent onClick={(e) => e.stopPropagation()}>
          <div>
            <img
              onClick={() => {
                setDisplayModal(false);
              }}
              src={CloseIcon}
              alt="close"
            />
          </div>
          <div>
            {!isLogin && (
              <ModalButton>
                <a href="/sign-in">{t("Sign In")}</a>
                <a href="/sign-up">{t("Sign Up")}</a>
              </ModalButton>
            )}
            <ModalNavigation
              onClick={() => {
                setDisplayModal(false);
              }}
            >
              <Link to="/">{t("Product")}</Link>
              <Link to="/purchase">{t("Shop")}</Link>
              <Link to="/media">{t("Media")}</Link>
              <Link to="/community">{t("Community")}</Link>
              <Link to="/support">{t("Support")}</Link>
              <Link
                to="https://daziu008xh8wa.cloudfront.net/assets/REFERRAL.pdf"
                target="_blank"
              >
                {t("Referral Policy")}
              </Link>
              <ModalButton>
                <Link to="https://agent.startmobis.com/" target="_blank">
                  {t("Be an Agent")}
                </Link>
              </ModalButton>
              {isLogin && <BreakLine />}
              {isLogin && <div onClick={logout}>{t("Logout")}</div>}
            </ModalNavigation>
          </div>
        </ModalMobileContent>
      </ModalMobileContainer>
    </>
  );
}

export default Header;
