import styled from "styled-components";
import SocialItem from "./SocialItem";
import FBIcon from "./icons/fb.svg";
import MediumIcon from "./icons/medium.svg";
import YTIcon from "./icons/yt.svg";
import TelegramIcon from "./icons/telegram.svg";
import TiktokIcon from "./icons/tiktok.svg";
import InstagramIcon from "./icons/ig.svg";
import { breakPoints } from "../../constant/breakpoints";
import { useTranslation } from "react-i18next";
import useTheme from "../../hooks/use-theme";
interface Social {
  link: string;
  icon: string;
  text: string;
}

const Container = styled.div`
  margin: 4rem 0;
  display: grid;
  grid-template-columns: repeat(5, minmax(0, 1fr));
  column-gap: 4rem;
  align-items: start;

  @media (min-width: ${breakPoints.laptop}) {
    margin: 12rem 0;
  }
`;

const Title = styled.h2<{ fontFamily?: string }>`
  color: #2d2d2d;
  font-family: ${({ fontFamily }) => fontFamily};
  font-size: 2.4rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.192rem;
  grid-column: span 5 / span 5;
  text-align: center;

  @media (min-width: ${breakPoints.ipad}) {
    font-size: 3.6rem;
  }

  @media (min-width: ${breakPoints.laptop}) {
    font-size: 9.6rem;
    grid-column: span 2 / span 2;
    text-align: left;
  }
`;

const Description = styled.p`
  margin-top: 0.8rem;
  grid-column: span 5 / span 5;
  text-align: center;
  color: #8f8f8f;
  font-family: "Archivo";
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 300;
  line-height: 150%;
  letter-spacing: 0.014rem;

  @media (min-width: ${breakPoints.ipad}) {
    font-size: 1.4rem;
  }

  @media (min-width: ${breakPoints.laptop}) {
    margin-top: 2.4rem;
    margin-right: -2rem;
    grid-column: span 3 / span 3;
    font-size: 1.6rem;
    color: #525252;
    text-align: left;
    font-weight: 400;
    letter-spacing: 0.016rem;
    line-height: 170%;
  }
  @media (min-width: ${breakPoints.desktop}) {
    grid-column: span 2 / span 2;
  }
`;

const List = styled.div`
  margin-top: 2rem;
  grid-column: span 5 / span 5;
  display: flex;
  flex-wrap: wrap;
  gap: 1.6rem;
  align-items: center;
  justify-content: center;
  @media (min-width: ${breakPoints.laptop}) {
    gap: 2.8rem;
    flex-wrap: nowrap;
    margin-top: 7rem;
  }
  @media (min-width: ${breakPoints.desktop}) {
    gap: 4rem;
  }
`;

function SocialList() {
  const { fontFamily } = useTheme();
  const { t } = useTranslation();

  const socials: Social[] = [
    {
      link: "https://www.facebook.com/start24phone",
      icon: FBIcon,
      text: t("Facebook"),
    },
    {
      link: "https://medium.com/@start24",
      icon: MediumIcon,
      text: t("Medium"),
    },
    {
      link: "https://t.me/start24phone",
      icon: TelegramIcon,
      text: t("Telegram"),
    },
    {
      link: "https://www.instagram.com/start24phone/",
      icon: InstagramIcon,
      text: t("Instagram"),
    },
    {
      link: "https://www.youtube.com/@START24Phone",
      icon: YTIcon,
      text: t("Youtube"),
    },
    {
      link: "https://www.tiktok.com/@start24phone",
      icon: TiktokIcon,
      text: t("Tiktok"),
    },
  ];

  return (
    <Container>
      <Title fontFamily={fontFamily}>{t("SOCIAL")}</Title>
      <Description>
        {t(
          "Stay current with START24 across social media and beyond, discovering fresh news, exclusive offers, and the latest smartphone updates!"
        )}
      </Description>
      <List>
        {socials.map((item, index) => (
          <SocialItem
            key={index}
            link={item.link}
            icon={item.icon}
            text={item.text}
          />
        ))}
      </List>
    </Container>
  );
}

export default SocialList;
