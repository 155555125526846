import styled from "styled-components";
import { ButtonGradient } from "../ButtonGradient";
import EyeIcon from "../../assets/icons/eye.svg";
import EyeSlashIcon from "../../assets/icons/eye-slash.svg";
import { useState, useContext } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router";
import AuthContext from "../../context/auth-context";
import { breakPoints } from "../../constant/breakpoints";
import X from "../../assets/icons/x.svg";
import UserApi from "../../api/user";
import { useTranslation } from "react-i18next";
const Wrapper = styled.div`
  cursor: default;
  position: fixed !important;
  padding: 0 0.8rem;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 99999999999;
  min-height: 100vh;
  background-color: rgba(0, 0, 0, 0.7);
  overflow: hidden;
  scroll-behavior: unset;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Container = styled.div`
  border-radius: 2.4rem;
  background: #fff;
  box-shadow: 1px 1px 10px 0px rgba(0, 0, 0, 0.1);
  display: flex;
  width: 100%;
  margin: 0 0.8rem;
  padding: 2rem;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  > h1 {
    width: 100%;
    color: #2d2d2d;
    text-align: center;
    font-family: "Archivo";
    font-size: 2.8rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    position: relative;
    > img {
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      display: none;
      cursor: pointer;
    }
  }
  > form {
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 2.4rem;
  }
  @media (min-width: ${breakPoints.mobile}) {
    > h1 > img {
      display: block;
    }
  }
  @media (min-width: ${breakPoints.ipad}) {
    gap: 3.2rem;
    width: 60rem;
    padding: 3.6rem;
  }
`;

const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  width: 100%;
  row-gap: 1.2rem;
  > label {
    color: var(--gray-scale-black-60, #525252);
    font-family: "Archivo";
    font-size: 2rem;
    font-style: normal;
    font-weight: 600;
    line-height: 130%; /* 26px */
    letter-spacing: 0.02rem;
  }
  > div {
    border-radius: 8px;
    background: #f2f2f2;
    width: 100%;
    display: flex;
    align-items: center;
    height: 48px;
    padding: 16px 24px;
    > input {
      background-color: transparent;
      display: block;
      flex: 1;
      color: #8f8f8f;
      font-family: "Archivo";
      font-size: 1.6rem;
      font-style: normal;
      font-weight: 400;
      line-height: 170%;
      letter-spacing: 0.016rem;
      border: none;
      outline: none;
    }
  }
`;

const SubmibButton = styled.div`
  width: 100%;
  > button {
    width: 100%;
    padding: 0.4rem 0;
    color: #f7f7f7;
    text-align: center;
    font-family: "Archivo";
    font-size: 2rem;
    font-style: normal;
    font-weight: 400;
    line-height: 180%; /* 36px */
    letter-spacing: 0.02rem;
  }
`;

const EyeButton = styled.img`
  cursor: pointer;
`;

interface ModalProps {
  isOpen: boolean;
  setOpen: () => void;
}

function ModalChangePassword({ isOpen, setOpen }: ModalProps) {
  const { t } = useTranslation();
  const { logout } = useContext(AuthContext);
  const navigate = useNavigate();
  const [displayPassword, setDisplayPassword] = useState<{
    currentPassword: boolean;
    newPassword: boolean;
    confirmNewPassword: boolean;
  }>({
    currentPassword: false,
    newPassword: false,
    confirmNewPassword: false,
  });

  const [inputField, setInputField] = useState<{
    currentPassword: string;
    newPassword: string;
    confirmNewPassword: string;
  }>({
    currentPassword: "",
    newPassword: "",
    confirmNewPassword: "",
  });

  const handleSubmit = async () => {
    if (
      !inputField.currentPassword ||
      !inputField.newPassword ||
      !inputField.confirmNewPassword
    ) {
      toast.error(t("Please complete form!"));
    } else {
      if (inputField.newPassword.length < 8) {
        toast.error(t("Password must be at least 8 characters!"));
      } else if (inputField.newPassword !== inputField.confirmNewPassword) {
        toast.error(t("Confirm password do not correct!"));
      } else {
        const response: any = await UserApi.updatePassword({
          oldPassword: inputField.currentPassword,
          newPassword: inputField.newPassword,
          confirmNewPassword: inputField.confirmNewPassword,
        });
        if (response) {
          toast.success(t("Change password success!"));
          setOpen();
          navigate("/sign-in");
          logout();
        }
      }
    }
  };

  return (
    <Wrapper onClick={setOpen} className="modal">
      <Container onClick={(e) => e.stopPropagation()}>
        <h1>
          {t("Change Password")}
          <img src={X} alt="x" onClick={setOpen} />
        </h1>
        <form action="">
          <FormGroup>
            <label htmlFor="">{t("Current Password")}</label>
            <div>
              <input
                type={displayPassword.currentPassword ? "text" : "password"}
                required
                size={1}
                placeholder={t("Enter your password")}
                value={inputField.currentPassword}
                onChange={(e) => {
                  setInputField({
                    ...inputField,
                    currentPassword: e.target.value,
                  });
                }}
              />
              <EyeButton
                onClick={() => {
                  setDisplayPassword({
                    ...displayPassword,
                    currentPassword: !displayPassword.currentPassword,
                  });
                }}
                src={displayPassword.currentPassword ? EyeIcon : EyeSlashIcon}
              />
            </div>
          </FormGroup>
          <FormGroup>
            <label htmlFor="">{t("New Password")}</label>
            <div>
              <input
                type={displayPassword.newPassword ? "text" : "password"}
                required
                size={1}
                placeholder={t("Enter your password")}
                value={inputField.newPassword}
                onChange={(e) => {
                  setInputField({
                    ...inputField,
                    newPassword: e.target.value,
                  });
                }}
              />
              <EyeButton
                onClick={() => {
                  setDisplayPassword({
                    ...displayPassword,
                    newPassword: !displayPassword.newPassword,
                  });
                }}
                src={displayPassword.newPassword ? EyeIcon : EyeSlashIcon}
              />
            </div>
          </FormGroup>
          <FormGroup>
            <label htmlFor="">{t("Confirm New Password")}</label>
            <div>
              <input
                type={displayPassword.confirmNewPassword ? "text" : "password"}
                required
                size={1}
                placeholder={t("Enter your password")}
                value={inputField.confirmNewPassword}
                onChange={(e) => {
                  setInputField({
                    ...inputField,
                    confirmNewPassword: e.target.value,
                  });
                }}
              />
              <EyeButton
                onClick={() => {
                  setDisplayPassword({
                    ...displayPassword,
                    confirmNewPassword: !displayPassword.confirmNewPassword,
                  });
                }}
                src={
                  displayPassword.confirmNewPassword ? EyeIcon : EyeSlashIcon
                }
              />
            </div>
          </FormGroup>
        </form>
        <SubmibButton onClick={handleSubmit}>
          <ButtonGradient>{t("Save Change")}</ButtonGradient>
        </SubmibButton>
      </Container>
    </Wrapper>
  );
}

export default ModalChangePassword;
