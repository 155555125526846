import { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { breakPoints } from "../../constant/breakpoints";
import Icon01 from "./images/Icon01.svg";
import Icon02 from "./images/Icon02.svg";
import Icon03 from "./images/Icon03.svg";
import Icon04 from "./images/Icon04.svg";
import Icon05 from "./images/Icon05.svg";
import Icon06 from "./images/Icon06.svg";
import Icon07 from "./images/Icon07.svg";
import Icon08 from "./images/Icon08.svg";
import useChangeLanguage from "../../hooks/useChangeLanguage";
import useTheme from "../../hooks/use-theme";

const Main = styled.div`
  width: 100%;
  margin-top: 10rem;
`;

const Title = styled.h2<{ fontFamily?: string }>`
  text-align: center;
  color: #2d2d2d;
  font-family: ${({ fontFamily }) => fontFamily};
  font-size: 6.4rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.128rem;
  text-transform: uppercase;
  margin-bottom: 5rem;
  margin-top: 1.7rem;
  @media (max-width: ${breakPoints.laptop}) {
    font-size: 5.4rem;
  }
  @media (max-width: ${breakPoints.ipad}) {
    font-size: 4rem;
  }
  @media (max-width: ${breakPoints.mobile}) {
    font-size: 3.2rem;
  }
`;

const CheckIcon = styled.div`
  width: 1.6rem;
  height: 1.6rem;
  border-radius: 0.4rem;
  background: var(
    --gradient,
    linear-gradient(237deg, #1268e9 0%, #b43bf0 50.09%, #f64f59 100%)
  );
  @media (max-width: ${breakPoints.mobile}) {
  }
`;

const SubTitleWrap = styled.div`
  width: 100%;
  //margin-bottom: 2.4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1.2rem;
  @media (max-width: ${breakPoints.mobile}) {
  }
`;

const SubTitle = styled.span<{ fontFamily?: string }>`
  color: #2d2d2d;
  font-family: ${({ fontFamily }) => fontFamily};
  font-size: 2.4rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: uppercase;
  @media (max-width: ${breakPoints.mobile}) {
    font-size: 1.4rem;
  }
`;

const Container = styled.div`
  margin-top: 6rem;
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  align-items: center;
  gap: 6rem;
  @media (max-width: ${breakPoints.ipad}) {
    flex-direction: column;
  }
  @media (min-width: ${breakPoints.laptop}) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
`;

const ContentWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 5rem;
  align-items: stretch;
  @media (max-width: ${breakPoints.mobile}) {
    font-size: 1.6rem;
  }
  :hover {
    .line {
      /* border-color: #e74a7b; */
      border-color: #e94b79;
    }

    .text-number {
      background: linear-gradient(
        360deg,
        #1268e9 0%,
        #b43bf0 50.09%,
        #f64f59 100%
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 2.4rem;
  align-items: center;
  @media (min-width: ${breakPoints.laptop}) {
    flex-direction: column;
  }
  @media (min-width: ${breakPoints.desktop}) {
    flex-direction: row;
  }
`;

const LeftWrap = styled.div`
  position: relative;
  min-width: 18rem;
  padding: 0.5rem 0.7rem;
  display: flex;
  justify-content: space-between;
  gap: 0.8rem;
  align-items: center;
  @media (max-width: ${breakPoints.mobile}) {
    gap: 0.4rem;
    max-height: 6rem;
    padding: 0.4rem 0.6rem;
    min-width: 10rem;
  }
  @media (max-width: 320px) {
    min-width: 8rem;
  }
`;

const TextNumberWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 1rem;
  @media (max-width: ${breakPoints.mobile}) {
    margin-left: 0.5rem;
  }
`;

const PhoneImage = styled.img`
  padding: 2rem;
  position: relative;
  /* width: 100%;
  height: 100%; */
  height: 67.5rem;
  width: auto;
  /* transform: translateY(100%) scale(1); */
  animation: phone-coming ease 1000ms;
  animation-fill-mode: forwards;
  z-index: 9999;
  @keyframes phone-coming {
    0% {
      transform: scale(0.5);
    }
    100% {
      transform: scale(1);
    }
  }
`;

const Line = styled.div<{
  $left?: boolean;
  $right?: boolean;
  $top?: boolean;
  $bottom?: boolean;
}>`
  position: absolute;
  background: transparent;
  width: 1.8844rem;
  height: 1.8844rem;

  ${({ $left: left }) =>
    left && "left: 0;border-left: 0.1131rem solid #B7B7B7;"};
  ${({ $right: right }) =>
    right && "right: 0;border-right: 0.1131rem solid #B7B7B7;"};
  ${({ $top: top }) => top && "top: 0;border-top: 0.1131rem solid #B7B7B7;"};
  ${({ $bottom: bottom }) =>
    bottom && "bottom: 0;border-bottom: 0.1131rem solid #B7B7B7;"};
`;

const TextNumber = styled.span`
  color: #2d2d2d;
  text-align: center;
  font-family: Archivo;
  font-size: 3.2rem;
  font-style: normal;
  font-weight: 500;
  line-height: 9.045rem; /* 282.656% */
  @media (max-width: ${breakPoints.mobile}) {
    font-size: 1.8rem;
  }
`;

const Text = styled.span`
  color: #2d2d2d;
  font-family: Archivo;
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 500;
  line-height: 170%; /* 27.2px */
  letter-spacing: 0.016rem;
  @media (max-width: ${breakPoints.mobile}) {
    font-size: 1.2rem;
  }
`;

const ImageWrapCenter = styled.div`
  display: none;
  @media (min-width: ${breakPoints.laptop}) {
    /* display: block; */
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const IconWrap = styled.div`
  max-width: 8.2rem;
  width: 8.2rem;
  max-height: 8.2rem;
  height: 8.2rem;
  @media (max-width: ${breakPoints.mobile}) {
    max-width: 4.8rem;
    width: 4.8rem;
    max-height: 4.8rem;
    height: 4.8rem;
  }
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
`;

const OrderButton = styled.button`
  cursor: pointer;
  display: block;
  margin: 0 auto;
  padding: 1.4rem 3.2rem;
  border-radius: 100px;
  border: none;
  outline: none;
  background: var(
    --gradient,
    linear-gradient(237deg, #1268e9 0%, #b43bf0 50.09%, #f64f59 100%)
  );
  @media (max-width: ${breakPoints.mobile}) {
    padding: 0.8rem 2.4rem;
  }
`;

const OrderButtonText = styled.span`
  color: #fff;
  font-family: Archivo;
  font-size: 2.4rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  @media (max-width: ${breakPoints.mobile}) {
    font-size: 1.4rem;
  }
`;

interface IContent {
  textNumber: string;
  icon: string;
  text: string;
}

const FeaturesHome = ({ onClick }: { onClick: () => void }) => {
  const { t } = useTranslation();
  const { fontFamily } = useTheme();
  const constContent = [
    {
      textNumber: "01",
      icon: Icon01,
      text: `<p><b>${t("Face Unlock")}</b> ${t(
        "feature allows you to unlock your phone quickly and securely."
      )}</p>`,
    },
    {
      textNumber: "02",
      icon: Icon02,
      text: `<p><b>${t("Unlock fingerprint")}</b> ${t(
        "sensor works -reliably and pleasantly with fast recognition speed."
      )}</p>`,
    },
    {
      textNumber: "03",
      icon: Icon03,
      text: `<p><b>NFC</b> ${t(
        "enables contactless communication through device-to-device touch interaction."
      )}</p>`,
    },
    {
      textNumber: "04",
      icon: Icon04,
      text: `<p><b>${t("Classic 3.5mm headphone jack")}</b> ${t(
        "design enhances audio quality with wired headphones."
      )}</p>`,
    },
    {
      textNumber: "05",
      icon: Icon05,
      text: `<p>${t("Compatible with")} <b>${t(
        "GPS, GLONASS, BeiDou, and Galileo"
      )}</b> ${t("satellite system for enhanced location accuracy.")}</p>`,
    },
    {
      textNumber: "06",
      icon: Icon06,
      text: `<p><b>${t("Dual SIM (Nano-SIM)")}</b> ${t(
        "allows for dual stand-by capability, enhancing convenience."
      )}</p>`,
    },
    {
      textNumber: "07",
      icon: Icon07,
      text: `<p><b>${t("Integrated Infrared Port")}</b> ${t(
        "for versatile connectivity and data exchange capabilities."
      )}</p>`,
    },
    {
      textNumber: "08",
      icon: Icon08,
      text: `<p><b>${t(
        "Dynamic transparent back design with versatile LED lights"
      )}</b> ${t("that adapt to notifications, videos, and music.")}</p>`,
    },
  ];

  const [contents, setContents] = useState<IContent[]>(constContent);
  const [showPhone, setShowPhone] = useState<boolean>(false);
  const containerRef = useRef<HTMLDivElement>(null);

  useChangeLanguage(() => setContents(constContent));

  useEffect(() => {
    const handleScroll = () => {
      if (containerRef.current) {
        const rect = containerRef.current.getBoundingClientRect();
        if (rect.top <= 100) {
          setShowPhone(true);
        }
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [containerRef]);

  return (
    <Main ref={containerRef}>
      <SubTitleWrap>
        <CheckIcon />
        <SubTitle fontFamily={fontFamily}>{t("Features")}</SubTitle>
      </SubTitleWrap>
      <Title fontFamily={fontFamily}>{t("Why Choose Start24")}</Title>
      <OrderButton onClick={onClick}>
        <OrderButtonText>{t("Order Now")}</OrderButtonText>
      </OrderButton>
      <Container>
        <ContentWrap>
          {contents.slice(0, 4).map((item, index) => (
            <Row key={index}>
              <LeftWrap>
                <Line className="line" $left $top />
                <Line className="line" $right $top />
                <Line className="line" $right $bottom />
                <Line className="line" $left $bottom />
                <TextNumberWrap>
                  <TextNumber className="text-number">
                    {item.textNumber}
                  </TextNumber>
                </TextNumberWrap>
                <IconWrap>
                  <Image src={item.icon} alt={`Icon-${item.textNumber}`} />
                </IconWrap>
              </LeftWrap>
              <Text dangerouslySetInnerHTML={{ __html: item.text }} />
            </Row>
          ))}
        </ContentWrap>
        <ImageWrapCenter>
          {showPhone && (
            <PhoneImage
              src={"https://daziu008xh8wa.cloudfront.net/assets/front.png"}
              alt="Phone"
            />
          )}
        </ImageWrapCenter>
        <ContentWrap>
          {contents.slice(4, 8).map((item, index) => (
            <Row key={index}>
              <LeftWrap>
                <Line className="line" $left $top />
                <Line className="line" $right $top />
                <Line className="line" $right $bottom />
                <Line className="line" $left $bottom />
                <TextNumberWrap>
                  <TextNumber className="text-number">
                    {item.textNumber}
                  </TextNumber>
                </TextNumberWrap>
                <IconWrap>
                  <Image src={item.icon} alt={`Icon-${item.textNumber}`} />
                </IconWrap>
              </LeftWrap>
              <Text dangerouslySetInnerHTML={{ __html: item.text }} />
            </Row>
          ))}
        </ContentWrap>
      </Container>
    </Main>
  );
};

export default FeaturesHome;
