import styled from "styled-components";
import { breakPoints } from "../../constant/breakpoints";
import { useTranslation } from "react-i18next";

interface SocialItemProps {
  link: string;
  icon: string;
  text: string;
}

const Icon = styled.div`
  position: absolute;
  top: 0.8rem;
  left: 0.8rem;
  right: 60%;
  bottom: 40%;
  display: flex;
  /* padding: 0.4rem; */
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 0.6rem;
  background: #2d2d2d;
  transition: linear 300ms;
  aspect-ratio: 1/1;
  > img {
    width: 1.6rem;
    aspect-ratio: 1/1;
  }

  @media (min-width: ${breakPoints.ipad}) {
    > img {
      width: 2.4rem;
    }
  }

  @media (min-width: ${breakPoints.laptop}) {
    top: 2rem;
    left: 2rem;
    right: 50%;
    bottom: 50%;
    /* padding: 1.8rem; */
    border-radius: 1.6rem;
    > img {
      width: 3.2rem;
    }
  }

  @media (min-width: ${breakPoints.desktop}) {
    border-radius: 2.4rem;
    > img {
      width: 4rem;
    }
  }
`;

const Mask = styled.div`
  position: absolute;
  top: 2rem;
  left: 2rem;
  right: calc(100% - 2rem);
  bottom: calc(100% - 2rem);
  transition: linear 300ms;
  background: linear-gradient(
    237deg,
    #1268e9 -15.33%,
    #b43bf0 45.3%,
    #f64f59 105.7%
  );
  border-radius: 3rem;
  z-index: -1;
`;

const Text = styled.p`
  position: absolute;
  bottom: 0.8rem;
  left: 0.8rem;
  color: #2d2d2d;
  font-family: "Archivo";
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  transition: linear 300ms;

  @media (min-width: ${breakPoints.ipad}) {
    font-size: 1.4rem;
  }

  @media (min-width: ${breakPoints.laptop}) {
    bottom: 2rem;
    left: 2rem;
    font-size: 2rem;
  }
  @media (min-width: ${breakPoints.desktop}) {
    font-size: 2.4rem;
    line-height: 180%;
  }
`;

const Container = styled.a`
  max-width: 8.4rem;
  overflow: hidden;
  position: relative;
  text-decoration: none;
  color: inherit;
  padding: 0.8rem;
  width: 100%;
  aspect-ratio: 1/1;
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  border-radius: 1.2rem;
  border: 1px solid #2d2d2d;
  transition: linear 300ms;
  &:hover ${Icon} {
    padding: 1.6rem;
    justify-content: start;
    align-items: start;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(
      237deg,
      #1268e9 -15.33%,
      #b43bf0 45.3%,
      #f64f59 105.7%
    );
  }
  &:hover ${Mask} {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  &:hover ${Text} {
    color: white;
  }

  @media (min-width: ${breakPoints.ipad}) {
    max-width: 12.8rem;
  }

  @media (min-width: ${breakPoints.laptop}) {
    max-width: 25.6rem;
    padding: 2rem;
    border-radius: 3.6rem;
    &:hover ${Icon} {
      padding: 3.6rem;
    }
  }
`;

function SocialItem({ link, icon, text }: SocialItemProps) {
  const { t } = useTranslation();
  return (
    <Container href={link} target="_blank">
      <Icon>
        <img src={icon} alt="social-icon" />
      </Icon>
      <Text>{t(text)}</Text>
    </Container>
  );
}

export default SocialItem;
