import Footer from "./Footer";
import styled from "styled-components";
import "react-toastify/dist/ReactToastify.css";
import Header from "./Header";
import { Outlet, useLocation, useSearchParams } from "react-router-dom";
import { breakPoints } from "../../constant/breakpoints";
import StickyButton from "../ChatBotSticky";
import ChatBot from "../ChatBot";
import { useEffect } from "react";
import useCart from "../../hooks/use-cart";
import { PaymentMethod } from "../../pages/payment";
import InitialPopup from "./InitialPopup";
import TelegramStickyV2 from "../TelegramSticky/TelegramStickyV2";

const LayoutWrap = styled.div`
  position: relative;
  @media (max-width: ${breakPoints.mobile}) {
    padding: 0;
  }
  overflow-y: auto;
`;
export const MainLayoutContainer = styled.div`
  box-sizing: border-box;
  margin: 0 auto;
  max-width: 144rem;
  min-height: 80vh;
  width: 100%;
  padding: 0 2.4rem;
  @media (min-width: ${breakPoints.mobile}) {
    max-width: ${breakPoints.mobile};
  }
  @media (min-width: ${breakPoints.ipad}) {
    max-width: ${breakPoints.ipad};
  }
  @media (min-width: ${breakPoints.laptop}) {
    max-width: ${breakPoints.laptop};
  }
  @media (min-width: 1200px) {
    max-width: ${breakPoints.desktop};
  }
`;
export const OutLayoutContainer = styled.div`
  width: 100%;
  min-height: 100dvh;
`;
export const MainLayout = () => {
  return (
    <MainLayoutContainer>
      <Outlet />
    </MainLayoutContainer>
  );
};

export const OutLayout = () => {
  return (
    <OutLayoutContainer>
      <Outlet />
    </OutLayoutContainer>
  );
};

function Layout() {
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();
  const { changePaymentMethod } = useCart();

  const step = searchParams.get("step");
  useEffect(() => {
    if (!pathname.includes("/purchase/phone")) {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  }, [pathname]);

  useEffect(() => {
    if (!step || step !== "3") {
      changePaymentMethod(PaymentMethod.Paypal);
    }
  }, [step]);

  return (
    <LayoutWrap>
      <Header />
      <Outlet />
      <Footer />
      <TelegramStickyV2 />
      <ChatBot />
      <StickyButton />
      <InitialPopup />
    </LayoutWrap>
  );
}

export default Layout;
