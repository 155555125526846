import React, { useEffect } from "react";
import { styled } from "styled-components";
import useCart from "../../hooks/use-cart";
import WhitePhone from "../../icons/white.png";
import BlackPhone from "../../icons/black.png";
import { ProductCode } from "../../pages/payment/context/payment-context";
import PlusIcon2 from "../../pages/Purchase/icons/Plus";
import MinusIcon2 from "../../pages/Purchase/icons/Minus";
import { breakPoints } from "../../constant/breakpoints";
import { PaymentMethod } from "../../pages/payment";
import PoolsIcon from "../../pages/payment/icons/pools.svg";
import { useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

const CartItemsContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 2.4rem;
`;

const Item = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const ItemLeft = styled.div`
  display: flex;
  gap: 1.6rem;
`;
const ImageContainer = styled.div<{ $quantity: number }>`
  position: relative;
  span {
    position: absolute;
    top: -0.5rem;
    right: -0.5rem;
    width: ${({ $quantity: quantity }) =>
      quantity >= 100 ? "2.5rem" : "1.6rem"};
    height: ${({ $quantity: quantity }) =>
      quantity >= 100 ? "2.5rem" : "1.6rem"};
    padding: 0.6rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background: rgba(18, 18, 18, 0.7);

    color: var(--gray-scale-white, #fff);
    text-align: right;
    font-family: "Archivo";
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  /* > img {
    max-width: 90%;
  } */
`;
const ItemName = styled.span`
  max-width: 70%;
  align-self: flex-start;
  color: var(--gray-scale-black-80, #2d2d2d);
  font-family: "Archivo";
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 500;
  line-height: 130%; /* 2.08rem */
  letter-spacing: 0.08rem;
  @media (max-width: ${breakPoints.laptop}) {
    max-width: 100%;
    font-size: 1.2rem;
    letter-spacing: 0.07rem;
  }
`;
const Quantity = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  user-select: none !important;
  span {
    display: flex;
    width: 2.4rem;
    height: 2.4rem;
    padding: 0.45rem 0.4rem 0.35rem 0.4rem;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    border-radius: 0.4rem;
    background: #d9d9d9;
    cursor: pointer;
    transition: all 0.2s;
    user-select: none !important;
    :hover {
      filter: brightness(0.1);
    }
  }
  @media (max-width: ${breakPoints.mobile}) {
    flex-direction: row;
    justify-content: flex-start;
    gap: 1rem;
  }
`;
const ItemRight = styled.div`
  align-self: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  span:first-child {
    color: var(--gray-scale-black-80, #2d2d2d);
    text-align: right;
    font-family: "Archivo";
    font-size: 2rem;
    font-style: normal;
    font-weight: 400;
    line-height: 130%; /* 2.6rem */
    display: flex;
    align-items: center;
    > img {
      margin: 0 0.5rem;
      max-width: 2rem;
      max-height: 2rem;
    }
  }
  span:last-child {
    color: var(--gray-scale-black-40, #8f8f8f);
    text-align: right;
    font-family: "Archivo";
    font-size: 2rem;
    font-style: normal;
    font-weight: 400;
    line-height: 130%; /* 2.6rem */
    text-decoration: line-through;
  }
  @media (max-width: ${breakPoints.mobile}) {
    align-self: flex-start;
    span:first-child,
    span:last-child {
      font-size: 1.4rem;
    }
  }
`;

const NoItem = styled.p`
  text-align: center;
  margin: 3rem 0;
`;

const NameAndQuantity = styled.div`
  display: flex;
  gap: 1.6rem;
  @media (max-width: ${breakPoints.mobile}) {
    flex-direction: column-reverse;
    justify-content: flex-end;
  }
`;

const CartItems = () => {
  const { cart, paymentMethod, addItem, removeItem } = useCart();
  const [searchParams] = useSearchParams();
  const step = searchParams.get("step");
  const { t } = useTranslation();

  useEffect(() => {}, [paymentMethod]);

  return (
    <CartItemsContainer>
      {!cart ||
      !cart.items ||
      cart.items.length === 0 ||
      cart.totalPrice === 0 ? (
        <NoItem>No Items</NoItem>
      ) : (
        <>
          {cart.items.map((item, index) => (
            <Item key={index}>
              <ItemLeft>
                <ImageContainer $quantity={item.quantity}>
                  <img
                    src={
                      item.product?.code === ProductCode.White
                        ? WhitePhone
                        : BlackPhone
                    }
                    alt={""}
                  />
                  <span>{item.quantity}</span>
                </ImageContainer>
                <NameAndQuantity>
                  {step !== "2" && step !== "3" && (
                    <Quantity>
                      <span
                        onClick={() => {
                          if (item.quantity >= 10) {
                            toast.error(
                              t("You have reached the order limit (maximum 10)")
                            );
                            return;
                          }
                          addItem({ product: item.product, quantity: 1 });
                        }}
                      >
                        <PlusIcon2 />
                      </span>
                      <span onClick={() => removeItem(item.product)}>
                        <MinusIcon2 />
                      </span>
                    </Quantity>
                  )}
                  <ItemName>
                    START24 PHONE / RAM 12GB / 256GB /{" "}
                    {item.product.code === ProductCode.White
                      ? "WHITE"
                      : "BLACK"}
                  </ItemName>
                </NameAndQuantity>
              </ItemLeft>

              <ItemRight>
                <span>
                  {paymentMethod === PaymentMethod.Pools ? (
                    <img src={PoolsIcon} alt="" />
                  ) : (
                    "$"
                  )}
                  {item.product.sale_price !== item.product.origin_price
                    ? item.product.sale_price
                    : item.product.origin_price}
                </span>
                <span>
                  {item.product.sale_price !== item.product.origin_price
                    ? `$${item.product.origin_price}`
                    : undefined}
                </span>
              </ItemRight>
            </Item>
          ))}
        </>
      )}
    </CartItemsContainer>
  );
};

export default CartItems;
