import React, { useState } from "react";
import { styled } from "styled-components";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";

import useTheme from "../../../../hooks/use-theme";
import { useTranslation } from "react-i18next";
import { Content, TextColumn, Title, Image } from "./SpecificationDesktop";
import { ImagesUrl } from "../../../../constant";

// import SpecFirstFirst from "../image/spec_first_first.png";
// import SpecFirstSecond from "../image/spec_first_second.png";
// import SpecSecondSecond from "../image/spec_second_second.png";
// import SpecThirdFirst from "../image/spec_third_first.png";
// import SpecThirdThird from "../image/spec_third_third.png";
// import SpecFourthSecond from "../image/spec_fourth_second.png";
// import SpecFifthFirst from "../image/spec_fifth_first.png";
// import SpecFifthSecond from "../image/spec_fifth_second.png";

const SpecificationMobile = () => {
  const { fontFamily } = useTheme();
  const { t } = useTranslation();
  const [firstSectionSliderChange, setFirstSectionSliderChange] = useState(0);
  const [thirdSectionSliderChange, setThirdSectionSliderChange] = useState(0);

  return (
    <MobileContainer>
      <FirstSection>
        <MobileTextColumn>
          <Title fontFamily={fontFamily}>
            {t("Geometric LED Notifications")}
          </Title>
          <Content>
            {t(
              "The geometric design serves as an illustration of lighting innovation for smartphones. You can customize the color of the color of the light pattern in response to incoming calls, new notifications, and more events. Let Start24 shine with your rhythm."
            )}
          </Content>
        </MobileTextColumn>
        <SwiperContainer>
          <Swiper
            style={{ width: "100%" }}
            centeredSlides
            onSlideChange={(e) => {
              setFirstSectionSliderChange(e.activeIndex);
            }}
          >
            <SwiperSlide style={{ display: "flex", justifyContent: "center" }}>
              <div>
                <Image src={ImagesUrl.Spec_First_First} alt="" />
              </div>
            </SwiperSlide>
            <SwiperSlide style={{ display: "flex", justifyContent: "center" }}>
              <div>
                <Image src={ImagesUrl.Spec_First_Second} alt="" />
              </div>
            </SwiperSlide>
          </Swiper>
          <SliderDotContainer>
            <SliderDot active={firstSectionSliderChange === 0} />
            <SliderDot active={firstSectionSliderChange === 1} />
          </SliderDotContainer>
        </SwiperContainer>
      </FirstSection>

      <SecondSection>
        <MobileTextColumn>
          <Title fontFamily={fontFamily}>
            {t("Geometric LED Notifications")}
          </Title>
          <Content>
            {t(
              "The geometric design serves as an illustration of lighting innovation for smartphones. You can customize the color of the color of the light pattern in response to incoming calls, new notifications, and more events. Let Start24 shine with your rhythm."
            )}
          </Content>
        </MobileTextColumn>
        <div>
          <Image src={ImagesUrl.Spec_Second_Second} alt="" />
        </div>
      </SecondSection>

      <ThirdSection>
        <MobileTextColumn>
          <Title fontFamily={fontFamily}>
            {t("108-Megapixel Triple-Lens Camera for MEGA Moment")}
          </Title>
          <Content>
            {t(
              "With its equipped camera of three diverse lenses. You may snap ultra-high-resolution photos. Capture your MEGA moment with Start24's stunning triple-lens camera."
            )}
          </Content>
        </MobileTextColumn>
        <SwiperContainer>
          <Swiper
            style={{ width: "100%" }}
            centeredSlides
            onSlideChange={(e) => {
              setThirdSectionSliderChange(e.activeIndex);
            }}
          >
            <SwiperSlide style={{ display: "flex", justifyContent: "center" }}>
              <div>
                <Image src={ImagesUrl.Spec_Third_First} alt="" />
              </div>
            </SwiperSlide>
            <SwiperSlide style={{ display: "flex", justifyContent: "center" }}>
              <div>
                <Image src={ImagesUrl.Spec_Third_Third} alt="" />
              </div>
            </SwiperSlide>
          </Swiper>
          <SliderDotContainer>
            <SliderDot active={thirdSectionSliderChange === 0} />
            <SliderDot active={thirdSectionSliderChange === 1} />
          </SliderDotContainer>
        </SwiperContainer>
      </ThirdSection>

      <FourthSection>
        <MobileTextColumn>
          <Title fontFamily={fontFamily}>
            {t("12GB RAM and 256GB ROM Storage")}
          </Title>
          <Content>
            {t(
              "With 12GB RAM and massive ROM storage of 256GB, Start24 provides a noticeably improved user experience. The large RAM enables smoother multitasking, allowing for quick lag-free switching between various background applications."
            )}
          </Content>
        </MobileTextColumn>
        <div>
          <Image src={ImagesUrl.Spec_Fourth_Second} alt="" />
        </div>
      </FourthSection>

      <FifthSection>
        <MobileTextColumn2>
          <Title fontFamily={fontFamily}>
            {t("Android 13 Operation System")}
          </Title>
          <Content>
            {t(
              "START24 is powered by Android 13 and provides secure and convenient data security using fingerprint and facial identification, ensuring a personalized, safe, and hassle-free user experience."
            )}
          </Content>
        </MobileTextColumn2>
      </FifthSection>

      <SixthSection>
        <MobileTextColumn>
          <Title fontFamily={fontFamily}>
            {t("5000mAh All-Day Life Battery")}
          </Title>
          <Content>
            {t(
              "A 5000mAh battery powered by an energy-efficient processor ensures all-day usage on a single charge. You can expect to go all day between charges with Start24."
            )}
          </Content>
        </MobileTextColumn>
        <div>
          <Image src={ImagesUrl.Spec_Fifth_First} alt="" />
        </div>
      </SixthSection>

      <SeventhSection>
        <MobileTextColumn>
          <Title fontFamily={fontFamily}>
            {t("6.81 Inches Full-Screen Display")}
          </Title>
          <Content>
            {t(
              "A larger full-screen display of 6.81 inches with a resolution of 2400 x 1080 pixels enhances the phone's overall viewing and multimedia effect, providing a more immersive visual experience while gaming, browsing, watching videos, and using apps."
            )}
          </Content>
        </MobileTextColumn>
        <div>
          <Image src={ImagesUrl.Spec_Fifth_Second} alt="" />
        </div>
      </SeventhSection>
    </MobileContainer>
  );
};

export default SpecificationMobile;

const FlexColumn = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
  gap: 1.6rem;
`;

const MobileContainer = styled(FlexColumn)`
  /* position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0%); */
  width: 90vw;
  gap: 4rem;
  margin-top: 4rem;
`;

const FirstSection = styled(FlexColumn)``;
const SecondSection = styled(FlexColumn)``;
const ThirdSection = styled(FlexColumn)``;
const FourthSection = styled(FlexColumn)``;
const FifthSection = styled(FlexColumn)``;
const SixthSection = styled(FlexColumn)``;
const SeventhSection = styled(FlexColumn)``;

const MobileTextColumn = styled(TextColumn)`
  padding: 0 1.6rem;
  gap: 0.4rem;
`;
const MobileTextColumn2 = styled(TextColumn)`
  gap: 0.4rem;
  border-radius: 2.4rem;
  background: rgba(74, 74, 74, 0.1);
  padding: 4rem 2.4rem;
`;
const SwiperContainer = styled.div`
  width: 100%;
  position: relative;
`;

const SliderDotContainer = styled.div`
  position: absolute;
  bottom: 1rem;
  left: 50%;
  display: flex;
  gap: 0.6rem;
`;

const SliderDot = styled.div<{ active?: boolean }>`
  width: 0.8rem;
  height: 0.8rem;
  border-radius: 4rem;
  transition: all 0.2s;
  background: ${(props) => (props.active ? "#B43BF0" : "#D1D1D1")};
`;
