import styled from "styled-components";
import UserIcon from "./layout/icons/UserIcon";
import RightIcon from "./layout/icons/RightIcon";
import EditIcon from "./layout/icons/EditIcon";
import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import AuthContext from "../context/auth-context";
import { breakPoints } from "../constant/breakpoints";
import ModalChangeName from "./modal/ModalChangeName";
import ModalChangePassword from "./modal/ModalChangePassword";
import ModalChangeLanguage from "./modal/ModalChangeLanguage";
import { toast } from "react-toastify";
import UserApi from "../api/user";
import { useTranslation } from "react-i18next";

const Container = styled.div<{
  $darkMode: boolean;
  $display: boolean;
}>`
  position: absolute;
  right: 0;
  margin-top: 2.4rem;
  width: 100vw;
  border-radius: 2.4rem;
  border: 1px solid ${(props) => (props.$darkMode ? "transparent" : "#ccc")};
  background-color: ${(props) => (props.$darkMode ? "#121212" : "#fff")};
  overflow: hidden;
  color: ${(props) => (props.$darkMode ? "#fff" : "#121212")};
  cursor: default;
  transform-origin: top center;
  user-select: none;
  transform: ${(props) => (props.$display ? "scaleY(1)" : "scaleY(0)")};
  transition: ease-in-out 150ms;

  @keyframes growDown {
    0% {
      transform: scaleY(0);
    }
    80% {
      transform: scaleY(1.1);
    }
    100% {
      transform: scaleY(1);
    }
  }

  @keyframes growUp {
    0% {
      transform: scaleY(1);
    }
    80% {
      transform: scaleY(1.1);
    }
    100% {
      transform: scaleY(0);
    }
  }

  @media (min-width: ${breakPoints.mobile}) {
    width: 33.6rem;
    right: 0;
  }
`;

const Header = styled.div`
  height: 6.8rem;
  background: var(
    --gradient,
    linear-gradient(237deg, #1268e9 0%, #b43bf0 50.09%, #f64f59 100%)
  );
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const ProfileContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 4rem;
  > h3 {
    font-family: Archivo;
    font-size: 1.6rem;
    font-style: normal;
    font-weight: 400;
    line-height: 170%;
    letter-spacing: 0.016rem;
  }
  > p {
    color: #8f8f8f;
    font-family: Archivo;
    font-size: 1.4rem;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    letter-spacing: 0.014rem;
  }
`;

const UpdateAvatar = styled.label<{ $isDisplay: boolean }>`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: ${(props) => (props.$isDisplay ? 1 : 0)};
  transition: linear 150ms;
  cursor: ${(props) => (props.$isDisplay ? "progress" : "pointer")};
`;

const Avatar = styled.div`
  width: 7rem;
  height: 7rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  background: #bdbdbd;
  transform: translateY(50%);
  position: relative;
  overflow: hidden;
  > img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
  &:hover ${UpdateAvatar} {
    opacity: 1;
  }
`;

const MenuContent = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 1.6rem;
  padding: 2.4rem 1.6rem;
`;

const MenuContentSub = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 2.4rem;
  row-gap: 1.6rem;
  animation: growDown 300ms ease-in-out forwards;
  transform-origin: top center;
  @keyframes growDown {
    0% {
      transform: scaleY(0);
    }
    80% {
      transform: scaleY(1.1);
    }
    100% {
      transform: scaleY(1);
    }
  }
`;

const Option = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  > p {
    font-family: "Archivo";
    font-size: 1.4rem;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    letter-spacing: 0.014rem;
  }
`;

const OptionSub = styled.div<{ $darkMode: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 0.8rem;
  > div {
    flex: 1;
    display: flex;
    align-items: center;
    color: ${(props) => (props.$darkMode ? "#fff" : "#121212")};
    font-family: "Archivo";
    font-size: 1.4rem;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    letter-spacing: 0.014rem;
    > input {
      flex: 1;
      margin-left: 0.4rem;
      background-color: transparent;
      border: 0.1rem solid transparent;
      padding: 0.4rem 0.8rem;
      outline: none;
      color: #8f8f8f;
      border-radius: 0.6rem;
      &:focus {
        border-color: #8f8f8f;
      }
    }
  }
  > svg {
    cursor: pointer;
  }
`;

const LogoutButton = styled.div<{ $darkMode: boolean }>`
  display: flex;
  padding: 6px 0px;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  border-radius: 6px;
  background-color: ${(props) => (props.$darkMode ? "#2d2d2d" : "#f7f7f7")};
  cursor: pointer;
  transition: linear 150ms;
  &:hover {
    background-color: ${(props) => (props.$darkMode ? "#3d3d3d" : "#c7c7c7")};
    > p {
      color: #b13434;
    }
  }
  > p {
    font-family: Archivo;
    font-size: 1.4rem;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    letter-spacing: 0.014rem;
  }
`;
const EditIconContainer = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

function UserDropdown({
  display,
  setDisplayDropdown,
}: {
  display: boolean;
  setDisplayDropdown: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const { t } = useTranslation();
  const { user, logout, setUser } = useContext(AuthContext);
  const navigate = useNavigate();
  const [displayEdit, setDisplayEdit] = useState<boolean>(false);
  const [displayLang, setDisplayLang] = useState<boolean>(false);
  const [isChangeAvt, setIsChangeAvt] = useState<boolean>(false);
  const [displayModalChangPassword, setDisplayModalChangePassword] =
    useState<boolean>(false);
  const [displayModalChangeName, setDisplayModalChangeName] =
    useState<boolean>(false);
  const [displayModalChangeLanguage, setDisplayModalChangeLanguage] =
    useState<boolean>(false);

  const handleLogout = async () => {
    logout();
    toast.success(t("Logout successfully"));
  };

  const handleChangeAvatar = async (image: File) => {
    setIsChangeAvt(true);
    if (image) {
      const response = await UserApi.updateAvatar(image);
      if (response) {
        localStorage.setItem("user", response);
        setUser(response);
        toast.success(t("Update avatar successfully!"));
      }
    }
    setIsChangeAvt(false);
  };

  const darkMode = true;
  const [selectedOption, setSelectedOption] = useState(null);
  return (
    <>
      <Container $display={display} $darkMode={darkMode}>
        <Header>
          <Avatar>
            {user?.avatar ? (
              <img src={user.avatar} alt="avatar" />
            ) : (
              <UserIcon isDark={true} />
            )}
            <UpdateAvatar $isDisplay={isChangeAvt} htmlFor="avatar_upload">
              {/* <EditIconContainer></EditIconContainer> */}
              {isChangeAvt ? "..." : <EditIcon $darkMode={darkMode} />}
            </UpdateAvatar>
            <input
              disabled={isChangeAvt}
              id="avatar_upload"
              type="file"
              hidden
              accept="image/png, image/gif, image/jpeg"
              onChange={(e) => {
                if (!e.target.files) return;
                handleChangeAvatar(e.target.files[0]);
              }}
            />
          </Avatar>
        </Header>
        <ProfileContent>
          <h3>{user?.fullname || user?.username}</h3>
          <p>{user?.email}</p>
        </ProfileContent>
        <MenuContent>
          <Option
            onClick={() => {
              setDisplayEdit(!displayEdit);
            }}
          >
            <p>{t("Profile Setting")}</p>
            <RightIcon $darkMode={darkMode} isDown={displayEdit} />
          </Option>
          {displayEdit && (
            <MenuContentSub>
              <OptionSub $darkMode={true}>
                <div>
                  <label htmlFor="#email">Email:</label>
                  <input disabled type="text" size={1} value={user?.email} />
                </div>
              </OptionSub>
              <OptionSub $darkMode={true}>
                <div>
                  <label htmlFor="#email">{t("Full name")}:</label>
                  <input
                    disabled
                    type="text"
                    size={1}
                    value={user?.fullname || user?.username}
                  />
                </div>
                <EditIconContainer
                  onClick={() => setDisplayModalChangeName(true)}
                >
                  <EditIcon $darkMode={darkMode} />
                </EditIconContainer>
              </OptionSub>
              {user?.provider !== "google" && (
                <OptionSub $darkMode={true}>
                  <div>
                    <label htmlFor="#email">{t("Password")}:</label>
                    <input
                      disabled
                      type="password"
                      size={1}
                      value="************"
                    />
                  </div>
                  <EditIconContainer
                    onClick={() => setDisplayModalChangePassword(true)}
                  >
                    <EditIcon $darkMode={darkMode} />
                  </EditIconContainer>
                </OptionSub>
              )}
            </MenuContentSub>
          )}
          {/* <Option onClick={() => setDisplayLang(!displayLang)}>
            <p>Language</p>
            
          </Option> */}

          <Option
            onClick={() => {
              setDisplayDropdown(false);
              navigate("/order-tracking");
            }}
          >
            <p>{t("Order Tracking")}</p>
            <RightIcon $darkMode={darkMode} />
          </Option>
          <Option
            onClick={() => {
              window.open("https://agent.startmobis.com/");
            }}
          >
            <p>Start24 Agent</p>
            <RightIcon $darkMode={darkMode} />
          </Option>
          {/* <Option
            onClick={() => {
              setDisplayModalChangeLanguage(!displayModalChangeLanguage);
            }}
          >
            <p>Languages</p>
            <RightIcon darkMode={darkMode} />
          </Option> */}
          <LogoutButton $darkMode={darkMode} onClick={handleLogout}>
            <p>{t("Logout")}</p>
          </LogoutButton>
        </MenuContent>
      </Container>
      {displayModalChangPassword && (
        <ModalChangePassword
          isOpen={displayModalChangPassword}
          setOpen={() => {
            setDisplayModalChangePassword(!displayModalChangPassword);
          }}
        />
      )}
      {displayModalChangeName && (
        <ModalChangeName
          name={user?.fullname}
          isOpen={displayModalChangeName}
          setOpen={() => {
            setDisplayModalChangeName(!displayModalChangeName);
          }}
        />
      )}
      {displayModalChangeLanguage && (
        <ModalChangeLanguage
          isOpen={displayModalChangeLanguage}
          setOpen={() => {
            setDisplayModalChangeLanguage(!displayModalChangeLanguage);
          }}
        />
      )}
    </>
  );
}

export default UserDropdown;
