function ClearIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
    >
      <path
        d="M15.7512 5.25828C15.6741 5.18102 15.5825 5.11973 15.4817 5.07792C15.3809 5.0361 15.2728 5.01457 15.1637 5.01457C15.0545 5.01457 14.9465 5.0361 14.8456 5.07792C14.7448 5.11973 14.6533 5.18102 14.5762 5.25828L10.5012 9.32494L6.42616 5.24994C6.34901 5.17279 6.25742 5.11159 6.15662 5.06984C6.05581 5.02808 5.94777 5.00659 5.83866 5.00659C5.72955 5.00659 5.62151 5.02808 5.52071 5.06984C5.41991 5.11159 5.32831 5.17279 5.25116 5.24994C5.17401 5.32709 5.11281 5.41869 5.07106 5.51949C5.0293 5.62029 5.00781 5.72833 5.00781 5.83744C5.00781 5.94655 5.0293 6.05459 5.07106 6.1554C5.11281 6.2562 5.17401 6.34779 5.25116 6.42494L9.32616 10.4999L5.25116 14.5749C5.17401 14.6521 5.11281 14.7437 5.07106 14.8445C5.0293 14.9453 5.00781 15.0533 5.00781 15.1624C5.00781 15.2716 5.0293 15.3796 5.07106 15.4804C5.11281 15.5812 5.17401 15.6728 5.25116 15.7499C5.32831 15.8271 5.41991 15.8883 5.52071 15.93C5.62151 15.9718 5.72955 15.9933 5.83866 15.9933C5.94777 15.9933 6.05581 15.9718 6.15662 15.93C6.25742 15.8883 6.34901 15.8271 6.42616 15.7499L10.5012 11.6749L14.5762 15.7499C14.6533 15.8271 14.7449 15.8883 14.8457 15.93C14.9465 15.9718 15.0546 15.9933 15.1637 15.9933C15.2728 15.9933 15.3808 15.9718 15.4816 15.93C15.5824 15.8883 15.674 15.8271 15.7512 15.7499C15.8283 15.6728 15.8895 15.5812 15.9313 15.4804C15.973 15.3796 15.9945 15.2716 15.9945 15.1624C15.9945 15.0533 15.973 14.9453 15.9313 14.8445C15.8895 14.7437 15.8283 14.6521 15.7512 14.5749L11.6762 10.4999L15.7512 6.42494C16.0678 6.10828 16.0678 5.57494 15.7512 5.25828Z"
        fill="black"
      />
    </svg>
  );
}

export default ClearIcon;
