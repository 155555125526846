import i18next, { t } from "i18next";
import styled from "styled-components";
import RightIcon from "../layout/icons/RightIcon";
import { useState, useContext } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router";
import AuthContext from "../../context/auth-context";
import { breakPoints } from "../../constant/breakpoints";
import JapanIcon from "../../assets/icons/languages/JapanIcon.svg";
import ChinaIcon from "../../assets/icons/languages/ChinaIcon.svg";
import KoreaIcon from "../../assets/icons/languages/KoreaIcon.svg";
import SpainIcon from "../../assets/icons/languages/SpainIcon.svg";
import PortugalIcon from "../../assets/icons/languages/PortugalIcon.svg";
import VietnameseIcon from "../../assets/icons/languages/VietnameseIcon.svg";
import EnglishIcon from "../../assets/icons/languages/EnglishIcon.svg";
import X from "../../assets/icons/x.svg";
const Wrapper = styled.div`
  cursor: default;
  position: fixed !important;
  padding: 0 0.8rem;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 99999999999;
  background-color: rgba(0, 0, 0, 0.7);
  overflow: hidden;
  scroll-behavior: unset;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Container = styled.div`
  border-radius: 2.4rem;
  background: #fff;
  box-shadow: 1px 1px 10px 0px rgba(0, 0, 0, 0.1);
  display: flex;
  width: 100%;
  margin: 0 0.8rem;
  padding: 2rem;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  > h1 {
    width: 100%;
    color: #2d2d2d;
    text-align: center;
    font-family: "Archivo";
    font-size: 2.8rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    position: relative;
    > img {
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      display: none;
      cursor: pointer;
    }
  }
  > form {
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 2.4rem;
  }
  @media (min-width: ${breakPoints.mobile}) {
    > h1 > img {
      display: block;
    }
  }
  @media (min-width: ${breakPoints.ipad}) {
    gap: 3.2rem;
    width: 60rem;
    padding: 3.6rem;
  }
`;

const SelectWrap = styled.div``;

const SelectContentWrap = styled.div`
  min-width: 8rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const SelectLanguage = styled.div<{ $darkMode: boolean }>`
  cursor: pointer;
  border-radius: 1rem;
  border: 0.1rem solid gray;
  padding: 1rem 2.4rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.6rem;
`;

const SelectedLanguage = styled.span<{ $darkMode: boolean }>`
  cursor: pointer;
  color: ${(props) => (props.$darkMode ? "#fff" : "#121212")};
  font-family: "Archivo";
  font-size: 1.8rem;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.014rem;
`;

const MenuContentSub = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 2.4rem;
  row-gap: 1.6rem;
  animation: growDown 300ms ease-in-out forwards;
  transform-origin: top center;
  @keyframes growDown {
    0% {
      transform: scaleY(0);
    }
    80% {
      transform: scaleY(1.1);
    }
    100% {
      transform: scaleY(1);
    }
  }
`;

const OptionSub = styled.div<{ $darkMode: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 0.8rem;
  cursor: pointer;

  > div {
    cursor: pointer;
    flex: 1;
    display: flex;
    align-items: center;
    color: ${(props) => (props.$darkMode ? "#fff" : "#121212")};
    font-family: "Archivo";
    font-size: 1.4rem;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    letter-spacing: 0.014rem;
    :hover {
      background: linear-gradient(
        360deg,
        #1268e9 0%,
        #b43bf0 50.09%,
        #f64f59 100%
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
    > span {
      cursor: pointer;
    }
    > input {
      flex: 1;
      margin-left: 0.4rem;
      background-color: transparent;
      border: 0.1rem solid transparent;
      padding: 0.4rem 0.8rem;
      outline: none;
      color: #8f8f8f;
      border-radius: 0.6rem;
      &:focus {
        border-color: #8f8f8f;
      }
    }
  }
  > svg {
    cursor: pointer;
  }
  > img {
    cursor: pointer;
  }
`;

const FlagCountry = styled.div`
  margin-right: 2rem;
  max-width: 2.5rem;
  display: flex;
  align-items: center;
  > img {
    cursor: pointer;
    width: 100%;
    height: 100%;
  }
`;

interface ModalProps {
  isOpen: boolean;
  setOpen: () => void;
}

interface Language {
  name: string;
  icon: string;
  key: string;
}

export enum LANGUAGES_CODE {
  Vietnamese = "vi",
  Korea = "ko",
  English = "en",
  Japan = "jp",
  China = "cn",
  Spanish = "es",
  Portugese = "pt",
}

const language: Language[] = [
  {
    name: t("Japan"),
    icon: JapanIcon,
    key: LANGUAGES_CODE.Japan,
  },
  {
    name: t("China"),
    icon: ChinaIcon,
    key: LANGUAGES_CODE.China,
  },
  {
    name: t("Korea"),
    icon: KoreaIcon,
    key: LANGUAGES_CODE.Korea,
  },
  {
    name: t("Spanish"),
    icon: SpainIcon,
    key: LANGUAGES_CODE.Spanish,
  },
  {
    name: t("Portugese"),
    icon: PortugalIcon,
    key: LANGUAGES_CODE.Portugese,
  },
  {
    name: t("Vietnamese"),
    icon: VietnameseIcon,
    key: LANGUAGES_CODE.Vietnamese,
  },
  {
    name: t("English"),
    icon: EnglishIcon,
    key: LANGUAGES_CODE.English,
  },
];

function ModalChangeLanguage({ isOpen, setOpen }: ModalProps) {
  const { logout } = useContext(AuthContext);
  const navigate = useNavigate();
  const [displayOptions, setDisplayOptions] = useState<boolean>(false);
  const [selectedLanguage, setSelectedLanguage] = useState<Language>(
    language.find((lang) => lang.key === "en") || language[0]
  );

  const handleChangeLanguage = (lang: Language) => {
    if (!lang || !lang.key) return;
    // const element = document.getElementsByClassName(
    //   "list-language"
    // ) as HTMLCollectionOf<HTMLElement>;

    // element[0].style.opacity = "0";
    // element[0].style.visibility = "hidden";
    // element[0].style.pointerEvents = "none";

    i18next
      .changeLanguage(lang.key)
      .then((t) => {
        t("key");
        console.log("change language success", t);
        setSelectedLanguage(lang);
        setDisplayOptions(!displayOptions);
      })
      .catch((err) => {
        console.log("something went wrong change language", err);
      });
  };

  return (
    <Wrapper onClick={setOpen} className="modal">
      <Container onClick={(e) => e.stopPropagation()}>
        <h1>
          {t("Change Language")}
          <img src={X} alt="x" onClick={setOpen} />
        </h1>
        <SelectWrap>
          <SelectLanguage
            onClick={() => setDisplayOptions(!displayOptions)}
            $darkMode={false}
          >
            <FlagCountry>
              <img src={selectedLanguage.icon} alt="flag" />
            </FlagCountry>
            <SelectedLanguage $darkMode={false}>
              {selectedLanguage.name}
            </SelectedLanguage>
            <RightIcon $darkMode={false} isDown={displayOptions} />
          </SelectLanguage>
          {displayOptions && (
            <MenuContentSub>
              {language.map((lang) => (
                <OptionSub
                  onClick={() => handleChangeLanguage(lang)}
                  $darkMode={false}
                >
                  <div>
                    <FlagCountry>
                      <img src={lang.icon} alt="flag" />
                    </FlagCountry>
                    <span>{lang.name}</span>
                  </div>
                </OptionSub>
              ))}
            </MenuContentSub>
          )}
        </SelectWrap>
      </Container>
    </Wrapper>
  );
}

export default ModalChangeLanguage;
