import styled from "styled-components";
import DownIcon from "../icons/down.svg";
import { breakPoints } from "../../../constant/breakpoints";
import { useTranslation } from "react-i18next";

const Container = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 8rem;
  z-index: 0;
  display: flex;
  justify-content: center;

  @media (min-width: ${breakPoints.mobile}) {
    top: 10rem;
  }
  @media (min-width: ${breakPoints.ipad}) {
    top: 12rem;
  }
  @media (min-width: ${breakPoints.laptop}) {
    top: 14rem;
  }
  @media (min-width: ${breakPoints.desktop}) {
    top: 20rem;
  }
`;

const Content = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  background: rgba(255, 255, 255, 0.2);
  padding: 0.8rem 1.6rem;
  border-radius: 5rem;
  animation: button-opacity ease 2s, bounce 1s infinite;
  animation-delay: 10s;
  opacity: 0;
  animation-fill-mode: forwards;
  > p {
    user-select: none;
    cursor: pointer;
    color: #fff;
    font-family: "Archivo";
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 14px */
    letter-spacing: 0.01rem;
  }
  > img {
    max-width: 100%;
    width: 1.4rem;
    aspect-ratio: 1/1;
    > svg {
      width: 1.4rem;
      aspect-ratio: 1/1;
    }
  }

  @media (min-width: ${breakPoints.ipad}) {
    column-gap: 0.4rem;
    > p {
      font-size: 1.4rem;
    }
  }
  @media (min-width: ${breakPoints.laptop}) {
    column-gap: 0.8rem;
    > p {
      font-size: 1.6rem;
      font-style: normal;
      font-weight: 400;
      line-height: 170%;
      letter-spacing: 0.016rem;
    }
    > img {
      transform: scale(1.5);
    }
  }

  @keyframes button-opacity {
    0% {
      opacity: 0;
    }
    30% {
      opacity: 0.9;
    }
    50% {
      opacity: 0.5;
    }
    70% {
      opacity: 0.25;
    }
    70% {
      opacity: 0.75;
    }
    70% {
      opacity: 0.15;
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes bounce {
    0%,
    100% {
      transform: translateY(-25%);
      animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
    }
    50% {
      transform: translateY(0);
      animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
    }
  }
`;

function ScrollToBottomButton({
  onScrollToIntroduce,
}: {
  onScrollToIntroduce: () => void;
}) {
  const { t } = useTranslation();
  return (
    <Container>
      <Content onClick={onScrollToIntroduce}>
        <p>{t("Scroll to explore")}</p>
        <img src={DownIcon} alt="down" />
      </Content>
    </Container>
  );
}

export default ScrollToBottomButton;
