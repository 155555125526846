import React, { useState } from "react";
import styled from "styled-components";
import CopyIcon from "../../icons/CopyIcon";
import { useSearchParams } from "react-router-dom";
import useTrackingOrder from "../../../../hooks/use-tracking-order";
import Spin from "../../../../components/Spin";
import { breakPoints } from "../../../../constant/breakpoints";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import {
  formattedDateTimeBefore,
  formattedDateWithoutTime,
} from "../../../../utils/utils";

export interface ITracking {
  time: string;
  status: string;
}
interface ITooltip {
  visible: boolean;
  content: string;
}
const Tracking = () => {
  const [searchParams] = useSearchParams();
  const { t } = useTranslation();
  const orders_code = searchParams.get("orders_code");
  const orderId = searchParams.get("orderId");

  const [showMore, setShowMore] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);

  const { data: trackingData, loading } = useTrackingOrder({
    orderId: orderId as string,
  });

  if (loading) {
    return (
      <Loading>
        <Spin />
      </Loading>
    );
  }

  if (
    !orderId ||
    !trackingData ||
    (trackingData && trackingData.length === 0)
  ) {
    return null;
  }

  const onClickOrdersCode = () => {
    navigator.clipboard.writeText(orders_code as string);
    setShowTooltip(true);
    onMouseLeave();
    setTimeout(() => {
      setShowTooltip(false);
    }, 2000);
  };
  const onHover = (e: any) => {
    const tooltipCopy = document.getElementById("tooltip_copy");
    const { clientX, clientY } = e;
    if (!tooltipCopy) return;
    tooltipCopy.setAttribute(
      "style",
      `
      top: ${clientY + 20}px;
      left: ${clientX}px;
      opacity: 1;
      visibility: visible;
      pointer-events: all;
    `
    );
  };
  const onMouseLeave = () => {
    const tooltipCopy = document.getElementById("tooltip_copy");
    if (!tooltipCopy) return;
    tooltipCopy.setAttribute(
      "style",
      `
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    `
    );
  };
  return (
    <Wrapper>
      <Container>
        <LeftContainer>
          <h2>{t("Tracking Status")}</h2>
          {showMore ? (
            trackingData.map((item, index) => (
              <Row key={index} active={index === 0}>
                {index === 0 ? <FirstBreak /> : <Break />}
                <div>{formattedDateTimeBefore(item.time)}</div>
                <div>{item.status}</div>
              </Row>
            ))
          ) : (
            <Row active={true}>
              <FirstBreak />
              <div>{formattedDateTimeBefore(trackingData[0].time)}</div>
              <div>{trackingData[0].status}</div>
            </Row>
          )}
        </LeftContainer>
        <RightContainer
          showTooltip={showTooltip}
          onMouseEnter={onHover}
          onMouseLeave={onMouseLeave}
        >
          <span onClick={onClickOrdersCode}>
            {orders_code} <CopyIcon />
          </span>
          <HoverTooltip id="tooltip_copy">{t("Click to copy")}</HoverTooltip>
        </RightContainer>
      </Container>
      <ShowMoreBtn>
        <button onClick={() => setShowMore(!showMore)}>
          {!showMore ? t("Show more") : t("Show less")}
        </button>
      </ShowMoreBtn>
    </Wrapper>
  );
};

export default Tracking;
const Loading = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const HoverTooltip = styled.div`
  position: fixed;
  color: #fff;
  font-size: 1.2rem;
  background-color: rgba(6, 20, 26, 0.5);
  padding: 0.5rem 1rem;
  border-radius: 5px;
  transition: opacity 0.3s;

  opacity: 0;
  visibility: hidden;
  pointer-events: none;
`;

export const Break = styled.div`
  margin-left: 1rem;
  width: 1px;
  height: 100%;
  background-color: #8f8f8f;
  position: relative;
  &::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 7px;
    height: 7px;
    transform: translate(-50%, -50%);
    background-color: #8f8f8f;
    border-radius: 50%;
  }
`;
export const BreakOneItem = styled(Break)`
  width: 0 !important;
`;

export const FirstBreak = styled(Break)`
  align-self: flex-end !important;
  &::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 15px;
    height: 15px;
    transform: translate(-50%, -50%);
    background-color: #1268e9;
    border-radius: 50%;
  }
  &::before {
    content: "";
    position: absolute;
    top: 42%;
    left: -90%;
    width: 3px;
    height: 7px;
    border-bottom: 1px solid #fff;
    border-right: 2px solid #fff;
    transform: rotate(45deg);
    z-index: 99;
  }
`;
export const EndBreak = styled(Break)`
  height: 42% !important;
  align-self: flex-start !important;
  &::after {
    top: 100% !important;
    transform: translateX(-50%) !important;
  }
`;

export const Row = styled.div<{ active?: boolean }>`
  display: flex;
  width: 100%;
  height: 6rem;
  gap: 2rem;
  align-items: center;
  overflow: hidden;
  > div {
    height: 100%;
    color: ${({ active }) => (active ? "#1268E9" : "#8F8F8F")};

    &:nth-child(2) {
      /* flex-grow: 1; */
      width: auto;

      display: flex;
      justify-content: flex-start;
      align-items: center;

      font-family: "Archivo";
      font-size: 1.4rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 0.014rem;
    }
    &:last-child {
      width: 45%;
      /* flex-grow: 1; */
      display: flex;
      justify-content: flex-start;
      align-items: center;

      font-weight: bold;
      font-family: "Archivo";
      font-size: 1.6rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;

      white-space: nowrap;
    }
  }
  @media (max-width: ${breakPoints.ipad}) {
    > div {
      &:last-child {
        width: 30%;
        font-size: 1.2rem;
        white-space: pre-wrap;
        text-align: left;
      }
    }
  }
  @media (max-width: ${breakPoints.mobile}) {
    > div {
      &:last-child {
        flex-grow: 1;
      }
    }
  }
`;
export const RightContainer = styled.div<{ showTooltip?: boolean }>`
  width: 30%;
  height: 100%;
  align-self: flex-start;

  display: flex;
  align-items: center;
  justify-content: flex-end;
  > span {
    display: flex;
    align-items: center;
    gap: 0.5rem;

    color: var(--gray-scale-black-60, #525252);
    text-align: right;
    font-family: "Archivo";
    font-size: 1.6rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.016rem;

    position: relative;
    cursor: pointer;
    &::after {
      content: "Text is copied";
      display: block;
      position: absolute;
      bottom: 120%;
      right: 0;
      padding: 0.8rem;

      background-color: rgba(0, 0, 0, 0.4);
      border-radius: 1rem;
      color: white;
      font-size: 1.2rem;
      white-space: nowrap;
      transition: all 0.3s;

      ${({ showTooltip }) =>
        showTooltip
          ? `
          opacity:1;
      visibility: visible;
      pointer-events: all;
      `
          : `
      opacity: 0;
      visibility: hidden;
      pointer-events: none;
      `}
    }
  }
  @media (max-width: ${breakPoints.ipad}) {
    width: 100%;
  }
`;

export const LeftContainer = styled.div`
  width: 70%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  overflow: auto;
  > p {
    height: 100%;
    display: flex;
    align-items: center;
    font-size: 2rem;
    font-weight: bold;
    font-style: italic;
    color: #8f8f8f;
  }
  > h2 {
    margin-bottom: 1rem;
    align-self: flex-start;

    color: var(--gray-scale-black-80, #2d2d2d);
    font-family: "Archivo";
    font-size: 1.8rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.018rem;
  }
  @media (max-width: ${breakPoints.ipad}) {
    width: 100%;
  }
`;
const ShowMoreBtn = styled.div`
  > button {
    border: none;
    background-color: transparent;
    cursor: pointer;

    color: var(--gray-scale-black-40, #8f8f8f);
    text-align: center;
    font-family: "Archivo";
    font-size: 1.4rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-decoration-line: underline;
  }
`;
const Container = styled.div`
  margin-top: 2rem;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: ${breakPoints.ipad}) {
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
  }
`;
const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
`;
