import moment from "moment";

export function utcToGtm(date: string) {
    return moment.utc(date).local().format('HH:mm DD-MM-YYYY')
}
export const isValidZipCode = new RegExp(/^[0-9]*$/);

export const isValidName = new RegExp(/^[\p{L} ]+$/u);
export const isValidPhoneNumber = new RegExp(/^[0-9]*$/);
export const isValidEmail = new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
export const formattedDate = (date: any) => {
    return moment(date).utc().format("DD-MMM-YYYY hh:mm")
}
export const formattedDateTimeBefore = (date: any) => {
    return moment(date).utc().format(" hh:mm DD-MMM-YYYY")
}
export const formattedDateWithoutTime = (date: any) => {
    return moment(date).utc().format("DD-MMM-YYYY")
}