import React, { useEffect, useState } from 'react'
import { Country } from '../pages/payment';
import Api from '../service';

type TPopup = {
    thumbnail: string;
    thumbnailMobile: string;
    redirectLink: string;
    isShow: boolean;
}
const useInitialPopup = () => {
    const [listPopups, setListPopups] = useState<TPopup[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const cdnDomain = "https://daziu008xh8wa.cloudfront.net/popup";
    const s3Domain = "https://phone-start24-presale.s3.us-west-1.amazonaws.com/popup";
    const getListPopups = async () => {
        try {
            const response = await Api.Popup.getPopup();
            if (response?.data) {
                const data = response.data;
                data.map((item: TPopup) => {
                    item.thumbnail = item.thumbnail.replace(s3Domain, cdnDomain);
                    item.thumbnailMobile = item.thumbnailMobile.replace(s3Domain, cdnDomain);
                    return item;
                })
                setListPopups(data);
            }
        } catch (error) {
            console.log('error:', error)
        } finally {
            setIsLoading(false);
        }
    };
    useEffect(() => {
        getListPopups();
    }, [])


    return { listPopups, isLoading }
}

export default useInitialPopup