import React, { useContext, useState } from "react";
import { styled } from "styled-components";
import useCart from "../../../../hooks/use-cart";
import PaymentContext, { ProductCode } from "../../context/payment-context";
import BlackPhone from "../../icons/black-phone.png";
import WhitePhone from "../../icons/white-phone.png";
import PlusIcon2 from "../../../Purchase/icons/Plus";
import MinusIcon2 from "../../../Purchase/icons/Minus";
import useWindowDimensions from "../../../../hooks/useWindowDimensions";
import { breakPoints } from "../../../../constant/breakpoints";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import QuantityInput from "./QuantityInput";
import {
  ButtonGradient,
  ButtonGradientI,
} from "../../../../components/ButtonGradient";
import { PaymentMethod } from "../..";
import EditIcon from "../../../../components/layout/icons/EditIcon";
import ReferralModal from "./ReferralModal";
import { toast } from "react-toastify";

const Container = styled.div`
  width: 100%;
`;
const BreakLine = styled.div`
  background: #b1b1b1;
  display: block;
  width: 100%;
  height: 0.2rem;
  margin: 1rem 0;
`;
const Row = styled.div`
  width: 100%;
  display: flex;
  > div > span {
    color: var(--gray-scale-black-60, #525252);
    font-family: "Archivo";
    font-size: 1.6rem;
    font-style: normal;
    font-weight: 400;
    line-height: 170%; /* 2.72rem */
    letter-spacing: 0.016rem;
    text-transform: uppercase;
  }
  > div:nth-child(1) {
    width: 70%;
  }
  > div:nth-child(2) {
    width: 15%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    span {
      display: block;
      text-align: center;
    }
  }
  > div:nth-child(3) {
    width: 15%;
    span {
      direction: rtl;
      display: block;
    }
  }
`;
const ItemsContainer = styled.div`
  margin: 4.8rem 0;
  display: flex;
  flex-direction: column;
  row-gap: 4.8rem;
  @media (max-width: ${breakPoints.ipad}) {
    margin: 2.4rem 0;
  }
`;
const InfoItem = styled.div`
  display: flex;
  gap: 2.4rem;
  @media (max-width: ${breakPoints.ipad}) {
    align-items: flex-start;
    width: 90vw;
    img {
      max-width: 8.4rem;
    }
  }
`;

const Thumbnail = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 4.8rem;
  height: 4.8rem;
  background-color: #f2f2f2;
  border-radius: 0.4rem;
  aspect-ratio: 1/1;
  > img {
    width: 1.8rem;
  }

  @media (min-width: ${breakPoints.laptop}) {
    width: 14.5rem;
    height: 14.5rem;
    border-radius: 1.7rem;
    > img {
      width: 4.95rem;
    }
  }
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 2.4rem;
  h3 {
    color: var(--gray-scale-black-80, #2d2d2d);
    font-family: "Archivo";
    font-size: 2.4rem;
    font-style: normal;
    font-weight: 500;
    line-height: 140%; /* 3.36rem */
  }
  label {
    color: var(--gray-scale-black-40, #8f8f8f);
    font-family: "Archivo";
    font-size: 1.8rem;
    font-style: normal;
    font-weight: 400;
    line-height: 170%; /* 3.06rem */
    letter-spacing: 0.018rem;
    margin-right: 0.8rem;
  }
  p {
    color: var(--gray-scale-black-40, #8f8f8f);

    /* BODY 3 */
    font-family: Archivo;
    font-size: 1.4rem;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 2.1rem */
    letter-spacing: 0.014rem;
  }

  @media (max-width: ${breakPoints.ipad}) {
    row-gap: 1.2rem;
    flex-grow: 1;
    h3 {
      font-size: 1.6rem;
      font-style: normal;
      font-weight: 400;
      line-height: 140%; /* 2.24rem */
      letter-spacing: 0.016rem;
    }
    label {
      font-size: 1.4rem;
      line-height: 150%; /* 2.1rem */
      letter-spacing: 0.014rem;
    }
  }
`;
const OriginalPrice = styled.span`
  color: var(--gray-scale-black-40, #8f8f8f);
  font-family: "Archivo";
  font-size: 1.8rem;
  font-style: normal;
  font-weight: 400;
  line-height: 170%; /* 3.06rem */
  letter-spacing: 0.018rem;
  text-decoration: line-through;
  margin-right: 0.5rem;
  @media (max-width: ${breakPoints.ipad}) {
    font-size: 1.4rem;
    line-height: 150%; /* 2.1rem */
    letter-spacing: 0.014rem;
  }
`;
const SalePrice = styled(OriginalPrice)`
  font-weight: 600;
  background: var(
    --GRADIENT,
    linear-gradient(237deg, #1268e9 -15.33%, #b43bf0 45.3%, #f64f59 105.7%)
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  @media (max-width: ${breakPoints.ipad}) {
    font-size: 1.4rem;
    line-height: 150%; /* 2.1rem */
    letter-spacing: 0.014rem;
  }
`;
const QuantityWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  > span {
    margin-top: 1rem;
    color: var(--gray-scale-black-40, #8f8f8f);
    text-align: center;
    font-family: "Archivo";
    font-size: 1.6rem;
    font-style: normal;
    font-weight: 400;
    line-height: 170%; /* 2.72rem */
    letter-spacing: 0.016rem;
    text-decoration-line: underline;
    cursor: pointer;
  }
  @media (max-width: ${breakPoints.ipad}) {
    flex-direction: row;
    > span {
      margin-top: 0;
      margin-left: 2rem;
    }
  }
`;
const QuantityContainer = styled.div`
  width: 100%;
  padding: 0.5rem 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 0.4rem;
  border: 1px solid #b3b3b3;
  user-select: none !important;
  span {
    cursor: pointer;
  }
  label {
    color: var(--gray-scale-black-80, #2d2d2d);
    text-align: center;
    font-family: "Archivo";
    font-size: 1.6rem;
    font-style: normal;
    font-weight: 400;
    line-height: 170%; /* 2.72rem */
    letter-spacing: 0.016rem;
  }
  @media (max-width: ${breakPoints.ipad}) {
    width: 40%;
    padding: 0.1rem 0.5rem;
  }
`;
const TotalPrice = styled.p`
  color: var(--gray-scale-black-80, #2d2d2d) !important;
  text-align: right;
  font-family: "Archivo";
  font-size: 2rem;
  font-style: normal;
  font-weight: 400;
  line-height: 170%; /* 3.4rem */
  letter-spacing: 0.02rem;
`;

const SummaryContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 2.4rem;
  margin-top: 4.8rem;
  @media (max-width: ${breakPoints.ipad}) {
    row-gap: 1.2rem;
  }
`;
const Row2 = styled.div`
  display: flex;
  justify-content: space-between;
  > label {
    color: var(--gray-scale-black-60, #525252);
    font-family: "Archivo";
    font-size: 1.6rem;
    font-style: normal;
    font-weight: 400;
    line-height: 170%; /* 2.72rem */
    letter-spacing: 0.016rem;
    text-transform: uppercase;
  }
  > p {
    color: var(--gray-scale-black-80, #2d2d2d);
    font-family: "Archivo";
    font-size: 2rem;
    font-style: normal;
    font-weight: 600;
    line-height: 170%; /* 3.4rem */
    letter-spacing: 0.02rem;
  }
  @media (max-width: ${breakPoints.ipad}) {
    > label {
      font-size: 1.6rem;
      text-transform: capitalize;
    }
  }
`;

const GiftLabel = styled.div`
  display: flex;
  padding: 0.6rem 0.8rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 0.4rem;
  border: 0.1rem solid #f64f59;
  width: fit-content;
  /* text */
  color: #f64f59;
  font-family: Archivo;
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 400;
  line-height: 170%;
  letter-spacing: 0.018rem;
  @media (min-width: ${breakPoints.laptop}) {
    font-size: 1.8rem;
  }
`;

const GiftQuantity = styled.div`
  width: 100%;
  flex-direction: column;
  align-items: center !important;
  justify-content: start !important;
  text-align: center;
  row-gap: 1.2rem;
  > p {
    color: #8f8f8f;
    text-align: center;
    font-family: "Archivo";
    font-size: 1.6rem;
    font-style: normal;
    font-weight: 400;
    line-height: 170%; /* 27.2px */
    letter-spacing: 0.016rem;
  }
`;

const EmptyCart = styled.div`
  width: 70vw;
  height: 50dvh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 3.4rem;
  > h5 {
    color: #000;
    text-align: center;
    font-family: "Archivo";
    font-size: 2.4rem;
    font-style: normal;
    font-weight: 400;
    line-height: 180%; /* 4.32rem */
  }
  @media (max-width: ${breakPoints.ipad}) {
    width: 100%;
    height: 50dvh;
  }
`;
const OrderBtn = styled(ButtonGradient)`
  display: flex;
  width: 44.8rem;
  padding: 1.4rem 4rem;
  justify-content: center;
  align-items: center;

  color: #fff;
  /* Button */
  font-family: "Archivo";
  font-size: 2.4rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  @media (max-width: ${breakPoints.mobile}) {
    width: auto;
    font-size: 2rem;
  }
`;

const ReferralDisplay = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  > p {
    color: var(--gray-scale-black-80, #2d2d2d);
    font-family: "Archivo";
    font-size: 2rem;
    font-style: normal;
    font-weight: 600;
    line-height: 170%; /* 3.4rem */
    letter-spacing: 0.02rem;
  }
  > label {
    color: var(--gray-scale-black-60, #525252);
    font-family: "Archivo";
    font-size: 1.6rem;
    font-style: normal;
    font-weight: 400;
    line-height: 170%; /* 2.72rem */
    letter-spacing: 0.016rem;
    text-transform: uppercase;
  }
`;

const LabelReferral = styled.span`
  background: #f2f2f2;
  color: #616161;
  padding: 1rem;
  font-size: 1.6rem;
  font-style: normal;
  line-height: 130%;
  display: flex;
  justify-content: center;
  gap: 0.5rem;

  cursor: pointer;
`;

const Cart = () => {
  const { t } = useTranslation();
  const {
    cart,
    paymentMethod,
    addItem,
    removeItem,
    removeItems,
    getNumberOfItems,
  } = useCart();
  const { items, totalPrice } = cart;
  const { data } = useContext(PaymentContext);
  const [showReferralModal, setShowReferralModal] = useState(false);
  const navigate = useNavigate();

  const windowDimensions = useWindowDimensions();
  const isIpad = windowDimensions.width < 820;

  const totalDiscount =
    paymentMethod === PaymentMethod.Pools
      ? (data?.discount || 0) * getNumberOfItems() * 10
      : (data?.discount || 0) * getNumberOfItems();

  const referral = data?.referral;

  return (
    <>
      {!items || items.length <= 0 || totalPrice === 0 ? (
        <EmptyCart>
          <h5>{t("Your cart is empty")}</h5>
          <OrderBtn onClick={() => navigate("/purchase/phone/black")}>
            {t("Order Now")}
          </OrderBtn>
        </EmptyCart>
      ) : (
        <Container>
          {!isIpad && (
            <>
              <Row>
                <div>
                  <span>{t("Product")}</span>
                </div>
                <div>
                  <span>{t("Quantity")}</span>
                </div>
                <div>
                  <span>{t("Actual price")}</span>
                </div>
              </Row>
              <BreakLine />
            </>
          )}

          <ItemsContainer>
            {cart.items.map((item) => (
              <>
                <Row key={item.product._id}>
                  <div>
                    <InfoItem>
                      <Thumbnail>
                        <img
                          src={
                            item.product?.code === ProductCode.Black
                              ? BlackPhone
                              : WhitePhone
                          }
                          alt="phone"
                        />
                      </Thumbnail>
                      <TextContainer>
                        <h3>{item.product.name} / RAM 12GB / 256GB </h3>
                        <div>
                          <label>{t("Unit Price")}: </label>
                          <OriginalPrice>
                            {item.product.origin_price !==
                              item.product.sale_price &&
                              `$${item.product.origin_price}`}
                          </OriginalPrice>
                          <SalePrice>${item.product.sale_price}</SalePrice>
                        </div>
                        {isIpad && (
                          <div>
                            <QuantityWrapper>
                              <QuantityContainer>
                                <span onClick={() => removeItem(item.product)}>
                                  <MinusIcon2 width={12} />
                                </span>
                                <label>{item.quantity}</label>
                                <span
                                  onClick={() => {
                                    if (item.quantity >= 10) {
                                      toast.error(
                                        t(
                                          "You have reached the order limit (maximum 10)"
                                        )
                                      );
                                      return;
                                    }
                                    addItem({
                                      product: item.product,
                                      quantity: 1,
                                    });
                                  }}
                                >
                                  <PlusIcon2 width={12} />
                                </span>
                              </QuantityContainer>
                              <span onClick={() => removeItems(item.product)}>
                                {t("Remove")}
                              </span>
                            </QuantityWrapper>
                          </div>
                        )}
                      </TextContainer>
                    </InfoItem>
                  </div>
                  {!isIpad && (
                    <>
                      <div>
                        <QuantityWrapper>
                          <QuantityContainer>
                            <span onClick={() => removeItem(item.product)}>
                              <MinusIcon2 />
                            </span>
                            <QuantityInput item={item} />
                            <span
                              onClick={() => {
                                if (item.quantity >= 10) {
                                  toast.error(
                                    t(
                                      "You have reached the order limit (maximum 10)"
                                    )
                                  );
                                  return;
                                }
                                addItem({ product: item.product, quantity: 1 });
                              }}
                            >
                              <PlusIcon2 />
                            </span>
                          </QuantityContainer>
                          <span onClick={() => removeItems(item.product)}>
                            {t("Remove")}
                          </span>
                        </QuantityWrapper>
                      </div>
                      <div>
                        <TotalPrice>${item.product.sale_price}</TotalPrice>
                      </div>
                    </>
                  )}
                </Row>
                {/* gifts */}
                {item.product.gifts.length > 0 &&
                  item.product.gifts.map((gift) => (
                    <Row key={gift._id}>
                      <div>
                        <InfoItem>
                          <Thumbnail>
                            <img
                              src={gift.product.images[0]}
                              alt="phone pouch"
                            />
                          </Thumbnail>
                          <TextContainer>
                            <h3>{t(`${gift.product.name}`)}</h3>
                            <GiftLabel>{t("Gift")}</GiftLabel>
                            {!isIpad && (
                              <p>
                                {t(
                                  `Come with START24 ${
                                    item.product.code === ProductCode.Black
                                      ? "BLACK"
                                      : "WHITE"
                                  } version`
                                )}
                              </p>
                            )}

                            {isIpad && (
                              <>
                                <GiftQuantity>
                                  <QuantityContainer>
                                    <label
                                      style={{ flex: 1, textAlign: "center" }}
                                    >
                                      {item.quantity <
                                      gift.quantity - gift.quantitySold
                                        ? item.quantity
                                        : gift.quantity - gift.quantitySold}
                                    </label>
                                  </QuantityContainer>
                                  {/* <p>
                                {gift.quantity - gift.quantitySold} pieces
                                available
                              </p> */}
                                </GiftQuantity>
                              </>
                            )}
                          </TextContainer>
                        </InfoItem>
                      </div>
                      {!isIpad && (
                        <>
                          <GiftQuantity>
                            <QuantityContainer>
                              <label style={{ flex: 1, textAlign: "center" }}>
                                {item.quantity <
                                gift.quantity - gift.quantitySold
                                  ? item.quantity
                                  : gift.quantity - gift.quantitySold}
                              </label>
                            </QuantityContainer>
                            <p>
                              {/* {gift.quantity - gift.quantitySold}{" "}
                              {t("pieces available")} */}
                            </p>
                          </GiftQuantity>
                          <div>
                            <TotalPrice>${0}</TotalPrice>
                          </div>
                        </>
                      )}
                    </Row>
                  ))}
              </>
            ))}
          </ItemsContainer>
          <BreakLine />
          <SummaryContainer>
            <Row2>
              <label>{t("Subtotal")}</label>
              <p>${cart.totalPrice}</p>
            </Row2>
            <Row2>
              <ReferralDisplay>
                <label>{t("Referral")}</label>
                <LabelReferral
                  onClick={() => {
                    setShowReferralModal(true);
                  }}
                >
                  {referral ? (
                    <>
                      <span>{referral}</span> <EditIcon />
                    </>
                  ) : (
                    <PlusIcon2 />
                  )}
                </LabelReferral>
              </ReferralDisplay>

              <p>-${totalDiscount}</p>
            </Row2>
            <Row2>
              <p>{t("Total")}</p>
              <p>${cart.totalPrice - totalDiscount}</p>
            </Row2>
          </SummaryContainer>
          <ReferralModal
            isOpen={showReferralModal}
            onClose={() => setShowReferralModal(false)}
          />
        </Container>
      )}
    </>
  );
};

export default Cart;
