function EditIcon({ $darkMode }: { $darkMode?: boolean }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
    >
      <path
        d="M9.57521 4.26801L10.5619 3.28134C10.6857 3.15747 10.8327 3.05921 10.9945 2.99217C11.1563 2.92513 11.3297 2.89062 11.5049 2.89062C11.68 2.89062 11.8534 2.92513 12.0152 2.99217C12.177 3.05921 12.3241 3.15747 12.4479 3.28134L13.3905 4.22401C13.6405 4.47404 13.7809 4.81312 13.7809 5.16667C13.7809 5.52023 13.6405 5.8593 13.3905 6.10934L12.4039 7.09601M9.57521 4.26801L3.16454 10.678C2.94319 10.8994 2.80678 11.1915 2.77921 11.5033L2.61787 13.33C2.60921 13.4271 2.62195 13.5249 2.65519 13.6166C2.68843 13.7082 2.74137 13.7915 2.81028 13.8604C2.87918 13.9294 2.96238 13.9824 3.054 14.0158C3.14561 14.0491 3.24343 14.0619 3.34054 14.0533L5.16721 13.892C5.47949 13.8647 5.77218 13.7283 5.99387 13.5067L12.4039 7.09601M9.57521 4.26801L12.4039 7.09601"
        stroke={$darkMode ? "white" : "black"}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default EditIcon;
