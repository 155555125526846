import React, { Dispatch, SetStateAction } from "react";
import {
  ConfirmMode,
  Email,
  ShippingContainer,
  ShippingRow,
} from "./PaymentConfirmation";
import EditIcon from "../../icons/edit.svg";
import { PaymentI } from "../../context/payment-context";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

interface IProps {
  setStep?: Dispatch<SetStateAction<number>>;
  isOpen: Dispatch<SetStateAction<boolean>>;
  data: PaymentI | undefined;
  mode?: ConfirmMode;
  tracking?: boolean;
}
const BillingInfo = ({ setStep, isOpen, data, mode, tracking }: IProps) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { t } = useTranslation();
  return (
    <ShippingContainer>
      <ShippingRow>
        <h3>{t("Billing Information")}</h3>
        {mode === ConfirmMode.Payment && (
          <p
            onClick={() => {
              isOpen(false);
              if (setStep) {
                setStep(1);
              }
              searchParams.set("step", "1");
              setSearchParams(searchParams);
            }}
          >
            <img src={EditIcon} alt="edit" />
          </p>
        )}
      </ShippingRow>
      <ShippingRow>
        <label>{t("Full name")}</label>
        <span>{data?.info?.full_name}</span>
      </ShippingRow>
      <ShippingRow>
        <label>{t("Phone number")}</label>
        <span>
          {!data?.info?.phone_code?.includes("+") && <span>+</span>}
          {data?.info?.phone_code} {data?.info?.phone_number}
        </span>
      </ShippingRow>
      <ShippingRow>
        <label>Email</label>
        <Email>{data?.info?.email}</Email>
      </ShippingRow>
      <div style={{ marginBottom: "2rem" }}></div>
    </ShippingContainer>
  );
};

export default BillingInfo;
