import React, { createContext, useCallback, useEffect, useState } from "react";

import { Outlet, useSearchParams } from "react-router-dom";
import { IProduct } from "./product-context";
import Api from "../service";
import moment from "moment";
import { PaymentMethod } from "../pages/payment";
import { deflate } from "zlib";
interface Props {
  children: React.ReactNode;
}

export enum ProductCode {
  White = "WHITE_START24_PHONE",
  Black = "BLACK_START24_PHONE",
}

// export interface IProduct {
//   product_code: string;
//   color: string;
//   price: number;
// }
export interface IItem {
  product: IProduct;
  quantity: number;
  origin_price?: number;
  sale_price?: number;
}
export interface ICart {
  items: IItem[] | [];
  totalPrice: number;
  refCode?: string;
  refCodeExpiredAt?: number;
}
const initCart = {
  items: [],
  totalPrice: 0,
  refCode: "",
  refCodeExpiredAt: 0,
};

interface CartContextType {
  cart: ICart;
  createNewCart: (cart: ICart) => void;
  addItem: ({
    product,
    quantity,
  }: {
    product: IProduct;
    quantity: number;
  }) => void;
  setNewItem: ({
    product,
    quantity,
  }: {
    product: IProduct;
    quantity: number;
  }) => void;
  removeItem: (product: IProduct) => void;
  removeItems: (product: IProduct) => void;
  clearCart: () => void;
  getNumberOfItems: () => number;
  updateRefCode: (refCode: string) => void;
  paymentMethod: PaymentMethod;
  changePaymentMethod: (paymentMethod: PaymentMethod) => void;
}

// Create the context
const CartContext = createContext<CartContextType>({
  cart: initCart,
  paymentMethod: PaymentMethod.Paypal,
  /*eslint-disable */
  createNewCart: () => {},
  addItem: () => {},
  setNewItem: () => {},
  removeItem: () => {},
  removeItems: () => {},
  clearCart: () => {},
  getNumberOfItems: () => 0,
  updateRefCode: () => {},
  changePaymentMethod: () => {},
  /*eslint-disable */
});

// Custom Provider component
export const CartContextProvider = (props: Props) => {
  const [cart, setCart] = useState<ICart>(initCart);
  const [paymentMethod, setPaymentMethod] = useState(PaymentMethod.Paypal);
  const [defaultPrice, setDefaultPrice] = useState(true);
  useEffect(() => {
    if (paymentMethod === PaymentMethod.Pools && defaultPrice) {
      const newItems = cart.items.map((item) => ({
        ...item,
        product: {
          ...item.product,
          sale_price: item.product.sale_price * 10,
          origin_price: item.product.origin_price * 10,
        },
      }));
      const newTotal = cart.totalPrice * 10;
      setCart({
        ...cart,
        items: newItems,
        totalPrice: newTotal,
      });
      setDefaultPrice(false);
    }
    if (paymentMethod !== PaymentMethod.Pools && !defaultPrice) {
      const newItems = cart.items.map((item) => ({
        ...item,
        product: {
          ...item.product,
          sale_price: item.product.sale_price / 10,
          origin_price: item.product.origin_price / 10,
        },
      }));
      const newTotal = cart.totalPrice / 10;

      setCart({
        ...cart,
        items: newItems,
        totalPrice: newTotal,
      });
      setDefaultPrice(true);
    }
  }, [paymentMethod]);

  const createNewCart = (cart: ICart) => {
    const { items } = cart;
    const totalPrice = items.reduce((total, item) => {
      return total + item.quantity * item.product.sale_price;
    }, 0);
    setCart({ ...cart, totalPrice });
  };
  const updateCartToServer = async (cart: ICart) => {
    try {
      const newItems = cart.items.map((item) => ({
        quantity: item.quantity,
        product: item.product._id,
      }));
      let cartToPost: any = {
        ...cart,
        items: newItems,
      };
      if (paymentMethod === PaymentMethod.Pools && !defaultPrice) {
        const newTotal = cart.totalPrice / 10;
        cartToPost = {
          ...cart,
          totalPrice: newTotal,
        };
      }
      await Api.Cart.updateCart(cartToPost);
    } catch (error) {
      console.log("--cart-error:", error);
    }
  };
  const addItem = async ({
    product,
    quantity,
  }: {
    product: IProduct;
    quantity: number;
  }) => {
    const index = cart.items.findIndex(
      (item) => item.product.code === product.code
    );
    const items: IItem[] = cart.items;
    let newItem: IItem;

    if (index >= 0) {
      const item = cart.items[index];
      newItem = {
        ...item,
        quantity: item.quantity + quantity,
      };
      items[index] = newItem;
    } else {
      newItem = {
        product,
        quantity,
      };
      items.push(newItem);
    }

    const newCart = {
      ...cart,
      items,
      totalPrice: cart.totalPrice + product.sale_price * quantity,
    };
    updateCartToServer(newCart);
    setCart((prev) => {
      return {
        ...prev,
        items,
        totalPrice: prev.totalPrice + product.sale_price * quantity,
      };
    });
  };

  const removeItem = (product: IProduct) => {
    const index = cart.items.findIndex(
      (item) => item.product.code === product.code
    );
    const items: IItem[] = cart.items;
    let newItem: IItem;

    if (index >= 0) {
      const item = cart.items[index];
      if (item.quantity === 1) {
        items.splice(index, 1);
      } else {
        newItem = {
          ...item,
          quantity: item.quantity - 1,
        };
        items[index] = newItem;
      }
    } else {
      items.slice(index, 1);
    }

    const newCart = {
      ...cart,
      items,
      totalPrice: cart.totalPrice - product.sale_price,
    };
    updateCartToServer(newCart);

    setCart((prev) => {
      return {
        ...prev,
        items,
        totalPrice: prev.totalPrice - product.sale_price,
      };
    });
  };

  const removeItems = async (product: IProduct) => {
    const index = cart.items.findIndex(
      (item) => item.product.code === product.code
    );

    const items: IItem[] = cart.items;
    const item = items[index];

    const newItems = items.filter((item) => item.product.code !== product.code);

    const newCart = {
      ...cart,
      items: newItems,
      totalPrice: cart.totalPrice - item.quantity * product.sale_price,
    };
    updateCartToServer(newCart);
    setCart((prev) => {
      return {
        ...prev,
        items: newItems,
        totalPrice: prev.totalPrice - item.quantity * product.sale_price,
      };
    });
  };

  const setNewItem = async ({
    product,
    quantity,
  }: {
    product: IProduct;
    quantity: number;
  }) => {
    const index = cart.items.findIndex(
      (item) => item.product.code === product.code
    );

    const items: IItem[] = cart.items;
    const prevTotalPrice = cart.totalPrice;
    const newTotalPrice =
      prevTotalPrice -
      items[index].quantity * product.sale_price +
      quantity * product.sale_price;

    items[index].quantity = quantity;

    const newCart = {
      ...cart,
      items,
      totalPrice: newTotalPrice,
    };

    setCart((prev) => {
      return {
        ...prev,
        items,
        totalPrice: newTotalPrice,
      };
    });
  };

  const clearCart = () => {
    const newInitCart = {
      ...initCart,
      refCode: cart.refCode,
      refCodeExpiredAt: cart.refCodeExpiredAt,
    };
    setCart(newInitCart);
    updateCartToServer(newInitCart);
  };

  const getNumberOfItems = useCallback(() => {
    let totalQuantity = 0;
    cart.items.forEach((item) => {
      totalQuantity += item.quantity;
    });
    return totalQuantity;
  }, [cart]);

  const updateRefCode = async (refCode: string) => {
    const expiredAt = moment().add(15, "days").valueOf();
    const refCodeExpiredAt = +(expiredAt / 1000).toFixed(0);
    const response = await Api.Cart.updateRefCode({
      refCode,
      refCodeExpiredAt,
    });
    if (response && response?.data) {
      let newCart: ICart = {
        items: response.data.items,
        totalPrice: response.data.totalPrice,
        refCode: response.data.refCode,
        refCodeExpiredAt: response.data.refCodeExpiredAt,
      };

      if (paymentMethod === PaymentMethod.Pools) {
        const newItems = newCart.items.map((item) => ({
          ...item,
          product: {
            ...item.product,
            sale_price: item.product.sale_price * 10,
            origin_price: item.product.origin_price * 10,
          },
        }));
        const newTotal = newCart.totalPrice * 10;
        newCart = {
          ...newCart,
          items: newItems,
          totalPrice: newTotal,
        };
      }
      createNewCart(newCart);
    }
  };

  const changePaymentMethod = async (method: PaymentMethod) => {
    setPaymentMethod(method);
  };

  return (
    <CartContext.Provider
      value={{
        cart,
        createNewCart,
        addItem,
        setNewItem,
        removeItem,
        removeItems,
        clearCart,
        getNumberOfItems,
        updateRefCode,
        paymentMethod,
        changePaymentMethod,
      }}
    >
      {props.children}
    </CartContext.Provider>
  );
};

export const CartOutlet = (props: any) => {
  return (
    <CartContextProvider>
      {props.children ? props.children : <Outlet />}
    </CartContextProvider>
  );
};
export default CartContext;
