import { styled } from "styled-components";
import { ButtonGradient } from "../ButtonGradient";
import FB from "./icons/fb.svg";
import Tele from "./icons/telegram.svg";
import Yt from "./icons/yt.svg";
import Medium from "./icons/medium.svg";
import Tiktok from "./icons/tiktok.svg";
import Instagram from "./icons/instagram.svg";
import { breakPoints } from "../../constant/breakpoints";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Wrapper = styled.div<{ $isMiniHeight: boolean }>`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6rem 6rem 0 0;
  background: var(--gray-scale-black, #121212);
  padding-top: 6rem;
  padding-bottom: ${(props) => (props.$isMiniHeight ? "8rem" : "3.5rem")};

  @media (min-width: ${breakPoints.ipad}) {
    border-radius: 8rem 8rem 0 0;
  }
  @media (min-width: ${breakPoints.laptop}) {
    border-radius: 12rem 12rem 0 0;
  }
`;
const Container = styled.div`
  padding: 0 0.8rem;
  width: 144rem;
  height: 100%;
  color: var(--gray-scale-white, #fff);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media (max-width: ${breakPoints.mobile}) {
    gap: 3.2rem;
  }

  @media (min-width: ${breakPoints.ipad}) {
    padding: 0 1rem;
  }
  @media (min-width: ${breakPoints.laptop}) {
    padding: 0 2rem;
  }
`;
const Title = styled.span`
  text-align: center;
  font-family: Archivo;
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 400;
  line-height: 115.625%; /* 115.625% */
  text-align: center;
  display: block;
  @media (min-width: ${breakPoints.ipad}) {
    font-size: 4.4rem;
  }
  @media (min-width: ${breakPoints.laptop}) {
    font-size: 6.4rem;
  }
  @media (max-width: ${breakPoints.desktop}) {
    border-radius: 12rem 12rem 0 0;
  }
`;
const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 6rem;
  @media (max-width: ${breakPoints.mobile}) {
    margin-top: 2.4rem;
  }
`;
const AgentButton = styled(ButtonGradient)`
  border-radius: 10rem;
  font-family: Archivo;
  font-size: 2.4rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 1.4rem 3.2rem;
  @media (max-width: ${breakPoints.mobile}) {
    font-size: 1.6rem;
  }
`;
const FooterBottomContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  @media (min-width: ${breakPoints.laptop}) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
`;
const CopyRight = styled.span`
  display: inline-block;
  color: var(
    --secondary-black-secondary-50,
    var(--gray-scale-black-40, #8f8f8f)
  );
  font-family: Archivo;
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 400;
  line-height: 170%; /* 27.2px */
  letter-spacing: 0.016rem;
  text-align: center;
  @media (max-width: ${breakPoints.mobile}) {
    width: 100%;
    font-size: 1.2rem;
  }
  @media (min-width: ${breakPoints.laptop}) {
    text-align: left;
  }
`;
const Policy = styled.ul`
  display: flex;
  gap: 3rem;
  justify-content: center;
  li {
    list-style: none;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    span {
      display: inline-block;
      height: 0.5rem;
      width: 0.5rem;
      background-color: #8f8f8f;
      border-radius: 50%;
    }
    a {
      text-decoration: none;
      color: var(
        --secondary-black-secondary-50,
        var(--gray-scale-black-40, #8f8f8f)
      );
      font-family: Archivo;
      font-size: 1.6rem;
      font-style: normal;
      font-weight: 400;
      line-height: 170%;
      letter-spacing: 0.016rem;
      transition: all 0.3s;
      &:hover,
      &:active {
        color: var(--gray-scale-black, #fff);
      }
    }
  }
  @media (max-width: ${breakPoints.mobile}) {
    gap: 1rem;
    flex-wrap: wrap;
    li {
      a {
        font-size: 1.2rem;
      }
    }
  }
  @media (min-width: ${breakPoints.laptop}) {
    justify-content: start;
  }
`;

const Social = styled.div`
  display: flex;
  gap: 1.5rem;
  justify-content: center;
  > a > img {
    width: 2.3rem;
    aspect-ratio: 1/1;
  }
  :hover {
    > img {
      transition: all 0.4s ease-in-out;
      filter: brightness(3.2);
    }
  }

  @media (max-width: ${breakPoints.mobile}) {
    width: 100%;
  }
  @media (min-width: ${breakPoints.laptop}) {
    justify-content: flex-end;
  }
`;
const Break = styled.div`
  height: 100%;
  width: 1px;
  background: #8f8f8f;
  transform: translateX(90deg);
`;
const TopFooter = styled.div`
  width: 100%;
`;
const LineBreak = styled.div`
  width: 13.4rem;
  height: 0.5rem;
  flex-shrink: 0;
  border-radius: 10rem;
  background: #fff;
`;
const End = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 2rem;
`;

const FooterEmail = styled.ul`
  display: flex;
  flex-direction: column;
  row-gap: 1.6rem;
  align-items: center;
  justify-content: space-between;
  list-style-type: none;
  li {
    text-align: center;
    color: #8f8f8f;
    /* BODY 2 */
    font-family: "Archivo";
    font-size: 1.2rem;
    font-style: normal;
    font-weight: 400;
    line-height: 170%; /* 27.2px */
    letter-spacing: 0.016rem;
    a {
      &:hover {
        color: #fff !important;
      }
    }
  }

  @media (min-width: ${breakPoints.ipad}) {
    margin: 3.2rem 0 1.5rem 0;
    list-style-type: disc;
    li {
      width: fit-content;
      font-size: 1.6rem;
      &:nth-child(2) {
        text-align: center;
      }
    }
  }

  @media (min-width: ${breakPoints.laptop}) {
    flex-direction: row;
    column-gap: 2rem;
  }
`;

const Certificates = styled(FooterEmail)`
  margin: 0;
  > li > a {
    text-align: center;
    color: #8f8f8f;
    /* BODY 2 */
    font-family: "Archivo";
    font-size: 1.2rem;
    font-style: normal;
    font-weight: 400;
    line-height: 170%; /* 27.2px */
    letter-spacing: 0.016rem;
  }
  @media (min-width: ${breakPoints.ipad}) {
    > li > a {
      font-size: 1.6rem;
    }
  }
`;
const Row = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 1.5rem;
`;

function Footer() {
  const { t } = useTranslation();
  const windowDimensions = useWindowDimensions();
  const isIpad = windowDimensions.width <= 820;
  const isMiniHeight = windowDimensions.height < 650;
  return (
    <Wrapper $isMiniHeight={isMiniHeight}>
      <Container>
        <TopFooter>
          <Title>{t("Unlock Your Limitless")}</Title>
          <ButtonContainer>
            <AgentButton
              onClick={() => {
                window.open("https://agent.startmobis.com/");
              }}
            >
              {t("Be an Agent")}
            </AgentButton>
          </ButtonContainer>
        </TopFooter>
        {/*  */}
        <FooterEmail>
          <li>
            {t("Marketing")}:{" "}
            <Link
              style={{
                color: "inherit",
              }}
              to={"mailTo:info@startmobis.com"}
            >
              info@startmobis.com
            </Link>
          </li>
          <li>
            {t("Sales | Partnership | Business Proposal")}:{" "}
            <Link
              style={{
                color: "inherit",
              }}
              to={"mailTo:business@startmobis.com"}
            >
              business@startmobis.com
            </Link>
          </li>
          <li>
            {t("Contact")} | CS:{" "}
            <Link
              style={{
                color: "inherit",
              }}
              to={"mailTo:cs@startmobis.com"}
            >
              cs@startmobis.com
            </Link>
          </li>
        </FooterEmail>
        {/*  */}
        <FooterBottomContainer>
          {!isIpad && (
            <CopyRight>{t("© 2023 START24 All Rights Reserved.")}</CopyRight>
          )}
          {/*  */}
          <Policy>
            <li>
              <span></span>
              <Link
                target="_blank"
                to="https://daziu008xh8wa.cloudfront.net/assets/REFERRAL.pdf"
              >
                {"Referral Policy"}
              </Link>
            </li>
            <li>
              <span></span>
              <Link
                target="_blank"
                to="https://daziu008xh8wa.cloudfront.net/assets/START24_PRIVACY+POLICY.pdf"
              >
                {"Privacy Policy"}
              </Link>
            </li>
            <li>
              <span></span>
              <Link
                target="_blank"
                to="https://daziu008xh8wa.cloudfront.net/assets/START24_COOKIE+POLICY.pdf"
              >
                {"Cookies Policy"}
              </Link>
            </li>
            <li>
              <span></span>
              <Link
                target="_blank"
                to="https://daziu008xh8wa.cloudfront.net/assets/START24_TERMS+OF+USE.pdf"
              >
                {"Terms of Use"}
              </Link>
            </li>
          </Policy>
          {/*  */}
          <Certificates>
            <li>
              {t("Certification")}:{" "}
              <a
                href="https://daziu008xh8wa.cloudfront.net/assets/LING68_EU_Certificate.pdf"
                target="blank"
              >
                LINK68-EU
              </a>{" "}
              |{" "}
              <a
                href="https://daziu008xh8wa.cloudfront.net/assets/MIC-RL-Certificate_231030012_219-237019.pdf"
                target="blank"
              >
                MIC-RL
              </a>{" "}
              |{" "}
              <a
                href="https://s3.console.aws.amazon.com/s3/object/phone-start24-presale?region=us-west-1&prefix=assets/MIC-TBL-Certificate_231030012_DF237004219%281%29.pdf"
                target="blank"
              >
                MIC-TBL
              </a>
            </li>
          </Certificates>

          {isIpad && (
            <CopyRight>{t("© 2023 START24 All Rights Reserved.")}</CopyRight>
          )}
          {/* {isMobile && (
            <End>
              <LineBreak />
            </End>
          )} */}
        </FooterBottomContainer>
        <Row>
          <Social>
            <Link to="https://www.facebook.com/start24phone" target="_blank">
              <img src={FB} alt="facebook icon" />
            </Link>
            <Break />
            <Link to="https://medium.com/@start24" target="_blank">
              <img src={Medium} alt="Medium icon" />
            </Link>
            <Break />
            <Link to="https://t.me/start24phone" target="_blank">
              <img src={Tele} alt="telegram icon" />
            </Link>
            <Break />
            <Link to="https://www.instagram.com/start24phone/" target="_blank">
              <img src={Instagram} alt="instagram icon" />
            </Link>
            <Break />
            <Link to="https://www.youtube.com/@START24Phone" target="_blank">
              <img src={Yt} alt="youtube icon" />
            </Link>
            <Break />
            <Link to="https://www.tiktok.com/@start24phone" target="_blank">
              <img src={Tiktok} alt="Tiktok icon" />
            </Link>
          </Social>
        </Row>
      </Container>
    </Wrapper>
  );
}

export default Footer;
