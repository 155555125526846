import React, { Suspense } from "react";
import Layout, { MainLayout, OutLayout } from "./components/layout/Layout";
import "./i18n";
import { Route, Routes } from "react-router-dom";
import { PaymentOutlet } from "./pages/payment/context/payment-context";
import HomePage from "./pages";
// import SignIn from "./pages/signIn";
// import LayoutLogin from "./components/layoutLogin";
// import SignUp from "./pages/signUp";
// import Media from "./pages/media";
// import MediaDetail from "./pages/media/components/MediaDetail";
// import Support from "./pages/support";
// import ForgotPass from "./pages/forgotPass";
// import OrderTracking from "./pages/orderTracking";
// import Community from "./pages/Community";
// import Purchase from "./pages/Purchase";
// import PurchaseMain from "./pages/PurchaseMain";
// import Payment from "./pages/payment";
// import Approved from "./pages/payment/Approved";
// import AccessoryPage from "./pages/Purchase/components/AccessoryPage";
// import Unsubscribe from "./pages/unsubscribe";
import CustomToast from "./components/layout/CustomToast";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { ProductOutlet } from "./context/product-context";
import LoadingPage from "./components/LoadingPage";
import Page404 from "./pages/404";

// const HomePage = React.lazy(() => import("./pages"));
const SignIn = React.lazy(() => import("./pages/signIn"));
const LayoutLogin = React.lazy(() => import("./components/layoutLogin"));
const SignUp = React.lazy(() => import("./pages/signUp"));
const Media = React.lazy(() => import("./pages/media"));
const MediaDetail = React.lazy(
  () => import("./pages/media/components/MediaDetail")
);
const Support = React.lazy(() => import("./pages/support"));
const ForgotPass = React.lazy(() => import("./pages/forgotPass"));
const OrderTracking = React.lazy(() => import("./pages/orderTracking"));
const Community = React.lazy(() => import("./pages/Community"));
const Purchase = React.lazy(() => import("./pages/Purchase"));
const PurchaseMain = React.lazy(() => import("./pages/PurchaseMain"));
const Payment = React.lazy(() => import("./pages/payment"));
const Approved = React.lazy(() => import("./pages/payment/Approved"));
const AccessoryPage = React.lazy(
  () => import("./pages/Purchase/components/AccessoryPage")
);
const Unsubscribe = React.lazy(() => import("./pages/unsubscribe"));
dayjs.extend(relativeTime);

function App() {
  return (
    <Suspense fallback={<LoadingPage />}>
      <Routes>
        <Route element={<CustomToast />}>
          <Route element={<Layout />}>
            <Route element={<OutLayout />}>
              <Route path="/" element={<HomePage />} />
            </Route>
            <Route element={<MainLayout />}>
              <Route path="/media" element={<Media />} />
              <Route path="/media/:id" element={<MediaDetail />} />
              <Route path="/support" element={<Support />} />
              <Route path="/community" element={<Community />} />

              <Route path="/purchase" element={<ProductOutlet />}>
                <Route element={<PaymentOutlet />}>
                  <Route index element={<PurchaseMain />} />
                  <Route path="phone/:color" element={<Purchase />} />
                  <Route path="accessory/:id" element={<AccessoryPage />} />
                  {/* coming soon */}
                  {/* <Route path="payment" element={<Payment />} />
                  <Route path="approved" element={<Approved />} /> */}
                </Route>
              </Route>
              <Route path="/order-tracking" element={<OrderTracking />} />
              <Route path="/unsubscribe" element={<Unsubscribe />} />
            </Route>
          </Route>
          <Route element={<LayoutLogin />}>
            <Route path="/sign-in" element={<SignIn />} />
            <Route path="/sign-up" element={<SignUp />} />
            <Route path="/forgot-password" element={<ForgotPass />} />

            {/* <Route path="/profile">
            <Route path="update-name" element={<UpdateProfile />} />
            <Route path="change-password" element={<UpdateProfile />} />
          </Route> */}
          </Route>
          <Route path="/*" element={<Page404 />} />
        </Route>
      </Routes>
    </Suspense>
  );
}

export default App;
