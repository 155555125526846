import styled from "styled-components";
import { breakPoints } from "../../../constant/breakpoints";
import { useTranslation } from "react-i18next";
import useTheme from "../../../hooks/use-theme";
import YoutubeVideo from "../../../components/YoutubeVideo/YoutubeVideo";
import { ImagesUrl } from "../../../constant";

const Container = styled.div`
  /* margin: 3.2rem 0; */
  margin-top: 10rem;
`;

const Header = styled.div<{ fontFamily?: string }>`
  display: flex;
  flex-direction: column;
  row-gap: 1.7rem;
  > div {
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 0.6rem;
    > span {
      width: 1.2rem;
      height: 1.2rem;

      border-radius: 0.4rem;
      background: linear-gradient(
        237deg,
        #1268e9 -15.33%,
        #b43bf0 45.3%,
        #f64f59 105.7%
      );
    }
    > p {
      color: #2d2d2d;
      font-family: ${({ fontFamily }) => fontFamily};
      font-size: 1.4rem;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      text-transform: uppercase;
    }
  }
  > h2 {
    color: #2d2d2d;
    text-align: center;

    /* H3 */
    font-family: ${({ fontFamily }) => fontFamily};
    font-size: 3.2rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.128rem;
  }
  @media (min-width: ${breakPoints.laptop}) {
    row-gap: 2.4rem;
    > div {
      column-gap: 1.2rem;
      > span {
        width: 1.6rem;
        height: 1.6rem;
      }
      > p {
        font-size: 2.2rem;
      }
    }
    > h2 {
      font-size: 6.4rem;
    }
  }
`;

const Content = styled.div`
  margin-top: 1.6rem;
  min-height: 60vh;
  width: 100%;
  @media screen and (max-width: ${breakPoints.ipad}) {
    min-height: auto;
  }
`;

function ComponentUnboxing() {
  const { t } = useTranslation();

  const { fontFamily } = useTheme();

  return (
    <Container>
      <Header fontFamily={fontFamily}>
        <div>
          <span></span>
          <p>{t("Video reaction")}</p>
        </div>
        <h2>{t("START24 UNBOXING")}</h2>
      </Header>
      <Content>
        <YoutubeVideo
          thumbnail={ImagesUrl.Youtube_Thumbnail_2}
          videoId={"PQq7b7uXVzw"}
        />
      </Content>
    </Container>
  );
}

export default ComponentUnboxing;
