function MinusIcon({
  width,
  height,
  color,
  onClick,
}: {
  width?: number;
  height?: number;
  color?: string;
  onClick?: () => void;
}) {
  return (
    <svg
      onClick={onClick}
      xmlns="http://www.w3.org/2000/svg"
      width={width ? width : 16}
      height={height ? height : 16}
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M3.33594 8H12.6693"
        stroke="#2D2D2D"
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default MinusIcon;
