import React, { useState } from "react";
import CustomModalV2 from "../modal/CustomModalV2";
import { styled } from "styled-components";
import useInitialPopup from "../../hooks/use-initial-popup";
import CloseIcon from "../../icons/Close";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { useTranslation } from "react-i18next";
import { getLocalItem, setLocalItem } from "../../utils/localStorage";
import { breakPoints } from "../../constant/breakpoints";
import { Link } from "react-router-dom";

const InitialPopup = () => {
  const { t } = useTranslation();
  const { listPopups } = useInitialPopup();
  const [activeIndexPopup, setActiveIndexPopup] = useState<number>(0);
  const window = useWindowDimensions();
  const isMobile = window.width <= 500;
  const onCloseHandler = () => {
    setActiveIndexPopup((prev) => prev + 1);
  };

  const isOpen = activeIndexPopup < listPopups.length && listPopups.length > 0;
  const doNotShowAgain = getLocalItem("do-not-show");
  const isOpenCombination = isOpen && !doNotShowAgain;
  return (
    <CustomModalV2 $isOpen={isOpenCombination} onClose={onCloseHandler}>
      {listPopups.map((item, index) => (
        <Container $isActive={index === activeIndexPopup} key={index}>
          <Wrapper>
            <IconBound>
              <CloseIcon onClick={onCloseHandler} color="#fff" />
            </IconBound>
            <Link
              to={item.redirectLink}
              onClick={onCloseHandler}
              aria-label="Pre order Phone Start24"
            >
              <img
                src={isMobile ? item.thumbnailMobile : item.thumbnail}
                alt="Popup Start24"
              />
            </Link>
            <DoNotShowAgainContainer>
              <input
                id="do-not-show"
                type="checkbox"
                onChange={(e: any) => {
                  setLocalItem("do-not-show", e.target.checked);
                }}
              />
              <label htmlFor="do-not-show">{t("Do not show again")}</label>
            </DoNotShowAgainContainer>
          </Wrapper>
        </Container>
      ))}
    </CustomModalV2>
  );
};

export default InitialPopup;

const IconBound = styled.div`
  position: absolute;
  top: 2%;
  right: 2%;
  width: 3.5rem;
  height: 3.5rem;
  border: none;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: inset 0 0 2px #464646;
  cursor: pointer;
`;
const Wrapper = styled.div`
  width: 100%;
  position: relative;
  > a > img {
    max-width: 100%;
    max-height: 100%;
    width: 120rem;
    aspect-ratio: 16/9;
    background-color: transparent;
    border-radius: 2rem;
  }
`;
const Container = styled.div<{ $isActive?: boolean }>`
  width: 60%;
  height: 100%;
  display: ${({ $isActive }) => ($isActive ? "flex" : "none")};
  justify-content: center;
  align-items: center;
  animation: ${({ $isActive }) => ($isActive ? "InitialPopup 300ms " : "none")};
  -webkit-animation: ${({ $isActive }) =>
    $isActive ? "InitialPopup 300ms " : "none"};

  @keyframes InitialPopup {
    0% {
      transform: scale(0.5);
    }
    100% {
      transform: scale(1);
    }
  }

  @media screen and (max-width: ${breakPoints.mobile}) {
    width: 100%;
  }
`;

const DoNotShowAgainContainer = styled.div`
  position: absolute;
  bottom: 3%;
  right: 3%;
  /* bottom: -7%;
  left: 0%; */

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  background-color: rgb(255, 255, 255, 0.5);
  border-radius: 1rem;
  padding: 1rem;
  box-shadow: 1px 1px 10px 1px rgba(0, 0, 0, 0.2);

  > label {
    font-size: 1.6rem;
    font-family: "Archivo";
    cursor: pointer;
  }

  @media screen and (max-width: ${breakPoints.mobile}) {
    padding: 0.5rem;
    border-radius: 0.5rem;
    > label {
      font-size: 1.2rem;
    }
  }
`;
