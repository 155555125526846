import { useEffect, useState } from "react";

interface CountDownProps {
  time: number;
  onEnd?: () => void;
}

function CountDown({ time = 30, onEnd }: CountDownProps) {
  const [timeOut, setTimeOut] = useState<number>(time);

  useEffect(() => {
    const timeInterval = setInterval(() => {
      if (timeOut > 0) {
        setTimeOut(timeOut - 1);
      }
      if (timeOut === 0) {
        if (onEnd) {
          onEnd();
        }
      }
    }, 1000);

    return () => clearInterval(timeInterval);
  }, [timeOut]);
  return timeOut;
}

export default CountDown;
