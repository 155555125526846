import { useContext } from "react";
import styled from "styled-components";
import EnIcon from "./icons/en.svg";
import VnIcon from "./icons/vn.svg";
import JpIcon from "./icons/jp.svg";
import KoIcon from "./icons/ko.svg";
import SpIcon from "./icons/sp.svg";
import PorIcon from "./icons/por.svg";
import CnIcon from "./icons/cn.svg";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useOnClickOutside } from "../../hooks/useOnClickOutside";
import { getLocalItem, setLocalItem } from "../../utils/localStorage";
import ThemeContext from "../../context/theme-context";
import { LanguageKeys } from "../../constant";
import useTheme from "../../hooks/use-theme";

interface OptionType {
  text: string;
  icon: string;
  code?: string;
}

const Container = styled.div`
  position: relative;
`;

const Select = styled.div`
  cursor: pointer;
  > img {
    flex: 1;
    max-width: 4rem !important;
    height: auto;

    @media (max-width: 500px) {
      max-width: 3rem !important;
    }
  }
`;

const OptionList = styled.div<{ $display: boolean }>`
  position: absolute;
  top: 100%;
  margin-top: auto.4rem;
  right: 0;
  background-color: #121212;
  color: #fff;
  transform-origin: top;
  transform: ${(props) => (props.$display ? "scaleY(100%)" : "scaleY(0)")};
  transition: linear 150ms;
  border-radius: 0.4rem;
  overflow: hidden;
  z-index: 9999;
`;

const Option = styled.div<{ $active: boolean }>`
  padding: 0.4rem 1.2rem;
  display: flex;
  align-items: center;
  column-gap: 0.4rem;
  cursor: ${(props) => (props.$active ? "default" : "pointer")};
  background-color: ${(props) => (props.$active ? "#8f8f8f" : "#121212")};
  transition: linear 150ms;
  > img {
    flex: 1;
    max-width: 2.4rem !important;
    height: auto;
  }
  &:hover {
    background-color: #8f8f8f;
  }
  user-select: none;
`;
const Flag = styled.img`
  width: 4rem;
  aspect-ratio: 1/1;
`;

export enum LANGUAGES_CODE {
  Vietnamese = "vi",
  Korea = "ko",
  English = "en",
  Japan = "jp",
  China = "cn",
  Spanish = "es",
  Portuguese = "pt",
}

const options: OptionType[] = [
  {
    icon: EnIcon,
    text: "English",
    code: LANGUAGES_CODE.English,
  },
  {
    icon: VnIcon,
    text: "Vietnamese",
    code: LANGUAGES_CODE.Vietnamese,
  },
  {
    icon: KoIcon,
    text: "Korea",
    code: LANGUAGES_CODE.Korea,
  },
  {
    icon: JpIcon,
    text: "Japanese",
    code: LANGUAGES_CODE.Japan,
  },
  {
    icon: SpIcon,
    text: "Spanish",
    code: LANGUAGES_CODE.Spanish,
  },
  {
    icon: PorIcon,
    text: "Portuguese",
    code: LANGUAGES_CODE.Portuguese,
  },
  {
    icon: CnIcon,
    text: "Chinese",
    code: LANGUAGES_CODE.China,
  },
];

function LanguageSelector() {
  const ref = useRef<HTMLDivElement>(null);
  const { setTheme } = useTheme();
  const [displayOpt, setDisplayOpt] = useState<boolean>(false);
  const { i18n } = useTranslation();
  const [langIcon, setLangIcon] = useState<string>(
    options.find((option) => option.code === i18n.language)?.icon || EnIcon
  );

  const handleLanguage = (key: string) => {
    i18n.changeLanguage(key);
    setLocalItem("language", key);
    setDisplayOpt(false);
    setTheme(key);
  };

  useOnClickOutside(ref, () => setDisplayOpt(false));

  const currentLang = (i18n: any) => {
    switch (i18n?.language) {
      case LANGUAGES_CODE.English:
        return <Flag src={EnIcon} alt="en_icon" />;
      case LANGUAGES_CODE.Vietnamese:
        return <Flag src={VnIcon} alt="vi_icon" />;
      case LANGUAGES_CODE.Korea:
        return <Flag src={KoIcon} alt="ko_icon" />;
      case LANGUAGES_CODE.Japan:
        return <Flag src={JpIcon} alt="jp_icon" />;
      case LANGUAGES_CODE.Spanish:
        return <Flag src={SpIcon} alt="sp_icon" />;
      case LANGUAGES_CODE.Portuguese:
        return <Flag src={PorIcon} alt="por_icon" />;
      case LANGUAGES_CODE.China:
        return <Flag src={CnIcon} alt="cn_icon" />;

      default:
        return <Flag src={EnIcon} alt="en_icon" />;
    }
  };

  useEffect(() => {
    setTimeout(() => {
      const lang = getLocalItem("language");
      !lang && setLocalItem("language", LANGUAGES_CODE.English);
      i18n.changeLanguage(lang || LANGUAGES_CODE.English);
    }, 100);
  }, []);

  return (
    <Container ref={ref}>
      <Select onClick={() => setDisplayOpt(!displayOpt)}>
        {/* <img src={langIcon} alt="icon" /> */}
        {currentLang(i18n)}
      </Select>
      <OptionList $display={displayOpt}>
        {options.map((option, index) => (
          <Option
            key={index}
            $active={option.code === i18n.language}
            onClick={() => {
              handleLanguage(option.code || LANGUAGES_CODE.English);
            }}
          >
            <Flag src={option.icon} alt="icon" />
            <p>{option.text}</p>
          </Option>
        ))}
      </OptionList>
    </Container>
  );
}

export default LanguageSelector;
