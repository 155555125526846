export enum OrderStatusType {
  Draft = "DRAFT",
  InProgress = "IN PROGRESS",
  Packing = "PACKING",
  Confirm = "CONFIRMED",
  RequestCancel = "REQUEST CANCEL",
  Shipping = "SHIPPING",
  Delivered = "DELIVERED",
  Refunded = "REFUNDED",
  Completed = "COMPLETED",
  RejectRequestCancel = "REJECT REQUEST CANCEL",
  Canceled = "CANCELED",
  PendingPayment = "PENDING PAYMENT",
}
