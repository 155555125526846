import React from "react";
import { breakPoints } from "../../../../constant/breakpoints";
import { styled } from "styled-components";
import useTheme from "../../../../hooks/use-theme";
import { useTranslation } from "react-i18next";
import { ImagesUrl } from "../../../../constant";

const SpecificationDesktop = () => {
  const { fontFamily } = useTheme();
  const { t } = useTranslation();

  return (
    <DesktopContainer>
      <FirstRow>
        <div>
          <Image src={ImagesUrl.Spec_First_First} alt="Led Notification" />
        </div>
        <div>
          <Image src={ImagesUrl.Spec_First_Second} alt="" />
        </div>
        <TextColumn>
          <Title fontFamily={fontFamily}>
            {t("Geometric LED Notifications")}
          </Title>
          <Content>
            {t(
              "The geometric design serves as an illustration of lighting innovation for smartphones. You can customize the color of the color of the light pattern in response to incoming calls, new notifications, and more events. Let Start24 shine with your rhythm."
            )}
          </Content>
        </TextColumn>
      </FirstRow>

      <SecondRow>
        <TextColumn>
          <Title fontFamily={fontFamily}>
            {t(
              "Industrial Transparent Back Design Featuring Colorful LED Lights"
            )}
          </Title>
          <Content>
            {t(
              "Start24's straightforward geometric design brings an immersive experience of light & sound to modern smartphones. Let Start24 illuminate your life!"
            )}
          </Content>
        </TextColumn>
        <div>
          <Image2 src={ImagesUrl.Spec_Second_Second} alt="" />
        </div>
      </SecondRow>

      <ThirdRow>
        <div>
          <Image src={ImagesUrl.Spec_Third_First} alt="" />
        </div>
        <TextColumn>
          <Title fontFamily={fontFamily}>
            {t("108-Megapixel Triple-Lens Camera for MEGA Moment")}
          </Title>
          <Content>
            {t(
              "With its equipped camera of three diverse lenses. You may snap ultra-high-resolution photos. Capture your MEGA moment with Start24's stunning triple-lens camera."
            )}
          </Content>
        </TextColumn>
        <div>
          <Image src={ImagesUrl.Spec_Third_Third} alt="" />
        </div>
      </ThirdRow>

      <FourthRow>
        <TextColumn>
          <Title fontFamily={fontFamily}>
            {t("12GB RAM and 256GB ROM Storage")}
          </Title>
          <Content>
            {t(
              "With 12GB RAM and massive ROM storage of 256GB, Start24 provides a noticeably improved user experience. The large RAM enables smoother multitasking, allowing for quick lag-free switching between various background applications."
            )}
          </Content>
        </TextColumn>
        <div>
          <Image
            src={ImagesUrl.Spec_Fourth_Second}
            alt=""
            style={{ width: "100rem", aspectRatio: "132/61" }}
          />
        </div>
        <TextColumn>
          <Title fontFamily={fontFamily}>
            {t("Android 13 Operation System")}
          </Title>
          <Content>
            {t(
              "START24 is powered by Android 13 and provides secure and convenient data security using fingerprint and facial identification, ensuring a personalized, safe, and hassle-free user experience."
            )}
          </Content>
        </TextColumn>
      </FourthRow>

      <FifthRow>
        <div>
          <div>
            <Image
              src={ImagesUrl.Spec_Fifth_First}
              alt=""
              style={{ width: "100rem", aspectRatio: "47/24" }}
            />
          </div>
          <TextColumn>
            <Title fontFamily={fontFamily}>
              {t("5000mAh All-Day Life Battery")}
            </Title>
            <Content>
              {t(
                "A 5000mAh battery powered by an energy-efficient processor ensures all-day usage on a single charge. You can expect to go all day between charges with Start24."
              )}
            </Content>
          </TextColumn>
        </div>
        <div>
          <div>
            <Image
              src={ImagesUrl.Spec_Fifth_Second}
              alt=""
              style={{ width: "100rem", aspectRatio: "47/24" }}
            />
          </div>
          <TextColumn>
            <Title fontFamily={fontFamily}>
              {t("6.81 Inches Full-Screen Display")}
            </Title>
            <Content>
              {t(
                "A larger full-screen display of 6.81 inches with a resolution of 2400 x 1080 pixels enhances the phone's overall viewing and multimedia effect, providing a more immersive visual experience while gaming, browsing, watching videos, and using apps."
              )}
            </Content>
          </TextColumn>
        </div>
      </FifthRow>
    </DesktopContainer>
  );
};

export default SpecificationDesktop;

const Flex = styled.div`
  width: 100%;
  display: flex;
  align-items: stretch;
  justify-content: space-between;
`;
//

const DesktopContainer = styled(Flex)`
  /* position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0%); */
  width: 95vw;
  flex-direction: column;
  gap: 0.8rem;
  margin-top: 8.5rem;
`;

const FirstRow = styled(Flex)`
  gap: 0.8rem;
  > div {
    width: 33%;
  }
`;
const SecondRow = styled(Flex)`
  > div {
    &:first-child {
      width: 30%;
    }
    &:nth-child(2) {
      width: 69%;
    }
  }
`;
const ThirdRow = styled(Flex)`
  gap: 0.8rem;
  > div {
    width: 33%;
    &:nth-child(3) {
      object-fit: fill;
    }
  }
`;
const FourthRow = styled(Flex)`
  > div {
    &:first-child,
    &:nth-child(3) {
      width: 30%;
    }
    &:nth-child(2) {
      width: 69%;
    }
  }
`;
const FifthRow = styled(Flex)`
  > div {
    width: 49.5%;
    display: flex;
    flex-direction: column;
    gap: 3.2rem;
  }
`;

export const Image = styled.img`
  max-width: 100%;
  max-height: 100%;
  width: 60rem;
  aspect-ratio: 501/292;
  border-radius: 0.75rem;
  transition: all 0.2s;
  &:hover {
    transform: scale(1.01);
  }
  @media (max-width: ${breakPoints.ipad}) {
    width: 90rem;
  }
  @media (max-width: ${breakPoints.mobile}) {
    min-height: 21.5rem;
  }
`;
export const Image2 = styled.img`
  max-width: 100%;
  max-height: 100%;
  width: 140rem;
  aspect-ratio: 1049/472;
  border-radius: 0.75rem;
  transition: all 0.2s;
  &:hover {
    transform: scale(1.01);
  }
  @media (max-width: ${breakPoints.mobile}) {
    min-height: 21.5rem;
  }
`;

export const Title = styled.span<{ fontFamily?: string }>`
  color: #000;
  text-align: center;
  font-family: ${({ fontFamily }) => fontFamily};
  font-size: 3rem;
  font-style: normal;
  font-weight: 400;
  line-height: 5rem; /* 104.167% */
  text-transform: uppercase;
  @media (max-width: ${breakPoints.desktop}) {
    line-height: 115%;
  }
  @media (max-width: 1280px) {
    font-size: 2.4rem;
  }
  @media (max-width: ${breakPoints.ipad}) {
    font-size: 2rem;
  }
`;
export const Content = styled.p`
  color: #000;
  text-align: center;
  font-family: "Archivo";
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 400;
  line-height: 170%; /* 2.72rem */
  letter-spacing: 0.016rem;
  @media (max-width: ${breakPoints.ipad}) {
    font-size: 1.2rem;
  }
`;
export const TextColumn = styled.div`
  padding: 0 4rem;
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
  justify-content: center;
  align-items: center;
  transition: all 0.2s;
  &:hover {
    transform: scale(1.1);
  }
`;
