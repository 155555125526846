import styled from "styled-components";
import Playback from "./components/Playback";
import Animation from "./components/Animation";
import { breakPoints } from "../../constant/breakpoints";
import { useEffect, useRef, useState } from "react";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import ScrollToBottomButton from "./components/ScrollToBottomButton";
import SkipMenuAnimation from "../home/component/SkipMenuAnimation";

const Wrapper = styled.div<{ $height: number }>`
  height: ${(props) => props.$height / 10}rem;
  @media (min-width: ${breakPoints.laptop}) {
  }
`;

const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: fit-content;
  overflow: hidden;
  /* @media (min-width: ${breakPoints.laptop}) {
    margin: 0 3.2rem;
  } */
`;

function Landing({
  onClick,
  onScrollToIntroduce,
}: {
  onClick: () => void;
  onScrollToIntroduce: () => void;
}) {
  const containerRef = useRef<HTMLDivElement>(null);
  const [wrapperHeight, setWrapperHeight] = useState(0);
  const [skipVideo, setSkipVideo] = useState(false);
  const windowDimensions = useWindowDimensions();
  useEffect(() => {
    if (containerRef.current) {
      setWrapperHeight(containerRef.current.clientHeight);
    }
  }, [containerRef, windowDimensions]);

  return (
    <Wrapper $height={wrapperHeight}>
      <Container ref={containerRef}>
        <SkipMenuAnimation setSkipVideo={setSkipVideo} />
        <ScrollToBottomButton onScrollToIntroduce={onScrollToIntroduce} />
        <Playback skipVideo={skipVideo} />
        <Animation onClick={onClick} />
      </Container>
    </Wrapper>
  );
}

export default Landing;
