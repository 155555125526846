import { useEffect, useState } from "react";
import Api from "../service";
import { ITracking } from "./use-tracking-order";
import { utcToGtm } from "../utils/utils";

interface IShipmentCheckpoint {
  tag: string;
  subtag: string;
  message: string;
  checkpointTime: string;
}

export interface ITrackingShipment {
  orders_code: string;
  status: string;
  transaction_id: string;
  shipment_tracking_number: string;
  shipment_tracking_url: string;
  shipment_error: string;
  shipment_type: string;
  delivered_at: string;
  warehouse_order_code: string;
  warehouse_error: string;
  warehouse_status: string;
  shipment_order_code: string;
  shipment_status: string;
  shipment_checkpoints: IShipmentCheckpoint[];
}

const useTrackingShipment = ({ orderId }: { orderId: string }) => {
  const [data, setData] = useState<ITrackingShipment | null>(null);
  const [shipmentCheckpoints, setShipmentCheckpoints] = useState<ITracking[]>([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await Api.Order.getTrackingShipment(orderId);
        if (response.data) {
          setData(response.data);
          const formattedShipmentCheckpoints = response.data.shipment_checkpoints.map((item: IShipmentCheckpoint) => ({
            time: utcToGtm(item.checkpointTime),
            status: item.message
          }))
          setShipmentCheckpoints(formattedShipmentCheckpoints.reverse());
        }
      } catch (error) {
        console.log("error:", error);
      } finally {
        setLoading(false);
      }
    }
    fetchData();
  }, [orderId]);

  return { data, shipmentCheckpoints, loading };
};

export default useTrackingShipment;