import { useState } from "react";
import { useTranslation } from "react-i18next";
import { styled } from "styled-components";
import { breakPoints } from "../../../constant/breakpoints";
import useTheme from "../../../hooks/use-theme";
import Specification from "./specification/Specification";

const Layout = styled.div`
  margin-top: 8rem;
  @media (max-width: 840px) {
    margin-top: 7rem;
  }
`;

const Title = styled.div<{ fontFamily?: string }>`
  font-family: ${({ fontFamily }) => fontFamily};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 3rem;
  gap: 1.7rem;
  text-align: center;
  @media (min-width: ${breakPoints.ipad}) {
    max-width: 82rem;
    font-size: 6rem;
    align-items: start;
    flex-direction: row;
    text-align: left;
  }
  @media (min-width: ${breakPoints.desktop}) {
    max-width: 144rem;
    font-size: 6.4rem;
  }
`;

const TitColor = styled.div`
  min-width: 13rem;
  flex: 1;
  margin-top: 3rem;
  display: flex;
  font-weight: 600;
  font-size: 1.6rem;
  gap: 1rem;
  align-items: center;
  > p {
    font-weight: normal;
  }
`;

const ComponentInfo = () => {
  const { t } = useTranslation();
  const { fontFamily } = useTheme();
  return (
    <Layout>
      <WrapHeader id="tests">
        <Title fontFamily={fontFamily}>
          <TitColor>
            <div
              style={{
                width: "1rem",
                height: "1rem",
                borderRadius: "2px",
                background:
                  "var(--gradient, linear-gradient(237deg, #1268E9 0%, #B43BF0 50.09%, #F64F59 100%))",
              }}
            ></div>
            <p>{t("PHONE SPEC")}</p>
          </TitColor>
          {t("RGB LED LIGHTS PHONE WITH TRANSPARENT BACK")}
        </Title>
      </WrapHeader>

      <Specification />
      {/* <LayoutItems /> */}
    </Layout>
  );
};

export default ComponentInfo;

const WrapHeader = styled.div`
  display: flex;
  justify-content: center;
  padding: 0 2rem;
`;
const LayoutItems = () => {
  const { t } = useTranslation();

  const data = [
    {
      title: t("DESIGN"),
      tabs: [
        {
          title: "",
          contents: [
            t("FHD+ resolution"),
            t("RGB LED lights"),
            t("Material: LCD"),
          ],
        },
      ],
    },
    {
      title: t("FEATURE"),
      tabs: [
        {
          title: "",
          contents: [
            t("Size: 6.81 inches (80.5% screen-to-body ratio)"),
            t("IPS LCD touchscreen / 16M colors / 90Hz refresh"),
            t("Resolution: 2400 x 1080 pixels"),
            t("Other Features: Cover Glass Panda MN228 Glass"),
          ],
        },
      ],
    },
    {
      title: t("CAMERA"),
      tabs: [
        {
          title: t("Sensors"),
          contents: [
            `${t("Main Camera")}: 108MP`,
            `${t("Rear Camera")}: 20MP`,
            t("Night Vision Camera + 2MP Macro Lens "),
            `${t("Front Camera")}: 32MP`,
            t("Fingerprint"),
            t("G-Sensor"),
            t("Gyroscope"),
            t("Proximity"),
            t("Ambient Light Sensor"),
            t("Compass"),
            t("Baroceptor"),
          ],
        },
        {
          title: t("Camera"),
          contents: [
            t("Triple Lenses"),
            t("Autofocus"),
            t("Continuous shooting"),
            t("Digital zoom"),
            t("HDR"),
            t("Touch focus"),
            t("Face detection"),
            t("IR LED flash (Night Vision)"),
            t("Dual LED flash (main camera)"),
            t("Video Resolution: 4K/1080@30/60fps"),
          ],
        },
        {
          title: t("Front Camera"),
          contents: [
            t("Single Lens"),
            t("16-megapixel"),
            t("FaceID"),
            t("Video Resolution: 1080p@30fps"),
          ],
        },
      ],
    },
    {
      title: t("NETWORK & CONNECTIVITY"),
      tabs: [
        {
          title: "",
          contents: [
            t(
              "Band: 2G GSM (Band 2/3/5/8), 3G WCDMA (Band 1/2/4/5/6/8), 3G CDMA2000 (Band BC0/BC1), 4G FDD-LTE (Band 1/2/3/4/5/7/8/12/13/17/18/19/20/25/26/28A/28B/66), 4G TDD-LTE (Band 34/38/39/40/41)"
            ),
            t(
              "Wi-Fi: WLAN 802.11 a/b/g/n/ac 2.4GHz/5GHz, Wi-Fi Direct, WI-FI Display, Wi-Fi Hotspot（V2.0)"
            ),
            t("Bluetooth: Bluetooth 5.3"),
            t("NFC: Yes"),
            t("GPS: GPS + GLONASS + BeiDou + Galileo"),
            t("USB: USB Type C"),
            t("USB OTG: Yes"),
            t("Programmable Key: Yes"),
            t("Loudspeaker: Yes"),
            t("3,5mm Headphone Jack: Yes"),
            t("Wireless Charging: No"),
            t("FM Radio: Yes"),
          ],
        },
      ],
    },
    {
      title: t("SUSTAINABILITY"),
      tabs: [
        {
          title: t("Front Glass / Plastic Body"),
          contents: [
            t("Water / Dust Resistance"),
            t("IP68 / IP69K / MIL-STD-810G certified"),
          ],
        },
        {
          title: t("Dual SIM"),
          contents: [t("Nano-SIM, dual stand-by")],
        },
        {
          title: t("Battery"),
          contents: [
            t("Battery Capacity: Non-removable Li-Po 5000mAh battery"),
            t("Fast Charging: 18W charger"),
          ],
        },
        {
          title: t("Color"),
          contents: [t("White, and Black colors")],
        },
      ],
    },
  ];

  return (
    <Layout>
      {data.map((i, index) => (
        <Item key={index} index={index} data={i} />
      ))}
    </Layout>
  );
};

const Item = ({
  data,
  index,
}: {
  index: number;
  data: {
    title: string;
    tabs: (
      | {
          title: string;
          contents: string[];
        }
      | undefined
    )[];
  };
}) => {
  const [active, setActive] = useState<boolean>(false);
  const [tabActive, setTabActive] = useState<number>(0);

  const { fontFamily } = useTheme();

  return (
    <div style={{ position: "relative" }}>
      <Row onClick={() => setActive(!active)}>
        <div style={{ display: "flex", gap: "2rem" }}>
          <NumberRow>
            <p>0{index + 1}</p>
            <NumberRowSub>0{index + 1}</NumberRowSub>
          </NumberRow>
          <TileRow>
            <p>{data.title}</p>
            <TitleRowSub>{data.title}</TitleRowSub>
          </TileRow>
        </div>
        <AnimationIcon active={active}>
          <Img src="/icondown.png" />
        </AnimationIcon>
      </Row>
      {data.tabs.length > 0 && (
        <Content active={active}>
          <TabsTitle fontFamily={fontFamily}>
            {data.tabs.map(
              (i, index) =>
                i?.title && (
                  <p
                    key={index}
                    style={{
                      fontWeight: index === tabActive ? "bold" : "normal",
                      cursor: "pointer",
                      color: index === tabActive ? "#2d2d2d" : "inherit",
                    }}
                    onClick={() => setTabActive(index)}
                  >
                    {i?.title}
                  </p>
                )
            )}
          </TabsTitle>
          <TabsContent>
            {data.tabs[tabActive]?.contents.map((i, index) => (
              <p key={index} style={{ maxWidth: "800px" }}>
                {`${i}`}
              </p>
            ))}
          </TabsContent>
        </Content>
      )}
    </div>
  );
};

const TabsTitle = styled.div<{ fontFamily?: string }>`
  display: flex;
  flex-wrap: wrap;
  column-gap: 2.4rem;
  font-family: ${({ fontFamily }) => fontFamily};
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  color: var(--gray-scale-black-40, #8f8f8f);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  > p {
    margin-top: 1.6rem;
  }
  @media (min-width: ${breakPoints.ipad}) {
    column-gap: 6rem;
    font-size: 22px;
    > p {
      margin-top: 3rem;
    }
  }
`;

const TabsContent = styled.div`
  padding: 2.4rem 0;
  border-bottom: 1px solid #b1b1b1;
  font-size: 2rem;
  display: flex;
  flex-direction: column;
  row-gap: 0.4rem;
  color: var(--gray-scale-black-80, #2d2d2d);
  font-family: "Archivo";
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.014rem;
  @media (min-width: ${breakPoints.ipad}) {
    padding: 5rem 0;
    font-size: 2rem;
    row-gap: 1rem;
    line-height: 180%;
    letter-spacing: 0.02rem;
  }
`;

const Content = styled.div<{ active: boolean }>`
  height: 100%;
  border-top: 1px solid #b1b1b1;
  transform: ${(props) => (props.active ? "scaleY(1)" : "scaleY(0)")};
  max-height: ${(props) => (props.active ? "100rem" : "0")};
  transform-origin: top;
  transition: all 0.2s linear;
  overflow: hidden;
`;

const Img = styled.img`
  width: 2rem;
  height: 2rem;
`;

const NumberRowSub = styled.span`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 100%;
  transition: ease-in-out 500ms;

  padding: 1rem 0;
`;

const NumberRow = styled.div`
  height: fit-content;
  color: #b1b1b1;
  font-size: 2rem;
  position: relative;
  opacity: 1;
  overflow: hidden;
  min-width: 2.4rem;

  @media (min-width: ${breakPoints.laptop}) {
    font-size: 3.4rem;
  }
  > p {
    transition: ease-in-out 500ms;
    padding: 1rem 0;
  }
`;

const TitleRowSub = styled.span`
  position: absolute;
  left: 0;
  right: 0;
  top: 100%;
  transition: ease-in-out 500ms;
  padding: 1rem 0;
`;

const TileRow = styled.div`
  color: black;
  transition: ease-in-out 500ms;
  position: relative;
  overflow: hidden;
  font-size: 2rem;
  > p,
  ${TitleRowSub} {
    transition: ease-in-out 500ms;
    padding: 1rem 0;
  }
  @media (min-width: ${breakPoints.laptop}) {
    font-size: 3.4rem;
  }
`;

const Row = styled.div`
  align-items: center;
  cursor: pointer;
  justify-content: space-between;
  padding: 2.4rem 0;
  margin-top: -1px;
  display: flex;
  border-bottom: 1px solid transparent;
  transition: ease-in-out 500ms;
  column-gap: 0.8rem;
  &:hover {
    border-color: #ccc;
    ${NumberRow} > p {
      transform: translateY(100%);
    }
    ${TileRow} > p {
      transform: translateY(-100%);
    }
    ${NumberRowSub} {
      opacity: 1;
      transform: translateY(100%);
    }
    ${TitleRowSub} {
      opacity: 1;
      transform: translateY(-100%);
    }
  }
  @media (min-width: ${breakPoints.ipad}) {
    padding: 5rem 1rem;
  }
`;

const AnimationIcon = styled.div<{ active: boolean }>`
  opacity: ${({ active }) => (active ? 1 : 0.6)};
  transform: ${({ active }) => (active ? "rotate(180deg)" : "rotate(0deg)")};
  transition: transform 0.2s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
`;
