import { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";

const useChangeLanguage = (handler: () => void) => {
  const { i18n } = useTranslation();

  useEffect(() => {
    handler();
  }, [i18n.language]);
};

export default useChangeLanguage;
