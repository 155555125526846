import { styled } from "styled-components";
import { useEffect, useState } from "react";

const PreloadImage = ({ src, alt }: { src: string; alt: string }) => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const image = new window.Image();
    image.src = src;

    image.onload = () => {
      setLoaded(true);
    };

    return () => {
      // Cleanup the image object to avoid memory leaks
      image.onload = null;
    };
  }, [src]);

  return loaded ? <Image src={src} alt={alt} /> : null;
};
export default PreloadImage;

const Image = styled.img`
  width: 100%;
  height: 100%;
`;
