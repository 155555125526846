import { useCallback, useState } from 'react';

const useInput = (validateValue: any) => {
  const [enteredValue, setEnteredValue] = useState<string | undefined>();
  const [error, setError] = useState(false);

  const valueIsValid = validateValue(enteredValue);
  const hasError = !valueIsValid;

  const valueChangeHandler = useCallback((event: any) => {
    setEnteredValue(event.target.value);
  }, []);


  const reset = () => {
    setEnteredValue('');
  };

  return {
    value: enteredValue,
    setValue: setEnteredValue,
    isValid: valueIsValid,
    hasError,
    error,
    setError,
    valueChangeHandler,
    reset
  };
};

export default useInput;