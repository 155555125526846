import React, { lazy, useState, useTransition } from "react";
import { styled } from "styled-components";
// import cssClass from "./style.module.css";
import { breakPoints } from "../../constant/breakpoints";
import YoutubeIcon from "./YoutubeIcon.svg";
const Player = lazy(() => import("./Player"));

export interface IYoutubeVideo {
  thumbnail: string;
  videoId: string;
}
const YoutubeVideo = ({ thumbnail, videoId }: IYoutubeVideo) => {
  const [, startTransition] = useTransition();

  const [showVideo, setShowVideo] = useState(false);
  const [hasLoaded, setHasLoaded] = useState(false);
  return (
    <Container>
      <div className="videoRatio">
        {(!showVideo || !hasLoaded) && (
          <button
            className="thumbnailButton"
            onClick={() => {
              startTransition(() => {
                setShowVideo(true);
              });
            }}
          >
            <div className="videoInner">
              <img
                alt="Video thumbnail"
                src={thumbnail}
                className="thumbnailImage"
                loading="lazy"
              />
              <img
                alt="Play Video"
                // src="https://upload.wikimedia.org/wikipedia/commons/b/b8/YouTube_play_button_icon_%282013%E2%80%932017%29.svg"
                src={YoutubeIcon}
                loading="lazy"
                className="playIcon"
              />
            </div>
          </button>
        )}
        {showVideo && <Player videoId={videoId} setHasLoaded={setHasLoaded} />}
      </div>
    </Container>
  );
};

export default YoutubeVideo;

const Container = styled.div`
  width: 100%;
  border-radius: 4rem;
  overflow: hidden;
  .videoRatio {
    position: relative;
    aspect-ratio: 16/9;
  }
  .thumbnailButton {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background-color: transparent;
    border: 0;
    cursor: pointer;
    display: block;
    margin: 0;
    padding: 0;
    text-decoration: none;
    .videoInner {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      top: 0;
      width: 100%;
      height: 100%;

      .thumbnailImage {
        width: 100%;
        height: 100%;
      }

      .playIcon {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        transition: all 0.3s ease-in-out;
        width: 60px;
      }
    }
  }
  @media (max-width: ${breakPoints.ipad}) {
    border-radius: 2rem;
  }
  @media (max-width: ${breakPoints.mobile}) {
    border-radius: 1rem;
  }
`;
