import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

export const ButtonGradientI = styled.button<{ loading?: boolean }>`
  position: relative;
  font-family: "Archivo";
  font-weight: 400;
  color: #fff;
  cursor: pointer;
  text-align: center;
  border: none;
  background-size: 150% 100%;
  border-radius: 50px;
  moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  background-image: linear-gradient(to right, #1268e9, #b43bf0, #f64f59);

  &:hover {
    background-position: 100% 0;
    moz-transition: all 0.4s ease-in-out;
    -o-transition: all 0.4s ease-in-out;
    -webkit-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
    filter: brightness(1.2);
  }
  &:disabled,
  &.button--disabled {
    box-shadow: none;
    filter: contrast(0.5);
    cursor: not-allowed;
  }

  /* custom */
  &:active:not(:disabled):not(.button--disabled):not(.button--disabled) {
    opacity: 0.7;
  }
`;
export function ButtonGradient(props: any & { loading?: boolean }) {
  const { t } = useTranslation();

  return (
    <ButtonGradientI {...props}>
      {props.loading ? t("Loading...") : props.children}
      {/* {props.children} */}
    </ButtonGradientI>
  );
}
