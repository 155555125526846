import styled from "styled-components";
import { breakPoints } from "../../../constant/breakpoints";
import CheckIcon from "../icons/CheckIcon";

interface SelectorProps {
  text?: string;
  color: string;
  onSelect?: () => void;
  isActive: boolean;
  hasIcon?: boolean;
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 0.8rem;
  align-items: center;
  justify-content: center;
  width: fit-content;
`;

const Container = styled.div<{ $active: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3.2rem;
  height: 3.2rem;
  background-color: white;
  border-radius: 99999999px;
  aspect-ratio: 1/1;
  cursor: pointer;
  border-width: 0.2rem;
  border-style: solid;
  border-color: transparent;
  position: relative;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    margin: -0.4rem;
    border-radius: inherit;
    background: ${(props) =>
      props.$active
        ? "linear-gradient(to right, #f64f59, #b43bf0)"
        : "transparent"};
  }

  @media (min-width: ${breakPoints.ipad}) {
    width: 4.8rem;
    height: 4.8rem;
  }

  @media (min-width: ${breakPoints.laptop}) {
    width: 8rem;
    height: 8rem;
    border-width: 0.4rem;
    &::before {
      margin: -0.6rem;
    }
  }
`;

const Content = styled.div<{ $color: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: ${(props) => props.$color || "black"};
  border-radius: 99999999px;
  > svg {
    width: 2rem;
    height: 2rem;
    @media (min-width: ${breakPoints.laptop}) {
      width: 4rem;
      height: 4rem;
    }
  }
`;

const Text = styled.p`
  font-family: "Archivo";
  margin: 0;
  color: #2d2d2d;
  text-align: center !important;
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  @media (min-width: ${breakPoints.laptop}) {
    font-size: 2.4rem;
  }
`;

function ColorSelector({
  text,
  color,
  isActive = false,
  onSelect,
  hasIcon,
}: SelectorProps) {
  return (
    <Wrapper>
      <Container $active={isActive} onClick={onSelect}>
        <Content $color={color}>
          {isActive && hasIcon && (
            <CheckIcon fill={color === "#f7f7f7" ? "#000" : "#fff"} />
          )}
        </Content>
      </Container>
      {text ? <Text>{text}</Text> : null}
    </Wrapper>
  );
}

export default ColorSelector;
