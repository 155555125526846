import React, { Dispatch, SetStateAction } from "react";
import {
  ConfirmMode,
  FinalTotal,
  ProductSummaryContainer,
  ShippingRow,
} from "./PaymentConfirmation";
import { PaymentI } from "../../context/payment-context";
import { PaymentMethod } from "../..";
import PaypalIcon from "../../icons/paypal.svg";
import StripeIcon from "../../icons/stripe.svg";
import PoolsIcon from "../../icons/pools.svg";
import PoolsIcon2 from "../../icons/PoolIcon";
import useCart from "../../../../hooks/use-cart";
import { useTranslation } from "react-i18next";
interface IProps {
  data: PaymentI | undefined;
  totalPrice?: number;
  method: string;
  tracking?: boolean;
}
const Summary = ({ data, method, totalPrice, tracking }: IProps) => {
  const { getNumberOfItems } = useCart();
  const { t } = useTranslation();

  const subTotalPriceFinal =
    method === PaymentMethod.Pools && tracking
      ? (totalPrice || 0) * 10
      : totalPrice;

  const totalDiscountBaseOnTrackingMode = tracking
    ? data?.discount || 0
    : (data?.discount || 0) * getNumberOfItems();
  const totalDiscountFinal =
    method === PaymentMethod.Pools
      ? totalDiscountBaseOnTrackingMode * 10
      : totalDiscountBaseOnTrackingMode;

  const ship =
    data?.shipping?.shipment_product?.totalPrice[0]?.price ||
    data?.shipping?.shipment_amount ||
    0;
  const shippingFee = method === PaymentMethod.Pools ? ship * 10 : ship;

  const finalPrice =
    (subTotalPriceFinal || 0) + shippingFee - totalDiscountFinal;
  return (
    <ProductSummaryContainer>
      {totalDiscountFinal !== 0 ? (
        <ShippingRow>
          <label>
            {t("Referral")}: {data?.referral}
          </label>
          <span>
            -
            {method === PaymentMethod.Pools ? (
              <img src={PoolsIcon} alt="" />
            ) : (
              "$"
            )}
            {totalDiscountFinal}
          </span>
        </ShippingRow>
      ) : undefined}

      <ShippingRow>
        <label>{t("Subtotal")}</label>
        <span>
          {method === PaymentMethod.Pools ? (
            <img src={PoolsIcon} alt="" />
          ) : (
            "$"
          )}
          {subTotalPriceFinal}
        </span>
      </ShippingRow>
      <ShippingRow>
        <label>{t("Shipping Fee")}</label>
        <span>
          +
          {method === PaymentMethod.Pools ? (
            <img src={PoolsIcon} alt="" />
          ) : (
            "$"
          )}
          {shippingFee?.toFixed(2)}
        </span>
      </ShippingRow>
      <FinalTotal>
        <label>{t("Total Payable Amount")}</label>
        <div>
          {method ? (
            <img
              src={
                method === PaymentMethod.Paypal
                  ? PaypalIcon
                  : method === PaymentMethod.Stripe
                  ? StripeIcon
                  : method === PaymentMethod.Pools
                  ? PoolsIcon
                  : ""
              }
              alt=""
            />
          ) : undefined}

          <span>
            {method !== PaymentMethod.Pools && "$"}
            {finalPrice.toFixed(2)}
          </span>
        </div>
      </FinalTotal>
    </ProductSummaryContainer>
  );
};

export default Summary;
