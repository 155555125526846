function UserIcon({
  isDark,
  onClick,
  width,
  height,
  ref,
}: {
  isDark: boolean;
  width?: number;
  height?: number;
  onClick?: () => void;
  ref?: any;
}) {
  return isDark ? (
    <svg
      onClick={onClick}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32 32"
      fill="none"
    >
      <path
        d="M20.7904 4.03625C19.5741 2.72313 17.8754 2 16.0004 2C14.1154 2 12.411 2.71875 11.2004 4.02375C9.97661 5.34312 9.38036 7.13625 9.52036 9.0725C9.79786 12.8925 12.7047 16 16.0004 16C19.296 16 22.1979 12.8931 22.4797 9.07375C22.6216 7.155 22.0216 5.36563 20.7904 4.03625ZM27.0004 30H5.00036C4.7124 30.0037 4.42722 29.9433 4.16558 29.8229C3.90395 29.7026 3.67242 29.5254 3.48786 29.3044C3.08161 28.8188 2.91786 28.1556 3.03911 27.485C3.56661 24.5588 5.21286 22.1006 7.80036 20.375C10.0991 18.8431 13.011 18 16.0004 18C18.9897 18 21.9016 18.8438 24.2004 20.375C26.7879 22.1 28.4341 24.5581 28.9616 27.4844C29.0829 28.155 28.9191 28.8181 28.5129 29.3037C28.3284 29.5249 28.0969 29.7022 27.8352 29.8226C27.5736 29.9431 27.2884 30.0037 27.0004 30Z"
        fill="white"
      />
    </svg>
  ) : (
    <svg
      onClick={onClick}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 25 25"
      fill="none"
    >
      <path
        d="M5.82422 20.4502V19.4502C5.82422 17.5937 6.56172 15.8132 7.87447 14.5004C9.18723 13.1877 10.9677 12.4502 12.8242 12.4502M12.8242 12.4502C14.6807 12.4502 16.4612 13.1877 17.774 14.5004C19.0867 15.8132 19.8242 17.5937 19.8242 19.4502V20.4502M12.8242 12.4502C13.8851 12.4502 14.9025 12.0288 15.6526 11.2786C16.4028 10.5285 16.8242 9.51106 16.8242 8.4502C16.8242 7.38933 16.4028 6.37191 15.6526 5.62177C14.9025 4.87162 13.8851 4.4502 12.8242 4.4502C11.7634 4.4502 10.7459 4.87162 9.99579 5.62177C9.24565 6.37191 8.82422 7.38933 8.82422 8.4502C8.82422 9.51106 9.24565 10.5285 9.99579 11.2786C10.7459 12.0288 11.7634 12.4502 12.8242 12.4502Z"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default UserIcon;
