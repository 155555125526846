import axios from "axios";

import { toast } from "react-toastify";

const request = axios.create({
  baseURL: process.env.REACT_APP_DOMAIN_API,
  headers: {
    "Content-Type": "application/json",
  },
});

request.interceptors.request.use(function (config) {
  const token = localStorage.getItem("token");
  config.headers.Authorization = token ? `Bearer ${token}` : "";
  return config;
});

request.interceptors.response.use(
  (res) => {
    return res.data;
  },
  function (error) {
    if (
      error?.response?.statusCode === 403 ||
      error?.response?.status === 403
    ) {
      localStorage.removeItem("user");
      localStorage.removeItem("token");
      return window.location.reload();
    } else if (error.response?.status === 401) {
      localStorage.removeItem("user");
      localStorage.removeItem("token");
    } else {
      toast.error(error?.response?.data?.message || "Something went wrong!");
      return error?.response?.data || null;
    }
  }
);

export default request;
