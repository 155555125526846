import React from "react";
import styled from "styled-components";
import { breakPoints } from "../../constant/breakpoints";

const TelegramStickyV2 = () => {
  return (
    <Container onClick={() => window.open("https://t.me/start24phone")}>
      <Wrap>
        <Background></Background>
        <ImgWrap>
          <img
            src={
              "https://daziu008xh8wa.cloudfront.net/materials-lib/images/1705991803010.webp"
            }
            alt="Start24 Telegram"
          />
        </ImgWrap>
      </Wrap>
    </Container>
  );
};

export default TelegramStickyV2;

const Container = styled.div`
  position: fixed;
  bottom: 16.8rem;
  right: 3rem;
  height: 6.6rem;
  width: 6.6rem;
  cursor: pointer;
  z-index: 100;
  @media screen and (max-width: ${breakPoints.mobile}) {
    bottom: 13rem;
    right: 2rem;
  }
`;
const Wrap = styled.div`
  position: relative;
`;
const Background = styled.div`
  height: 6rem;
  width: 6rem;
  background: linear-gradient(
    237deg,
    #1268e9 -15.33%,
    #b43bf0 45.3%,
    #f64f59 105.7%
  );
  border-radius: 50%;

  animation: telegramRotate 2s linear infinite;

  @keyframes telegramRotate {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
const ImgWrap = styled.div`
  position: absolute;
  top: 0.2rem;
  left: 0.2rem;
  /* transform: translate(-50%, -50%); */
  > img {
    width: 5.6rem;
  }
`;
