import React from "react";
import styled from "styled-components";
import { breakPoints } from "../../../../constant/breakpoints";
import useWindowDimensions from "../../../../hooks/useWindowDimensions";
import SpecificationDesktop from "./SpecificationDesktop";
import SpecificationMobile from "./SpecificationMobile";
const Specification = () => {
  const windowDimensions = useWindowDimensions();
  const isIpad = windowDimensions.width <= 820;
  return (
    <WrapperOut>
      <>{isIpad ? <SpecificationMobile /> : <SpecificationDesktop />}</>
    </WrapperOut>
  );
};

export default Specification;
const WrapperOut = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;
