import styled from "styled-components";
import { breakPoints } from "../../constant/breakpoints";
import { ButtonGradient } from "../ButtonGradient";
import { useLocation } from "react-router-dom";
import { useContext, useEffect } from "react";
import AuthContext from "../../context/auth-context";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { useTranslation } from "react-i18next";

const Wrapper = styled.div<{ $isHigh: boolean }>`
  position: fixed;
  right: 2.4rem;
  /* bottom: ${(props) => (props.$isHigh ? "10rem" : "8rem")}; */
  display: none;
  align-items: end;
  flex-direction: column;
  row-gap: 1.6rem;
  z-index: 13;
  @media (min-width: ${breakPoints.ipad}) {
    display: flex;
  }
  @media (min-width: ${breakPoints.laptop}) {
    right: 3.2rem;
    /* bottom: ${(props) => (props.$isHigh ? "12rem" : "8rem")}; */
    bottom: 5.3rem;
  }
`;

const AgentButton = styled.a`
  > button {
    display: inline-flex;
    padding: 7px 14px;
    justify-content: center;
    align-items: center;
    gap: 6px;
    border-radius: 100px;
    background: var(
      --gradient,
      linear-gradient(237deg, #1268e9 0%, #b43bf0 50.09%, #f64f59 100%)
    );
    color: var(--gray-scale-white, #fff);
    text-align: center;
    font-family: Archivo;
    font-size: 1.2rem;
    font-style: normal;
    font-weight: 400;
    line-height: 170%; /* 34px */
    letter-spacing: 0.2px;
    @media (min-width: ${breakPoints.ipad}) {
      font-size: 1.6rem;
    }
    @media (min-width: ${breakPoints.laptop}) {
      font-size: 2rem;
    }
  }
`;

function StickyButton() {
  const { t } = useTranslation();
  const location = useLocation();
  const { user } = useContext(AuthContext);
  const screen = useWindowDimensions();

  useEffect(() => {
    const chatBot = document.querySelector<HTMLElement>(".click-chat-bot");
    if (chatBot) {
      if (location.pathname.includes("/community") && !user) {
        if (screen.width > 0) {
          chatBot.style.bottom = "12rem";
        }
        if (screen.width > 1024) {
          chatBot.style.bottom = "16rem";
        }
      } else {
        if (screen.width > 0) {
          chatBot.style.bottom = "8rem";
        }
        if (screen.width > 1024) {
          chatBot.style.bottom = "10rem";
        }
      }
    }
  }, [screen, location.pathname]);

  return (
    <Wrapper
      $isHigh={location.pathname.includes("/community") && !user ? true : false}
    >
      <AgentButton href="https://agent.startmobis.com/" target="_blank">
        <ButtonGradient>{t("Be an Agent")}</ButtonGradient>
      </AgentButton>
    </Wrapper>
  );
}

export default StickyButton;
