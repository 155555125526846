import { fetchEventSource } from "@microsoft/fetch-event-source";

export class StreamService {
  url: string;

  constructor(baseURL?: string) {
    this.url =
      baseURL || "https://chatbot.playgroundvina.com/api/bot/chatbot/stream";
  }

  async fetchStreamMessage(data: any, onData: any, onDone: any) {
    await fetchEventSource(this.url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
      onmessage(event) {
        onData(event.data);
      },
      onerror(error) {
        throw error;
      },
      onclose() {
        onDone();
      },
    });
  }
}

export const Stream = new StreamService();
