import { Address, usePrepareContractWrite, useContractWrite } from "wagmi";
import poolsAbi from '../constant/abi/poolsAbi.json';
const CONTRACT: Address = process.env.REACT_APP_CONTRACT_ADDRESS as `0x${string}`;
export const usePayment = ({
    order_code,
    total,
    value,
}: {
    order_code: string;
    total: number;
    value: number;
}) => {
    const totalPrice = total * 10 ** 18;

    const { config, error } = usePrepareContractWrite({
        address: CONTRACT,
        chainId: Number(process.env.REACT_APP_CHAIN_ID),
        abi: poolsAbi,
        functionName: "order",
        value: BigInt(value * 10 ** 18),
        args: [order_code, totalPrice],
    });
    if (order_code && error) {
        console.log('error -usePrepareContractWrite:', error)
    }
    return { ...useContractWrite(config), error };
};
