import React, { useEffect, useState } from "react";
import { styled } from "styled-components";
import SkipIcon from "./icons/Skip";
import { breakPoints } from "../../../constant/breakpoints";

const SkipMenuAnimation = ({
  setSkipVideo,
}: {
  setSkipVideo: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const [isShown, setIsShown] = useState<boolean>(true);
  const onClickSkipHandler = () => {
    setSkipVideo(true);
    const menu = document.getElementById("header_menu");
    menu?.setAttribute(
      "style",
      "animation: none; transition: transform 1s, opacity 1s; transform: translate(0); opacity: 1;"
    );
    setIsShown(false);
  };

  useEffect(() => {
    // const isFirst = JSON.parse(localStorage.getItem("isFirst") as string);
    // if (!isFirst) {
    //   setIsShown(false);
    // }
    setTimeout(() => {
      setIsShown(false);
    }, 9000);
  }, []);
  return (
    <Container $isShown={isShown}>
      <button onClick={onClickSkipHandler}>
        <span>Skip Video</span>
        <SkipIcon />
      </button>
    </Container>
  );
};

export default SkipMenuAnimation;

const Container = styled.div<{ $isShown?: boolean }>`
  position: absolute;
  top: 15%;
  right: 5%;
  transform: translate(-50%, -50%);
  display: ${({ $isShown: isShown }) => (isShown ? "flex" : "none")};
  justify-content: center;
  align-items: center;
  z-index: 9999;
  > button {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1.4rem 2.4rem;
    gap: 1rem;
    border: none;
    border-radius: 10rem;
    background: rgba(255, 255, 255, 0.2);
    cursor: pointer;
    &:hover {
      background: rgba(255, 255, 255, 0.3);
    }
    > span {
      color: var(--gray-scale-white, #fff);

      /* Button */
      font-family: "Archivo";
      font-size: 2.4rem;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
  @media (max-width: ${breakPoints.mobile}) {
    right: 0;

    > button {
      gap: 0.2rem;
      padding: 0.5rem 1rem;
      > span {
        font-size: 1.2rem;
      }
      > svg {
        width: 1.5rem;
      }
    }
  }
`;
