import styled from "styled-components";
import { ButtonGradient } from "../ButtonGradient";
import { useState, useContext } from "react";
import { toast } from "react-toastify";
import Api from "../../service";
import AuthContext from "../../context/auth-context";
import { breakPoints } from "../../constant/breakpoints";
import X from "../../assets/icons/x.svg";
import UserApi from "../../api/user";
import { useTranslation } from "react-i18next";

interface ModalProps {
  isOpen: boolean;
  name: string | undefined;
  setOpen: () => void;
}

const Wrapper = styled.div`
  cursor: default;
  position: fixed !important;
  padding: 0 0.8rem;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 99999999999;
  min-height: 100vh;
  background-color: rgba(0, 0, 0, 0.7);
  overflow: hidden;
  scroll-behavior: unset;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Container = styled.div`
  border-radius: 2.4rem;
  background: #fff;
  box-shadow: 1px 1px 10px 0px rgba(0, 0, 0, 0.1);
  display: flex;
  width: 100%;
  margin: 0 0.8rem;
  padding: 2rem;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  > h1 {
    width: 100%;
    color: #2d2d2d;
    text-align: center;
    font-family: "Archivo";
    font-size: 2.8rem;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    position: relative;
    > img {
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      cursor: pointer;
      display: none;
    }
  }
  > form {
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 2.4rem;
  }
  @media (min-width: ${breakPoints.mobile}) {
    > h1 {
      > img {
        display: block;
      }
    }
  }
  @media (min-width: ${breakPoints.ipad}) {
    gap: 3.2rem;
    width: 60rem;
    padding: 3.6rem;
  }
`;

const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  width: 100%;
  row-gap: 1.2rem;
  > label {
    color: var(--gray-scale-black-60, #525252);
    font-family: "Archivo";
    font-size: 2rem;
    font-style: normal;
    font-weight: 600;
    line-height: 130%; /* 26px */
    letter-spacing: 0.02rem;
  }
  > div {
    border-radius: 8px;
    background: #f2f2f2;
    width: 100%;
    display: flex;
    align-items: center;
    height: 48px;
    padding: 16px 24px;
    > input {
      background-color: transparent;
      display: block;
      flex: 1;
      color: #8f8f8f;
      font-family: "Archivo";
      font-size: 1.6rem;
      font-style: normal;
      font-weight: 400;
      line-height: 170%;
      letter-spacing: 0.016rem;
      border: none;
      outline: none;
    }
  }
`;

const SubmibButton = styled.div`
  width: 100%;
  > button {
    width: 100%;
    padding: 0.4rem 0;
    color: #f7f7f7;
    text-align: center;
    font-family: "Archivo";
    font-size: 2rem;
    font-style: normal;
    font-weight: 400;
    line-height: 180%; /* 36px */
    letter-spacing: 0.02rem;
  }
`;

function ModalChangeName({ isOpen, setOpen, name: oldName }: ModalProps) {
  const { t } = useTranslation();
  const { setUser } = useContext(AuthContext);
  const [name, setName] = useState<string | undefined>(oldName);

  const handleSubmit = async () => {
    if (!name) {
      toast.error(t("Please complete form!"));
    } else {
      if (name.length < 5) {
        toast.error(t("Name is at least 5 characters"));
      } else {
        const response = await UserApi.updateProfile({ fullname: name });
        if (response) {
          setUser((prev: any) => {
            return { ...prev, fullname: response.fullname };
          });
          toast.success(t("Update name success!"));
          setOpen();
        }
      }
    }
  };

  return (
    <Wrapper onClick={setOpen} className="modal">
      <Container onClick={(e) => e.stopPropagation()}>
        <h1>
          {t("Change Name")}
          <img src={X} alt="x" onClick={setOpen} />
        </h1>
        <form action="">
          <FormGroup>
            <label htmlFor="">{t("Your name")}</label>
            <div>
              <input
                autoFocus
                type="text"
                required
                size={1}
                placeholder={t("Enter your name")}
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                }}
              />
            </div>
          </FormGroup>
        </form>
        <SubmibButton onClick={handleSubmit}>
          <ButtonGradient>{t("Save Change")}</ButtonGradient>
        </SubmibButton>
      </Container>
    </Wrapper>
  );
}

export default ModalChangeName;
