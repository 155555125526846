import React, { useEffect, useState } from "react";
import Api from "../service";
import { utcToGtm } from "../utils/utils";

export interface ITracking {
  time: string;
  status: string;
}
type ITrackingOrderResponse = {
  createdAt: string;
  status: string;
}

const useTrackingOrder = ({ orderId }: { orderId: string }) => {
  const [data, setData] = useState<ITracking[] | null>(null);
  const [loading, setLoading] = useState(true)
  useEffect(() => {

    const fetchData = async () => {
      try {
        const response = await Api.Order.getTrackingOrder(orderId);
        if (response.data) {
          const formattedData = response.data.map((item: ITrackingOrderResponse) => ({
            time: utcToGtm(item.createdAt),
            status: item.status
          }))
          setData(formattedData);
        }
      }
      catch (error) {
        console.log("error:", error);
      }
      finally {
        setLoading(false);
      }
    }
    fetchData();

  }, [orderId]);

  return { data, loading };
};

export default useTrackingOrder;
